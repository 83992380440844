import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react"
import { Post_CardFragment } from "~/__generated__/graphql"
import { v4 as uuidv4 } from "uuid"
import { useSubscription } from "@apollo/client"
import { gql } from "~/__generated__"
import invariant from "tiny-invariant"

type UnsubscribeFn = () => void
export type NewFeedPostCallback = (post: Post_CardFragment) => void
interface NewFeedPostContextType {
  onNewFeedPost: (callback: NewFeedPostCallback) => UnsubscribeFn
}

const NewFeedPostContext = createContext<NewFeedPostContextType | null>(null)

export const NewFeedPostProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const subscriptionsRef = useRef<Record<string, NewFeedPostCallback>>({})
  const onNewFeedPost = useCallback((callback: NewFeedPostCallback) => {
    const callbackId = uuidv4()
    subscriptionsRef.current[callbackId] = callback
    return () => delete subscriptionsRef.current[callbackId]
  }, [])

  useSubscription(NEW_POST_IN_CHANNEL_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      invariant(data)

      console.log("received new post", data)

      const newPost = data.feedPostCreated?.post
      if (!newPost) return

      console.log(
        `broadcasting new post to ${
          Object.keys(subscriptionsRef.current).length
        } callbacks`
      )

      Object.values(subscriptionsRef.current).forEach((callbackFn) =>
        callbackFn(newPost)
      )
    },
    onError: (e) => {
      console.error(e)
    },
  })

  const value = useMemo(
    () => ({
      onNewFeedPost,
    }),
    [onNewFeedPost]
  )

  return (
    <NewFeedPostContext.Provider value={value}>
      {children}
    </NewFeedPostContext.Provider>
  )
}

export const useNewFeedPost = () => {
  const contextValue = useContext(NewFeedPostContext)
  if (contextValue === null) {
    throw Error("NewFeedPostContext has not been Provided!")
  }
  return contextValue
}

const NEW_POST_IN_CHANNEL_SUBSCRIPTION = gql(`
  subscription FeedPostCreated {
    feedPostCreated {
      currentUser {
        id
        unreadChannelPostsCount
      }
      post {
        id
        currentUserCanView
        ...Post_Card
        channel {
          id
          unreadFeedPostCount
        }
      }
    }
  }
`)
