import CalendarIcon from "~/images/icons/calendar-add.svg?react"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import { Calendar, CalendarProps } from "~/ui/calendar"
import { formatDate, toCalendarDate } from "~/common/formatDate"
import { useState } from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"

type CalendarDateFieldProps = Omit<
  CalendarProps,
  "onSelect" | "mode" | "selected" | "initialFocus" | "captionLayout"
> & {
  date?: Date
  onChangeDate: (date: Date | undefined) => void
  isDisabled?: boolean
  displayWithoutYear?: boolean
}

export const CalendarDateField = ({
  date,
  onChangeDate,
  isDisabled,
  displayWithoutYear,
  ...calendarProps
}: CalendarDateFieldProps) => {
  const [open, setOpen] = useState(false)
  const ccls = useCommunityClassname()

  return (
    <label className="onboarding-field flex">
      <div
        className={cn(
          "onboarding-field__prefix bg-white",
          ccls({
            [CommunitySlug.Gotomillions]: "border-primary",
            default: "",
          })
        )}
      >
        <CalendarIcon className="w-[17px] h-auto" />
      </div>
      <div className="flex-grow">
        <Popover onOpenChange={setOpen} open={open}>
          <PopoverTrigger asChild>
            <Button
              variant="input"
              className={cn(
                "text-dark-gray !rounded-l-none min-h-[50px]",
                !date && "text-gray-500",
                ccls({
                  [CommunitySlug.Gotomillions]: "border-primary",
                  default: "",
                })
              )}
              disabled={isDisabled}
            >
              {date
                ? formatDate(date, displayWithoutYear ? "MMM d" : undefined)
                : "Select Date"}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Calendar
              mode="single"
              selected={date}
              onSelect={(date) => {
                onChangeDate(date ? toCalendarDate(date) : undefined)
                setOpen(false)
              }}
              initialFocus
              captionLayout="dropdown"
              fromYear={calendarProps.fromYear || 1900}
              toYear={calendarProps.toYear || new Date().getFullYear() + 10}
              {...calendarProps}
            />
          </PopoverContent>
        </Popover>
      </div>
    </label>
  )
}
