import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { feedPath, signInPath } from "~/common/paths"

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser(false)

  useEffect(() => {
    if (currentUser) {
      navigate(feedPath.pattern)
    } else {
      navigate(signInPath.pattern)
    }
  }, [currentUser, navigate])

  return null
}
