import {
  CommunitySlug,
  TagConnection_ComposerFragment,
} from "~/__generated__/graphql"
import Select from "react-select"
import clsx from "clsx"
import { gql } from "~/__generated__"
import Tag from "../images/icons/tag.svg?react"
import { useCommunityClassname } from "~/community/useCommunity"
import { Badge } from "~/ui/badge"

export const TagSelector = ({
  tags,
  tagId,
  setTagId,
  suggestedTagId,
  disabled = false,
  loadingSuggestions = false,
}: {
  tags: TagConnection_ComposerFragment
  tagId: string | null
  setTagId: (tagId: string | null) => void
  suggestedTagId?: string | null
  disabled?: boolean
  loadingSuggestions?: boolean
}) => {
  const ccls = useCommunityClassname()
  const options = tags.nodes.map((t) => ({
    value: t.id,
    label: t.id === suggestedTagId ? `✨ ${t.name}` : t.name,
  }))
  const selectedOption =
    options.find((option) => option.value === tagId) || null

  return (
    <div
      className={clsx(
        "flex text-xs font-medium items-center tracking-wide mt-3",
        {
          "cursor-not-allowed": disabled,
        }
      )}
    >
      <Tag />

      {loadingSuggestions ? (
        <Badge variant="tagSelector" className="mx-2">
          ✨ Suggesting tags...
        </Badge>
      ) : (
        <Select
          classNamePrefix="select"
          className="flex-1"
          // defaultValue={colourOptions[0]}
          isDisabled={disabled}
          isClearable
          isSearchable
          name="tag"
          placeholder="Add Topics"
          options={options}
          value={selectedOption}
          onChange={(tag) => {
            setTagId(tag?.value || null)
          }}
          styles={{
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: "#999999",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              justifyContent: "initial",
              border: 0,
              outline: "none !important",
              boxShadow: "none",
              cursor: "pointer !important",
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: 12,
              fontWeight: 500,
              minHeight: 0,
              backgroundColor: "transparent",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              flex: "initial",
              paddingTop: 0,
              paddingBottom: 0,
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "white",
              backgroundColor: ccls({
                [CommunitySlug.Gotomillions]: "hsl(var(--primary))",
                [CommunitySlug.Fintechtakes]: "hsl(var(--primary))",
                [CommunitySlug.Safespace]: "#F14C76",
                default: `hsl(var(--highlight))`,
              }),
              marginLeft: 0,
              marginRight: 0,
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 8,
              paddingRight: 8,
              fontSize: "12px",
              lineHeight: "16px",
              borderRadius: 4,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              margin: 0,
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
            }),
            clearIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            dropdownIndicator: () => ({
              display: "none",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 100,
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              cursor: "pointer !important",
            }),
          }}
        />
      )}
    </div>
  )
}

gql(`
  fragment TagConnection_Composer on TagConnection {
    nodes {
      id
      name
    }
  }
`)
