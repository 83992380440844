import { useMemo } from "react"
import { User } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"

type Field = {
  key: string
  value: keyof Pick<User, "id" | "name" | "firstName" | "lastName" | "email">
  hide?: boolean
}
const prefilledFields: Field[] = [
  { key: "UserId", value: "id", hide: true },
  { key: "User Id", value: "id" },
  { key: "Name", value: "name" },
  { key: "Full Name", value: "name" },
  { key: "First Name", value: "firstName" },
  { key: "Last Name", value: "lastName" },
  { key: "Given Name", value: "firstName" },
  { key: "Surname", value: "lastName" },
  { key: "Email", value: "email" },
  { key: "Email Address", value: "email" },
  { key: "E-mail Address", value: "email" },
]

export const AirTableFormEmbed = ({ url }: { url: string }) => {
  const currentUser = useCurrentUser()

  const parsedUrl = useMemo(() => new URL(url), [url])
  const queryString = useMemo(() => {
    const existingParams = new URLSearchParams(parsedUrl.search)

    prefilledFields.forEach((field) => {
      const value = currentUser[field.value]
      if (value) {
        const permutations = [field.key]
        if (field.key.includes(" ")) {
          permutations.push(
            [
              field.key.split(" ")[0],
              ...field.key
                .split(" ")
                .slice(1)
                .map((word) => word.toLowerCase()),
            ].join(" ")
          )
        }

        permutations.forEach((key) => {
          existingParams.set(`prefill_${key}`, value)
          if (field.hide) {
            existingParams.set(`hide_${key}`, "true")
          }
        })
      }
    })

    return existingParams.toString()
  }, [currentUser, parsedUrl.search])
  const formattedUrl = useMemo(() => {
    if (!parsedUrl.pathname.startsWith("/embed")) {
      parsedUrl.pathname = `/embed${parsedUrl.pathname}`
    }
    parsedUrl.search = queryString
    return parsedUrl.toString()
  }, [parsedUrl, queryString])

  return (
    <div>
      <iframe
        title="Embedded Form"
        src={formattedUrl}
        width="100%"
        height="533"
        className="bg-transparent border border-mercury rounded-lg"
      />
    </div>
  )
}
