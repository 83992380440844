import { FirebaseApp, initializeApp } from "firebase/app"
import {
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging"
import { useCallback, useEffect, useState } from "react"
//@ts-expect-error including from a non-ts file
import workerUrl from "../firebase-messaging-sw?url"
import { add, isAfter, parseISO } from "date-fns"
import invariant from "tiny-invariant"
import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { useSafeMutation } from "./useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import toast from "react-hot-toast"
import safespaceNotificationPromptImage from "../images/notification-prompt-safespace.png"
import boardroomNotificationPromptImage from "../images/notification-prompt-boardroom.png"
import marketinglandNotificationPromptImage from "../images/notification-prompt-marketingland.png"
import gotomillionsNotificationPromptImage from "../images/notification-prompt-gotomillions.png"
import fintechtakesNotificationPromptImage from "../images/notification-prompt-fintechtakes.png"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { useConfig } from "./ConfigProvider"

export const NEXT_PROMPT_KEY = "notifications-next-prompt-key"
export const NOTIFICATIONS_DENY_COUNT_KEY = "notifications-deny-count-key"

const notificationImageMap: Record<CommunitySlug, string> = {
  SAFESPACE: safespaceNotificationPromptImage,
  BOARDROOM: boardroomNotificationPromptImage,
  MARKETINGLAND: marketinglandNotificationPromptImage,
  GOTOMILLIONS: gotomillionsNotificationPromptImage,
  FINTECHTAKES: fintechtakesNotificationPromptImage,
}

const notificationsSupported =
  window.isSecureContext &&
  "Notification" in window &&
  "serviceWorker" in navigator

if (notificationsSupported) {
  window.navigator.serviceWorker.addEventListener("message", (event) => {
    if (!event.data.action) {
      return
    }

    switch (event.data.action) {
      case "redirect-from-notificationclick":
        window.location.href = event.data.url
        break
      // no default
    }
  })
} else {
  if (window.isSecureContext) {
    console.warn("Service workers are not supported in this browser.")
  } else {
    console.warn(
      "Service workers are not supported outside of a secure context."
    )
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyD1DSf0x2nF97RptwD11kbSWgCHGnIZgPc",
  authDomain: "workweek-communities.firebaseapp.com",
  projectId: "workweek-communities",
  storageBucket: "workweek-communities.appspot.com",
  messagingSenderId: "489606663935",
  appId: "1:489606663935:web:de07bfa9b0345bba4186ca",
}
const vapidKey =
  "BImzgfZvKWvNAueWbC20grMft_KxL7D53MW93FkTW6QiNZwhQBsoFsF4UIWsuVWW5IAaZgjNVZAY1-TkOU5vPYU"

let app: FirebaseApp | null = null
let messaging: Messaging | null = null

if (notificationsSupported) {
  app = initializeApp(firebaseConfig)
  messaging = getMessaging(app)
}

let serviceWorkerRegistration_: ServiceWorkerRegistration | null = null

const getOrRegisterWorker = async () => {
  if (serviceWorkerRegistration_) return serviceWorkerRegistration_
  const registration = await navigator.serviceWorker.register(workerUrl)
  serviceWorkerRegistration_ = registration
  return registration
}

let tokenUploaded = false

export const Notifications = () => {
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const [modalOpen, setModalOpen] = useState(false)
  const [prompted, setPrompted] = useState(false)
  const community = useCommunity()
  const { qaToolsEnabled } = useConfig()

  useEffect(() => {
    if (!notificationsSupported) return
    invariant(messaging)
    getOrRegisterWorker()
    const cleanup = onMessage(messaging, (payload) => {
      console.log("Message received. ", payload)
      toast(`${payload.notification?.title}. ${payload.notification?.body}`)
    })
    return () => cleanup()
  }, [])

  const uploadToken = useCallback(async () => {
    if (tokenUploaded) return
    tokenUploaded = true

    const serviceWorkerRegistration = await getOrRegisterWorker()
    invariant(messaging)
    const token = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration,
    })

    console.log("uploading token", token)
    const { errors } = await runUserUpdate({
      variables: { input: { fcmPushToken: token } },
    })

    if (errors) {
      console.log("error uploading token", errors)
    }
  }, [runUserUpdate])

  useEffect(() => {
    if (qaToolsEnabled) {
      console.log(
        "[QA Notifications] notificationsSupported: ",
        notificationsSupported,
        "prompted: ",
        prompted,
        "permission: ",
        Notification.permission
      )
    }
    if (!notificationsSupported) return
    if (prompted) return
    if (Notification.permission === "denied") return
    if (Notification.permission === "granted") {
      uploadToken()
      return
    }
    const nextPromptTime = localStorage.getItem(NEXT_PROMPT_KEY)
    if (!nextPromptTime) {
      localStorage.setItem(
        NEXT_PROMPT_KEY,
        add(new Date(), { minutes: 2 }).toISOString()
      )
    }
    if (qaToolsEnabled) {
      console.log("[QA Notifications] nextPromptTime: ", nextPromptTime)
    }
    const interval = setInterval(async () => {
      const nextPromptTime = localStorage.getItem(NEXT_PROMPT_KEY)
      invariant(nextPromptTime)
      if (isAfter(new Date(), parseISO(nextPromptTime))) {
        setPrompted(true)
        // setModalOpen(true)
        const permission = await Notification.requestPermission()
        if (qaToolsEnabled) {
          console.log(
            "[QA Notifications] requested permissions to send notifications!"
          )
        }
        if (permission === "granted") {
          uploadToken()
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [uploadToken, prompted, qaToolsEnabled])

  const setNextPromptTime = useCallback(() => {
    const denyCount = parseInt(
      localStorage.getItem(NOTIFICATIONS_DENY_COUNT_KEY) || "0"
    )
    let nextPromptTime = null

    if (denyCount === 0) {
      nextPromptTime = add(new Date(), { days: 3 })
    } else {
      nextPromptTime = add(new Date(), { days: 30 })
    }

    localStorage.setItem(
      NOTIFICATIONS_DENY_COUNT_KEY,
      (denyCount + 1).toString()
    )
    localStorage.setItem(NEXT_PROMPT_KEY, nextPromptTime.toISOString())
    setModalOpen(false)
  }, [])

  const acceptPermission = useCallback(async () => {
    setNextPromptTime()
    setModalOpen(false)
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      uploadToken()
    }
  }, [uploadToken, setNextPromptTime])

  return (
    <Dialog open={modalOpen}>
      <DialogContent
        className="w-2/3 max-w-xl gap-0 flex flex-col"
        noCloseButton
      >
        <div className="mb-4 font-serif text-3xl text-center">
          Get (Some) Desktop Notifications?
        </div>
        <div className="mb-4 text-xs text-center">
          Enable browser notifications so you don't miss veryyyyy important info
          when you're away from this tab. You'll only receive these for high
          alert notifications and can turn them off anytime.
        </div>

        <img
          src={notificationImageMap[community.slug]}
          className="w-[402px] self-center mb-4"
          alt="notification prompt"
        />

        <div className="h-[1px] bg-mercury mb-6" />

        <Button type="button" className="mb-4 py-3" onClick={acceptPermission}>
          I'll enable them
        </Button>

        <Button
          type="button"
          className="text-primary"
          onClick={setNextPromptTime}
          variant="link"
        >
          Not now
        </Button>
      </DialogContent>
    </Dialog>
  )
}
