import SafeSpaceLogo from "~/images/safespace-logo.svg?react"
import BoardroomLogo from "~/images/boardroom-logo.svg?react"
import MarketinglandLogo from "~/images/marketingland-logo.svg?react"
import SafeSpaceLogoSquare from "~/images/safespace-logo-square.svg?react"
import BoardroomLogoSquare from "~/images/boardroom-logo-square.svg?react"
import MarketinglandLogoSquare from "~/images/marketingland-logo-square.svg?react"
import GotomillionsLogo from "~/images/gotomillions-logo.svg?react"
import GotomillionsLogoSquare from "~/images/gotomillions-logo-square.svg?react"
import FintechtakesLogo from "~/images/fintechtakes-logo.svg?react"
import FintechtakesLogoSquare from "~/images/fintechtakes-logo-square.svg?react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity, useCommunityClassname } from "./useCommunity"
import { useMemo } from "react"
import { cn } from "~/lib/utils"

interface CommunityLogoProps {
  className?: string
  variant?: "square" | "default"
}

export const CommunityLogo = ({
  className,
  variant = "default",
}: CommunityLogoProps) => {
  const community = useCommunity()
  const ccls = useCommunityClassname()

  const Logo = useMemo(() => {
    switch (community.slug) {
      case CommunitySlug.Safespace:
        return variant === "square" ? SafeSpaceLogoSquare : SafeSpaceLogo
      case CommunitySlug.Boardroom:
        return variant === "square" ? BoardroomLogoSquare : BoardroomLogo
      case CommunitySlug.Marketingland:
        return variant === "square"
          ? MarketinglandLogoSquare
          : MarketinglandLogo
      case CommunitySlug.Gotomillions:
        return variant === "square" ? GotomillionsLogoSquare : GotomillionsLogo
      case CommunitySlug.Fintechtakes:
        return variant === "square" ? FintechtakesLogoSquare : FintechtakesLogo
      default:
        return null
    }
  }, [community.slug, variant])

  if (!Logo) return null
  return (
    <Logo
      className={cn(
        "fill-logo-light text-logo-light dark:fill-logo-dark dark:text-logo-dark",
        ccls({
          [CommunitySlug.Gotomillions]: "text-[#FFFEF2]",
          default: "",
        }),
        className
      )}
    />
  )
}
