import { useEffect, useState } from "react"
import i18n from "~/common/i18n"
import {
  ScreenEnum,
  ScreenVariant_AdminFragment,
} from "~/__generated__/graphql"
import { camelCase } from "change-case"
import { gql } from "~/__generated__"
import { useGlobals } from "~/common/GlobalsProvider"

export const useScreenVariant = (
  screen: ScreenEnum,
  variant?: string | null
) => {
  const { screenVariants } = useGlobals()
  const [screenVariant, setScreenVariant] =
    useState<ScreenVariant_AdminFragment | null>(null)

  useEffect(() => {
    const screenVariant = screenVariants.find(
      (node) =>
        node.screen === screen &&
        ((!variant && !node.variantIdentifier) ||
          variant === node.variantIdentifier)
    )
    if (screenVariant?.overrides && screenVariant.overrides.translations) {
      i18n.addResourceBundle(
        "en-US",
        camelCase(screenVariant.screen),
        screenVariant.overrides.translations,
        true,
        true
      )
    }
    setScreenVariant(screenVariant || null)
  }, [screenVariants, screen, variant])

  return screenVariant
}

export const ScreenVariantsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { screenVariants } = useGlobals()

  useEffect(() => {
    screenVariants.forEach((screenVariant) => {
      if (
        screenVariant.overrides &&
        screenVariant.overrides.translations &&
        !screenVariant.variantIdentifier
      ) {
        i18n.addResourceBundle(
          "en-US",
          camelCase(screenVariant.screen),
          screenVariant.overrides.translations,
          true,
          true
        )
      }
    })
  }, [screenVariants])

  // No need for a new context here. This is handled via `GlobalsProvider`.
  return children
}

gql(`
  fragment ScreenVariant_Display on ScreenVariant {
    id
    screen
    variantIdentifier
    overrides
    active
    createdAt
    updatedAt
  }
`)
