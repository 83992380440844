import HTMLReactParser from "html-react-parser"
import { RenderFn } from "editorjs-blocks-react-renderer"
import clsx from "clsx"

export interface ImageBlockData {
  file?: {
    url: string
    name?: string
  }
  url?: string
  caption: string
  alt: string
  withBorder: boolean
  withBackground: boolean
  stretched: boolean
  [s: string]: any
}

export interface ImageBlockConfig {
  actionsClassNames?: {
    [s: string]: string
  }
}

export const ImageRenderer: RenderFn<ImageBlockData, ImageBlockConfig> = ({
  data,
  className = "",
  actionsClassNames = {
    stretched: "image-block--stretched",
    withBorder: "image-block--with-border",
    withBackground: "image-block--with-background",
  },
}) => {
  const classNames: string[] = []
  if (className) classNames.push(className)

  Object.keys(actionsClassNames).forEach((actionName) => {
    if (data && data[actionName] === true && actionName in actionsClassNames) {
      classNames.push(actionsClassNames[actionName])
    }
  })

  const figureprops: {
    [s: string]: string
  } = {}

  if (classNames.length > 0) {
    figureprops.className = classNames.join(" ")
  }

  const hasCaption = data?.caption && data.caption.length > 0

  return (
    <figure {...figureprops}>
      {data?.file?.url && (
        <img
          src={data.file.url}
          alt={data.alt || data.caption || data.file.name}
          className={clsx(
            "rounded-2xl max-h-[400px]",
            hasCaption ? "mb-2" : "mb-6",
            classNames.join(" ")
          )}
        />
      )}
      {data?.url && <img src={data.url} alt={data.caption} className="mb-3" />}
      {data?.caption && (
        <figcaption className="mb-6 text-black text-2xs">
          {HTMLReactParser(data.caption)}
        </figcaption>
      )}
    </figure>
  )
}
