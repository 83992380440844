import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { Lead } from "~/__generated__/graphql"
import { useLocalStorage } from "@uidotdev/usehooks"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ReferralSourceEnum } from "~/__generated__/graphql"
import invariant from "tiny-invariant"
import { useInternalSearchParams } from "~/common/useInternalSearchParams"
import { useConfig } from "./ConfigProvider"

interface LeadContextType {
  lead: Lead | null
  loading: boolean
  error: any
  clearLead: () => void
  findOrCreateLead: (
    preventDuplicateRef?: React.MutableRefObject<boolean>
  ) => Promise<void>
}

interface UseLeadOptions {
  readOnly?: boolean
  preventDuplicateRef?: React.MutableRefObject<boolean>
}

const LeadContext = createContext<LeadContextType | null>(null)

export const LeadProvider = ({ children }: { children: React.ReactNode }) => {
  const { qaToolsEnabled } = useConfig()
  const [leadId, setLeadId] = useLocalStorage<string | null>("leadId", null)
  const [runLeadCreate] = useSafeMutation(LEAD_CREATE_MUTATION)
  const [lead, setLead] = useState<Lead | null>(null)

  const internalSearchParams = useInternalSearchParams()

  const urlLeadId = internalSearchParams.get("lid")

  const urlEmail = internalSearchParams.get("em")
  // invitationId refers to an user inviting a user
  const invitationId = internalSearchParams.get("invitation")
  // invitationCode refers to an admin member inviting a user
  const urlInvitationCode = internalSearchParams.get("i")
  const urlVariant = internalSearchParams.get("v")
  const urlNewsletter = internalSearchParams.get(
    "ww_creator_newsletter_subscribe"
  )

  const preferredLeadId = urlLeadId || leadId

  const referralSource = invitationId
    ? ReferralSourceEnum.MemberReferral
    : urlNewsletter === "true"
    ? ReferralSourceEnum.NewsletterSignup
    : ReferralSourceEnum.Organic

  const queryVariables = urlInvitationCode
    ? { invitationCode: urlInvitationCode }
    : urlEmail
    ? { email: urlEmail }
    : preferredLeadId
    ? { id: preferredLeadId }
    : {}

  const {
    data: leadQueryResponse,
    loading,
    error,
  } = useQuery(LEAD_QUERY_DOCUMENT, {
    variables: queryVariables,
    skip: !preferredLeadId && !urlEmail && !urlInvitationCode,
  })

  const internalCreateRef = useRef(false)

  const findOrCreateLead = useCallback(
    async (preventDuplicateRef?: React.MutableRefObject<boolean>) => {
      if (lead || loading) {
        return
      }

      const ref = preventDuplicateRef || internalCreateRef

      if (leadQueryResponse?.lead) {
        const existingLead = leadQueryResponse.lead
        setLead(existingLead)
        setLeadId(existingLead.id)

        if (qaToolsEnabled) {
          if (urlLeadId || urlEmail || urlInvitationCode) {
            console.log("Lead fetched from url param")
          } else if (leadId) {
            console.log("Lead fetched from session")
          }
        }

        return
      } else if (!loading) {
        if (!ref.current && leadQueryResponse && !leadQueryResponse.lead) {
          if (urlEmail || preferredLeadId) {
            if (qaToolsEnabled) {
              console.log("Failed to fetch lead.")
            }
          }
        }

        const createLead = async (ref: React.MutableRefObject<boolean>) => {
          if (ref.current) {
            return
          }
          ref.current = true
          const { data: leadCreateResponse } = await runLeadCreate({
            variables: {
              input: {
                invitationId,
                source: referralSource,
                variationParam: urlVariant,
                email: urlEmail,
              },
            },
          })

          if (leadCreateResponse?.leadCreate?.lead) {
            const newLead = leadCreateResponse.leadCreate.lead
            setLeadId(newLead.id)
            setLead(newLead)

            if (qaToolsEnabled) {
              console.log("New lead created. Source: ", newLead.source)
            }
          }
        }

        await createLead(ref)
      }
    },
    [
      invitationId,
      lead,
      leadId,
      leadQueryResponse,
      loading,
      preferredLeadId,
      qaToolsEnabled,
      referralSource,
      runLeadCreate,
      setLeadId,
      urlEmail,
      urlLeadId,
      urlInvitationCode,
      urlVariant,
    ]
  )

  const clearLead = useCallback(() => {
    setLead(null)
    setLeadId(null)
  }, [setLeadId])

  const value = {
    lead,
    loading,
    error,
    clearLead,
    findOrCreateLead,
  }

  return <LeadContext.Provider value={value}>{children}</LeadContext.Provider>
}

export const useLead = ({
  readOnly = false,
  preventDuplicateRef,
}: UseLeadOptions = {}) => {
  const context = useContext(LeadContext)
  invariant(context, "useLead must be used within a LeadProvider")

  useEffect(() => {
    if (!readOnly || context.lead) {
      context.findOrCreateLead(preventDuplicateRef)
    }
  }, [context, readOnly, preventDuplicateRef])

  return context
}

const LEAD_QUERY_DOCUMENT = gql(`
  query Lead($id: ID, $email: String, $invitationCode: String) {
    lead(id: $id, email: $email, invitationCode: $invitationCode) {
      id
      name
      email
      source
      variationParam
      createdAt
    }
  }
`)

export const LEAD_UPDATE_MUTATION = gql(`
  mutation LeadUpdate($input: LeadUpdateInput!) {
    leadUpdate(input: $input) {
      lead {
        id
        email
      }
    }
  }
`)

const LEAD_CREATE_MUTATION = gql(`
  mutation LeadCreate($input: LeadCreateInput!) {
    leadCreate(input: $input) {
      lead {
        id
        email
        source
        createdAt
      }
    }
  }
`)
