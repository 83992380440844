import toast from "react-hot-toast"
import { postPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { POST_INCREMENT_SHARE_COUNT_MUTATION } from "./PostCard"
import { useConfig } from "~/common/ConfigProvider"

export const useSharePost = () => {
  const [incrementShareCount] = useSafeMutation(
    POST_INCREMENT_SHARE_COUNT_MUTATION
  )
  const { domain } = useConfig()

  return async (post: { id: string }) => {
    const postUrl = `${domain}${postPath({ postId: post.id })}`
    navigator.clipboard.writeText(postUrl)
    await incrementShareCount({ variables: { input: { postId: post.id } } })
    toast.success("Post link copied to clipboard.")
  }
}
