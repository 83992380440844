import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

gql(`
  fragment Community_Display on Community {
    __typename
    addToHomescreenPostId
    addToHomescreenVideoUrl
    applicationFields
    applicationProcessingCopy
    applicationProcessingCtas
    areApplicationsPaused
    brand
    celebrationTypes
    contentTypes
    groupFacts
    creator {
      __typename
      email
      firstName
      id
      lastName
    }
    darkMode
    defaultReactions
    delphiEmbedUrl
    features
    id
    lumaCalendarUrl
    lumaEventsUrl
    name
    origin
    privacyPolicyUrl
    referralsClipboardBlurb
    referralsProfileBlurb
    referralUrl
    signUpUrl
    slug
    summit
    termsOfUseUrl
    welcomeVideoUrl
    userResponsibilityQuestions
  }
`)

export const COMMUNITY_QUERY_DOCUMENT = gql(`
  query Community($id: ID) {
    community(id: $id) {
      ...Community_Display
    }
  }
`)
