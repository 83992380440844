import { useCallback, useReducer } from "react"
import { useDropzone, FileWithPath } from "react-dropzone"
import toast from "react-hot-toast"
import { v4 as uuidv4 } from "uuid"
import { Attachment, AttachmentTypeEnum } from "~/__generated__/graphql"
import { useFileUpload } from "~/editor/useFileUpload"

export const IMAGE_MIME_TYPES = ["image/jpeg", "image/png", "image/webp"] // TODO: HEIC support

type FileManagerEntry = {
  id: string
  previewUrl: string
  fileFromDropzone?: FileWithPath | null
  type: string
  filename: string
  uploadProgress?: number | null
  isDeleted: boolean
}

export type FileManagerState = FileManagerEntry[]

type Actions =
  | { type: "fileAdded"; file: FileManagerEntry }
  | { type: "removeFile"; id: string }
  | { type: "replaceFile"; previousId: string; file: FileManagerEntry }
  | { type: "setUploadProgress"; id: string; progress: number | null }
  | { type: "addFiles"; files: ServerFile[] }
  | { type: "removeAll" }

const fileReducer = (state: FileManagerState, action: Actions) => {
  switch (action.type) {
    case "fileAdded":
      return [...state, action.file]
    case "removeFile":
      return state.map((file) =>
        file.id === action.id ? { ...file, isDeleted: true } : file
      )
    case "replaceFile":
      return state.map((file) =>
        file.id === action.previousId ? action.file : file
      )
    case "removeAll":
      return []
    case "setUploadProgress":
      return state.map((file) =>
        file.id === action.id
          ? { ...file, uploadProgress: action.progress }
          : file
      )
    case "addFiles":
      return [
        ...state.map((f) => {
          if (action.files.some((s) => s.id === f.id)) {
            return { ...f, isDeleted: false }
          }
          return f
        }),
        ...action.files
          .filter((f) => !state.some((s) => s.id === f.id))
          .map((f) => serverFileToManagerFile(f)),
      ]
  }
  return state
}

export const useFileManager = () => {
  const initialState: FileManagerState = []
  const [state, dispatch] = useReducer(fileReducer, initialState)

  const { uploadAndAttach } = useFileUpload()

  const onFileAdd = useCallback(
    async (file: FileWithPath) => {
      const managerFile = localFileToManagerFile(file)
      dispatch({ type: "fileAdded", file: managerFile })

      const data = await uploadAndAttach(
        file,
        fileIsImage(managerFile)
          ? AttachmentTypeEnum.Image
          : AttachmentTypeEnum.File,
        {
          directUploadWillStoreFileWithXHR: (request) => {
            request.upload.addEventListener("progress", (event) => {
              dispatch({
                type: "setUploadProgress",
                id: managerFile.id,
                progress: event.lengthComputable
                  ? event.loaded / event.total
                  : null,
              })
            })
          },
        }
      )

      if (data) {
        console.log("file uploaded", file)
        dispatch({
          type: "replaceFile",
          previousId: managerFile.id,
          file: {
            ...serverFileToManagerFile(data["attachmentCreate"]["attachment"]),
            previewUrl: managerFile.previewUrl,
          },
        })
      } else {
        dispatch({ type: "removeFile", id: managerFile.id })
      }
    },
    [uploadAndAttach]
  )

  const dropzoneBag = useDropzone({
    maxFiles: 1000,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxSize: 52428800,
    onDropRejected: () => {
      toast.error("One or more of your files were too large.")
    },
    onDrop: (files) => {
      console.log("added files", files)
      files.forEach((file) => onFileAdd(file))
    },
  })

  return {
    dropzoneBag,
    attachedFiles: state,
    fileManagerDispatch: dispatch,
    hasPendingUploads: state.some((file) => fileIsLocal(file)),
  }
}

export const fileIsLocal = (file: FileManagerEntry) => {
  return !!file.fileFromDropzone
}

export const fileIsImage = (file: FileManagerEntry) => {
  return IMAGE_MIME_TYPES.includes(file.type)
}

export type ServerFile = Pick<
  Attachment,
  "id" | "byteSize" | "contentType" | "editorUrl" | "filename"
>

export const serverFileToManagerFile = (file: ServerFile): FileManagerEntry => {
  return {
    id: file.id,
    previewUrl: file.editorUrl,
    fileFromDropzone: null,
    type: file.contentType,
    filename: file.filename,
    isDeleted: false,
  }
}

const localFileToManagerFile = (file: FileWithPath): FileManagerEntry => {
  return {
    id: uuidv4(),
    previewUrl: URL.createObjectURL(file),
    fileFromDropzone: file,
    type: file.type,
    filename: file.name,
    isDeleted: false,
  }
}
