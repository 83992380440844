import { cn } from "~/lib/utils"

interface TokenProps {
  className?: string
  children: React.ReactNode
}

export const Token = ({ className, children }: TokenProps) => {
  return (
    <div
      className={cn(
        "rounded-full w-10 h-10 flex justify-center items-center bg-highlight text-white font-medium text-lg",
        className
      )}
    >
      {children}
    </div>
  )
}
