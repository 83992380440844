import { cva, VariantProps } from "class-variance-authority"
import { useCallback, useMemo, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { Article_CardFragment, CommunitySlug } from "~/__generated__/graphql"
import { useConfig } from "~/common/ConfigProvider"
import { formatDate } from "~/common/formatDate"
import { articlePath, editArticlePath } from "~/common/paths"
import { useTranslation } from "~/common/Translation"
import { HighlightContent } from "~/components/HighlightContent"
import { UserName } from "~/directory/UserName"
import {
  ImpressionContentContextEnum,
  useImpressions,
} from "~/feed/useImpressions"
import Crown from "~/images/icons/crown.svg?react"
import { cn } from "~/lib/utils"
import { ArticleImage } from "./ArticleImage"
import { Card, CardContent } from "./card"
import { ContentTypeIcon } from "./ContentType"
import { SimpleTooltip } from "./SimpleTooltip"
import { StackedUserAvatars } from "./StackedUserAvatars"
import { TagBubble } from "./TagBubble"
import { H5, H9, Muted } from "./typography"
import { useCommunity } from "~/community/useCommunity"

export const articleCardVariants = cva("", {
  variants: {
    variant: {
      default: "w-full sm:w-68 h-102",
      defaultFullWidth: "w-full h-102",
      hero: "w-full sm:w-160 h-80",
    },
    state: {
      default: "",
      highlighted: "border-highlight bg-card-highlight-background",
      impressionMade:
        "border-l-2 border-r-2 border-l-purple-500 border-r-purple-500",
    },
  },
  defaultVariants: {
    variant: "default",
    state: "default",
  },
})

const articleCardContentVariants = cva("p-4 h-full rounded-2xl", {
  variants: {
    state: {
      default: "",
      highlighted: "",
      impressionMade: "",
    },
  },
  defaultVariants: {
    state: "default",
  },
})

export type ArticleCardVariants = VariantProps<typeof articleCardVariants>

interface ArticleCardProps extends ArticleCardVariants {
  article: Article_CardFragment
  className?: string
  path?: typeof articlePath | typeof editArticlePath
  embedded?: boolean
  trackImpressions?: boolean
  clickable?: boolean
}

export const ArticleCard = ({
  className,
  embedded = false,
  trackImpressions = false,
  path = articlePath,
  clickable = true,
  ...props
}: ArticleCardProps) => {
  const { article, variant, state } = props
  const { visuallyShowImpressions } = useConfig()
  const navigate = useNavigate()
  const community = useCommunity()

  const goToArticle = () => {
    if (!clickable) return
    navigate(path({ articleId: article.id }))
  }

  const elementRef = useRef<HTMLDivElement | null>(null)
  const { inView, ref: inViewRef } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  })

  const { impressionMade } = useImpressions({
    inView,
    content: {
      type: "article",
      content: article,
      context: embedded
        ? ImpressionContentContextEnum.Embed
        : ImpressionContentContextEnum.Standalone,
    },
    enabled: trackImpressions ?? false,
    elementRef: elementRef,
  })

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      elementRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  return (
    <Card
      className={cn(
        articleCardVariants({
          variant,
          state:
            visuallyShowImpressions && impressionMade
              ? "impressionMade"
              : state,
        }),
        state === "highlighted" &&
          community.slug === CommunitySlug.Fintechtakes &&
          "border-primary",
        clickable && "cursor-pointer card-clickable",
        className
      )}
      role={clickable ? "button" : undefined}
      aria-pressed={clickable ? "false" : undefined}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          goToArticle()
        }
      }}
      onClick={goToArticle}
      ref={setRefs}
    >
      <CardContent className={cn(articleCardContentVariants({ state }))}>
        {(variant === "default" ||
          variant === "defaultFullWidth" ||
          !variant) && <DefaultArticleCard {...props} embedded={embedded} />}
        {variant === "hero" && (
          <HeroArticleCard {...props} embedded={embedded} />
        )}
      </CardContent>
    </Card>
  )
}

const DefaultArticleCard = ({
  article,
}: Omit<ArticleCardProps, "className">) => {
  const { collaborators, premium, markedLiveAt, createdAt, revision } = article
  const { title, description, contentType, tag, landscapeImageUrl } = revision!

  const [query] = useState("")
  const collaboratorNames = useMemo(
    () => collaborators.map((a) => a.user.name).join(", "),
    [collaborators]
  )
  const { t: contentTypes } = useTranslation("contentTypes")

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="text-2xs font-medium flex items-center gap-2">
        <ContentTypeIcon contentType={contentType} className="w-4 h-4" />
        <div>{contentTypes(contentType)}</div>
      </div>
      <div className="w-full sm:h-28 flex justify-center items-center overflow-hidden rounded-sm">
        <ArticleImage
          className="w-full rounded-sm"
          contentType={contentType}
          imageUrl={landscapeImageUrl || undefined}
        />
      </div>
      <div className="flex items-center gap-2">
        <StackedUserAvatars
          users={collaborators.map((a) => a.user)}
          opensUserDialog={true}
          avatarSize="3xs"
        />
        <span className="text-2xs">
          {collaborators.length === 1 ? (
            <UserName
              user={collaborators[0].user}
              formatter={(userName) => (
                <HighlightContent
                  query={query}
                  content={userName}
                  truncationLimit={30}
                  lineLimit={1}
                />
              )}
            />
          ) : (
            <HighlightContent
              query={query}
              content={collaboratorNames}
              truncationLimit={30}
              lineLimit={1}
            />
          )}
        </span>
      </div>
      <div className="flex flex-col gap-3 flex-grow">
        <H9 className="line-clamp-2">{title}</H9>
        <Muted className="text-3xs line-clamp-3 leading-loose tracking-wide">
          {description}
        </Muted>
        <Muted className="text-3xs">
          {formatDate(markedLiveAt || createdAt || new Date(), "MMM d, y")}
        </Muted>
      </div>
      <div className="flex justify-between items-center">
        <TagBubble tagName={tag.name} />
        {premium && (
          <SimpleTooltip content="Premium Content">
            <div>
              <Crown className="w-4 h-4 text-highlight" />
            </div>
          </SimpleTooltip>
        )}
      </div>
    </div>
  )
}

const HeroArticleCard = ({
  article,
  embedded,
}: Omit<ArticleCardProps, "className">) => {
  const { collaborators, premium, markedLiveAt, createdAt, revision } = article
  const { title, description, contentType, tag, portraitImageUrl } = revision!
  const [query] = useState("")
  const collaboratorNames = useMemo(
    () => collaborators.map((a) => a.user.name).join(", "),
    [collaborators]
  )
  const { t: contentTypes } = useTranslation("contentTypes")
  return (
    <div className="flex flex-row-reverse gap-3 h-full">
      <div className="min-w-1/3 sm:min-w-52 h-full overflow-hidden flex justify-center items-center">
        <ArticleImage
          className="h-full rounded-sm"
          contentType={contentType}
          imageUrl={portraitImageUrl || undefined}
        />
      </div>

      <div className="flex flex-col gap-3 h-full flex-1">
        <div className="flex items-center gap-2">
          <StackedUserAvatars
            users={collaborators.map((a) => a.user)}
            opensUserDialog={true}
            avatarSize="md"
          />
          <div className="flex flex-col">
            <Muted className="text-3xs">Authors</Muted>
            <div>
              <span className="text-xs">
                {collaborators.length === 1 ? (
                  <UserName
                    user={collaborators[0].user}
                    formatter={(userName) => (
                      <HighlightContent
                        query={query}
                        content={userName}
                        truncationLimit={30}
                        lineLimit={1}
                      />
                    )}
                  />
                ) : (
                  <HighlightContent
                    query={query}
                    content={collaboratorNames}
                    truncationLimit={30}
                    lineLimit={1}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 flex-grow">
          <H5 className="line-clamp-2">{title}</H5>
          <Muted className="text-2xs line-clamp-4 leading-normal tracking-wide">
            {description}
          </Muted>
          <Muted className="text-2xs">
            {formatDate(markedLiveAt || createdAt || new Date(), "MMM d, y")}{" "}
          </Muted>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-8 items-center">
            {!embedded && (
              <TagBubble tagName={tag.name} className="hidden md:block" />
            )}
            <div className="text-2xs font-medium flex items-center gap-2 whitespace-nowrap">
              <ContentTypeIcon contentType={contentType} className="w-4 h-4" />
              <div>{contentTypes(contentType)}</div>
            </div>
          </div>
          {premium && (
            <SimpleTooltip content="Premium Content">
              <div>
                <Crown className="w-4 h-4 text-highlight" />
              </div>
            </SimpleTooltip>
          )}
        </div>
      </div>
    </div>
  )
}

gql(`
  fragment Article_Card on Article {
    id
    createdAt
    markedLiveAt
    state
    visibility
    featuredUntil
    premium
    revision: approvedRevision {
      id
      title
      description
      contentType
      contentVersion
      landscapeImageUrl
      portraitImageUrl
      tag {
        id
        name
      }
    }
    collaborators {
      id
      user {
        ...User_Avatar
      }
    }
    currentUserBookmark {
      ...Bookmark
    }
  }
`)
