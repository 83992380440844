import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useWizard } from "~/ui/Wizard"
import { useEffect, useRef } from "react"
import { useRulesEngine } from "~/rules-engine/RulesEngineContext"
import {
  OfferCategoryEnum,
  RulesEngineEventTypeEnum,
  UserOfferStatusEnum,
} from "~/__generated__/graphql"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const LookupSaveBeforeLoseOffersStep = () => {
  const { goToStep, addToMeta } = useWizard()
  const currentUser = useCurrentUser()
  const { refetchUserOffers } = useUserOffers()
  const { createRulesEngineEvent } = useRulesEngine()
  const isFirstRunRef = useRef(true)

  useEffect(() => {
    ;(async () => {
      if (!isFirstRunRef.current) return
      isFirstRunRef.current = false
      await createRulesEngineEvent(RulesEngineEventTypeEnum.UserIsCancelling)

      const { data } = await refetchUserOffers()

      const offer = data.userOffers?.nodes.find((userOffer) => {
        return [
          userOffer.offer.category === OfferCategoryEnum.SaveBeforeLose,
          userOffer.offer.tier.level === currentUser.tier?.level,
          userOffer.offer.tierInterval === currentUser.tierInterval,
          [
            UserOfferStatusEnum.Acknowledged,
            UserOfferStatusEnum.Created,
            UserOfferStatusEnum.Accepted,
          ].includes(userOffer.status),
        ].every(Boolean)
      })?.offer

      if (!offer) {
        goToStep("ConfirmCancelStep")
        return
      }
      addToMeta("offer", offer)
      goToStep("SpecialOfferStep")
    })()
  }, [
    goToStep,
    addToMeta,
    createRulesEngineEvent,
    refetchUserOffers,
    currentUser.tier,
    currentUser.tierInterval,
  ])

  return (
    <div className="h-72 flex justify-center items-center">
      <LoadingIndicator />
    </div>
  )
}

LookupSaveBeforeLoseOffersStep.displayName = "LookupSaveBeforeLoseOffersStep"
