import * as React from "react"
import { cn } from "~/lib/utils"
import { Badge } from "~/ui/badge"
import { Button, ButtonProps } from "~/ui/button"

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonProps {
  count?: number
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { count, className, children, variant = "ghost", size = "icon", ...props },
    ref
  ) => {
    return (
      <Button
        variant={variant}
        size={size}
        {...props}
        ref={ref}
        className={cn("relative [&>span]:grow-0", className)}
      >
        {children}
        {count ? (
          <Badge variant="counter" className="absolute bottom-0 right-0">
            {count}
          </Badge>
        ) : null}
      </Button>
    )
  }
)
