import { Outlet, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { applicationFitWaitingScreenPath } from "~/common/paths"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const RequireUserOnboarded = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser(false)
  const onboardedRoutePattern = applicationFitWaitingScreenPath.pattern

  useEffect(() => {
    if (currentUser && !currentUser.onboarded) {
      navigate(onboardedRoutePattern)
    }
  }, [currentUser, navigate, onboardedRoutePattern])

  if (currentUser && !currentUser.onboarded) {
    return null
  }

  return <Outlet />
}
