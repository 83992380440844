import { Plus } from "lucide-react"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { Callout } from "~/ui/Callout"
import { Token } from "~/ui/Token"
import { useWizard } from "~/ui/Wizard"
import FintechTakesBefore1 from "~/images/fintechtakes-before1.png"
import FintechTakesBefore2 from "~/images/fintechtakes-before2.png"
import FintechTakesBefore3 from "~/images/fintechtakes-before3.png"
import { useSubscription } from "../SubscriptionProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useEffect, useRef } from "react"

export const FintechTakesBeforeYouMakeTheCallStep = () => {
  const { back, goToStep, meta } = useWizard()
  const { sendMakeTheMostEmail } = useSubscription()
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.CommunitySummaryViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const continueToCancel = () => {
    goToStep("LookupSaveBeforeLoseOffersStep", "forward", false)
  }

  const keepMembership = async () => {
    goToStep("LoadingStep", "forward", false)
    await sendMakeTheMostEmail()
    goToStep("SoGladYouStayedStep")
  }

  return (
    <div className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Before you make the call</DialogTitle>
        <div className="text-xs">
          We thought we'd share all the ways Fintech Takes can support you
        </div>
      </DialogHeader>

      <div className="flex flex-col gap-8 justify-start">
        <Callout
          medium={
            <img
              width={307}
              height={103}
              src={FintechTakesBefore1}
              alt="Convos with and feedback from the people behind your fav ecom brands"
            />
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token className="bg-primary">1</Token>
            <div className="font-bold">Discussions and debriefs</div>
          </div>
        </Callout>

        <Callout
          inverted
          medium={
            <div className="relative flex items-end justify-end">
              <img
                width={400}
                height={237}
                src={FintechTakesBefore2}
                alt="Resources that are actually relevant"
              />
              <div className="absolute w-16 top-0 bottom-0 right-0 bg-gradient-to-l from-card to-transparent" />
            </div>
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token className="bg-primary">2</Token>
            <div className="font-bold">
              Resources that are actually relevant
            </div>
          </div>
        </Callout>

        <Callout
          medium={
            <img
              width={286}
              height={191}
              src={FintechTakesBefore3}
              alt="Camaraderie and in person connection"
            />
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token className="bg-primary">3</Token>
            <div className="font-bold">
              Camaraderie and in person connection
            </div>
          </div>
        </Callout>
      </div>

      <Alert className="flex justify-between items-center bg-white mt-8 mb-8">
        <ul className="flex flex-col gap-1 font-semibold text-xs">
          <li className="flex gap-2 items-center">
            <Plus className="w-5 h-5 text-primary" /> Private conversations &
            groups
          </li>
          <li className="flex gap-2 items-center">
            <Plus className="w-5 h-5 text-primary" /> Monthly Office Hours with
            Alex
          </li>
          <li className="flex gap-2 items-center">
            <Plus className="w-5 h-5 text-primary" /> Curated 1:1 introductions{" "}
          </li>
          <li className="flex gap-2 items-center">
            <Plus className="w-5 h-5 text-primary" /> Premium research reports &
            more
          </li>
        </ul>
        <div>
          <Button onClick={keepMembership}>Yes, I'll Keep My Membership</Button>
        </div>
      </Alert>

      <Separator />

      <DialogFooter className="mt-8">
        <Button type="button" variant="link" size="inline" onClick={back}>
          Back
        </Button>
        <Button
          type="button"
          variant="link"
          size="inline"
          onClick={continueToCancel}
        >
          Continue to Cancel
        </Button>
      </DialogFooter>
    </div>
  )
}

FintechTakesBeforeYouMakeTheCallStep.displayName =
  "FintechTakesBeforeYouMakeTheCallStep"
