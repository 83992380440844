import { useSubscription } from "@apollo/client"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { MESSAGES_QUERY_DOCUMENT } from "./MessagesSectionInner"
import { RefObject } from "react"
import { useCurrentUser } from "~/common/GlobalsProvider"

const MESSAGE_POSTED_SUBSCRIPTION = gql(`
  subscription MessagePosted($userId: ID!) {
    messagePosted(userId: $userId) {
      currentUser {
        id
        unreadMessageCount
      }
      post {
        id
        ...Post_Card

        room {
          ...Room_MessageList
        }
      }
    }
  }
`)

export const useMessagesSubscription = ({
  scrollviewRef,
  visibleRoomIds,
  refetchRooms,
}: {
  scrollviewRef?: RefObject<HTMLDivElement | null>
  visibleRoomIds?: string[]
  refetchRooms?: () => void
} = {}) => {
  const currentUser = useCurrentUser()

  const { data, loading, error } = useSubscription(
    MESSAGE_POSTED_SUBSCRIPTION,
    {
      variables: { userId: currentUser.id },
      onData: ({ data: { data }, client }) => {
        console.log("got subscription data", data)
        invariant(data)

        if (!data.messagePosted?.post) return
        const roomId = data.messagePosted.post.room?.id
        if (!roomId) return

        const element = scrollviewRef?.current
        const atBottom = element && element.scrollTop >= 0

        // Refetch the rooms list if this room is not visible to the user
        if (
          visibleRoomIds &&
          refetchRooms &&
          !visibleRoomIds.includes(roomId)
        ) {
          refetchRooms()
        }

        const cache = client.cache

        // read the existing cache
        const existingMessages = cache.readQuery({
          query: MESSAGES_QUERY_DOCUMENT,
          variables: { roomId },
        })

        if (!existingMessages) return

        // create a new list of messages with the new message and old messages; unique by id
        const updatedMessages = [
          { node: data.messagePosted.post },
          ...existingMessages.posts.edges,
        ].filter(
          (m, i, self) => self.findIndex((t) => t.node.id === m.node.id) === i
        )

        // write the new list of messages to the cache
        cache.writeQuery({
          query: MESSAGES_QUERY_DOCUMENT,
          variables: { roomId },
          data: {
            posts: {
              edges: updatedMessages,
              pageInfo: existingMessages.posts.pageInfo,
            },
          },
        })

        // Scroll new message into view IF the user is at the bottom of the
        // div already
        setTimeout(() => {
          if (!atBottom) return
          element.scrollTop = 0
        }, 100)
      },
      onError: (e) => {
        console.error(e)
      },
    }
  )

  return { data, loading, error }
}
