import { ExternalShareLayout } from "./ExternalShareLayout"
import { AuthenticatedLayout } from "./AuthenticatedLayout"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const AuthenticatedOrExternalLayout = () => {
  const currentUser = useCurrentUser(false)

  if (currentUser) {
    return <AuthenticatedLayout />
  } else {
    return <ExternalShareLayout />
  }
}
