import { useEffect } from "react"
import toast from "react-hot-toast"
import { useConfig } from "~/common/ConfigProvider"

let ranOnce = false

export const FlashMessageToasts = () => {
  const { flashMessages } = useConfig()

  useEffect(() => {
    if (ranOnce) {
      return
    } else {
      ranOnce = true
    }

    flashMessages.forEach(([type, message]) => {
      if (type === "error" || type === "alert") {
        toast.error(message)
      } else {
        toast(message)
      }
    })
  }, [flashMessages])

  return null
}
