import { Wizard, WizardHandle, WizardProps } from "~/ui/Wizard"
import { IntakeStep } from "./wizard/IntakeStep"
import { CancellationReasonStep } from "./wizard/CancellationReasonStep"
import { LoadingStep } from "./wizard/LoadingStep"
import { SuccessStep } from "./wizard/SuccessStep"
import { ConfirmCancelStep } from "./wizard/ConfirmCancelStep"
import { CancelledStep } from "./wizard/CancelledStep"
import { ResumedStep } from "./wizard/ResumedStep"
import { TakeAnExpenseTemplateStep } from "./wizard/TakeAnExpenseTemplateStep"
import { SentAnExpenseTemplateStep } from "./wizard/SentAnExpenseTemplateStep"
import { SpecialOfferStep } from "./wizard/SpecialOfferStep"
import { SafeSpaceBeforeYouMakeTheCallStep } from "./wizard/SafeSpaceBeforeYouMakeTheCallStep"
import { BoardRoomBeforeYouMakeTheCallStep } from "./wizard/BoardRoomBeforeYouMakeTheCallStep"
import { LookupSaveBeforeLoseOffersStep } from "./wizard/LookupSaveBeforeLoseOffersStep"
import { SoGladYouStayedStep } from "./wizard/SoGladYouStayedStep"
import { PricingTableStep } from "./wizard/PricingTableStep"
import { forwardRef } from "react"
import { PostUpgradeStep } from "./wizard/PostUpgradeStep"
import { SameTierSuccessStep } from "./wizard/SameTierSuccessStep"
import { MigrateToTierStep } from "./wizard/MigrateToTierStep"
import { MarketinglandBeforeYouMakeTheCallStep } from "./wizard/MarketinglandBeforeYouMakeTheCallStep"
import { CheckoutStep } from "./wizard/CheckoutStep"
import { SubscribeToTierStep } from "./wizard/SubscribeToTierStep"
import { SocialContractStep } from "./wizard/SocialContractStep"
import { IntentStep } from "./wizard/IntentStep"
import { CreateCheckoutSessionStep } from "./wizard/CreateCheckoutSessionStep"
import { GoToMillionsBeforeYouMakeTheCallStep } from "./wizard/GoToMillionsBeforeYouMakeTheCallStep"
import { FintechTakesBeforeYouMakeTheCallStep } from "./wizard/FintechTakesBeforeYouMakeTheCallStep"

export const ManageSubscriptionWizard = forwardRef<
  WizardHandle,
  Omit<WizardProps, "children">
>((props, ref) => {
  return (
    <Wizard {...props} ref={ref}>
      <IntakeStep />
      <IntentStep />

      <SafeSpaceBeforeYouMakeTheCallStep />
      <BoardRoomBeforeYouMakeTheCallStep />
      <MarketinglandBeforeYouMakeTheCallStep />
      <GoToMillionsBeforeYouMakeTheCallStep />
      <FintechTakesBeforeYouMakeTheCallStep />

      <CancellationReasonStep />
      <CancelledStep />
      <LookupSaveBeforeLoseOffersStep />
      <SpecialOfferStep />
      <ConfirmCancelStep />
      <LoadingStep />
      <ResumedStep />
      <SentAnExpenseTemplateStep />
      <SoGladYouStayedStep />
      <SuccessStep />
      <TakeAnExpenseTemplateStep />

      <PricingTableStep />
      <PostUpgradeStep />
      <SameTierSuccessStep />
      <MigrateToTierStep />
      <SubscribeToTierStep />
      <CreateCheckoutSessionStep />
      <CheckoutStep />
      <SocialContractStep />
    </Wizard>
  )
})
