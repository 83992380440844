import * as React from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"

import { cn } from "~/lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, startAdornment, endAdornment, inputClassName, ...props },
    ref
  ) => {
    const ccls = useCommunityClassname()

    return (
      <div
        className={cn(
          "h-10 w-full rounded-md border border-mercury bg-white flex items-center justify-center",
          "focus-within:ring-2 focus-within:ring-offset-0 focus-within:ring-neutral-300 focus-within:outline-none",
          "data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50",
          className
        )}
        data-disabled={props.disabled}
      >
        {startAdornment && (
          <div className={cn("h-full flex items-center justify-center")}>
            {startAdornment}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "relative rounded-md w-full h-full flex px-3 py-2 text-sm shadow-none outline-none border-none focus:ring-0 focus:ring-offset-0 bg-transparent file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder focus-visible:outline-none focus-visible:border-none focus-visible:shadow-none",
            !!startAdornment && "pl-0",
            !!endAdornment && "pr-0",
            inputClassName,
            ccls({
              [CommunitySlug.Gotomillions]: "text-black",
              [CommunitySlug.Boardroom]: "text-black",
              [CommunitySlug.Marketingland]: "text-black",
              default: "",
            })
          )}
          ref={ref}
          {...props}
        />
        {endAdornment && <>{endAdornment}</>}
      </div>
    )
  }
)
Input.displayName = "Input"

const IconInputAdornment = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center justify-center w-10 h-full text-placeholder">
    {children}
  </div>
)

export { Input, IconInputAdornment }
