import { createContext, useContext } from "react"
import invariant from "tiny-invariant"
import {
  FeatureFlagEnum,
  User_CurrentUserFragment,
} from "~/__generated__/graphql"

export type Config = {
  csrfToken: string
  currentUserCache: User_CurrentUserFragment | null
  directUploadsUrl: string
  domain: string
  environment: string
  featureFlags: Partial<Record<FeatureFlagEnum, boolean>>
  flashMessages: [string, string][]
  hrisCostPerEmployeeArticleId: string
  hrisRetentionArticleId: string
  hrisTenureArticleId: string
  hrisTurnoverArticleId: string
  linkMetadataPath: string
  mapsApiKey: string
  qaToolsEnabled: boolean
  stripePublishableKey: string
  trackImpressions: boolean
  unfitRefundWindowInDays: number
  visuallyShowImpressions: boolean
}

const ConfigContext = createContext<Config | null>(null)

export const useConfig = () => {
  const ctx = useContext(ConfigContext)
  invariant(ctx, "useConfig must be used within a ConfigProvider")
  return ctx
}

export const ConfigProvider = ({
  config,
  children,
}: {
  config: Partial<Config>
  children: React.ReactNode
}) => {
  return (
    <ConfigContext.Provider
      value={{
        csrfToken: "",
        currentUserCache: null,
        directUploadsUrl: "",
        domain: "",
        environment: "",
        featureFlags: {},
        flashMessages: [],
        hrisCostPerEmployeeArticleId: "",
        hrisRetentionArticleId: "",
        hrisTenureArticleId: "",
        hrisTurnoverArticleId: "",
        linkMetadataPath: "",
        mapsApiKey: "",
        qaToolsEnabled: false,
        stripePublishableKey: "",
        trackImpressions: false,
        unfitRefundWindowInDays: 0,
        visuallyShowImpressions: false,
        ...config,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
