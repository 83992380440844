import * as React from "react"

import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"
import { Lock } from "lucide-react"

export const cardVariants = cva(
  "relative border border-mercury text-foreground bg-card w-full transition-transform duration-200 ease-in-out",
  {
    variants: {
      variant: {
        default: "rounded-2xl shadow-sm",
        ghost: "border-none",
        compact: "rounded-lg",
        square: "",
      },
      state: {
        default: "",
        selected: "border-highlight scale-105 shadow-lg",
      },
    },
    defaultVariants: {
      variant: "default",
      state: "default",
    },
  }
)

interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, variant, state, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("card", cardVariants({ variant, state }), className)}
      {...props}
    />
  )
)
Card.displayName = "Card"

const cardHeaderVariants = cva("", {
  variants: {
    variant: {
      default: "border-b border-mercury flex flex-col px-4 py-3",
      cardLabel:
        "flex items-center justify-center uppercase text-3xs px-4 py-1 font-semibold tracking-widest leading-5 rounded-t-lg",
    },
    background: {
      default: "",
      highlight: "bg-highlight text-white",
      primary: "bg-primary text-white",
    },
  },
  defaultVariants: {
    variant: "default",
    background: "default",
  },
})

interface CardHeaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardHeaderVariants> {
  locked?: boolean
}

const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className, variant, background, locked, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        cardHeaderVariants({ variant, background }),
        locked && "justify-between",
        className
      )}
      {...props}
    >
      <span>{children}</span>
      {locked && (
        <div>
          <Lock className="w-4 h-4" />
        </div>
      )}
    </div>
  )
)
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("font-semibold text-sm tracking-wide", className)}
    {...props}
  />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-slate-500 ", className)}
    {...props}
  />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-4 pt-0", className)} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex items-center p-4 rounded-b-2xl border border-mercury",
      className
    )}
    {...props}
  />
))
CardFooter.displayName = "CardFooter"

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
