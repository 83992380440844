import { formatCurrency } from "~/common/formatCurrency"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { Alert } from "~/ui/alert"
import { useCommunity } from "~/community/useCommunity"
import { formatDate } from "date-fns"
import { TierIntervalEnum, TierLevelEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { useTiers } from "~/tiers/TiersProvider"
import { useMemo } from "react"

export const SameTierSuccessStep = () => {
  const { close, meta } = useWizard()
  const { subscription } = useSubscription()
  const community = useCommunity()
  const currentUser = useCurrentUser()
  const { formatTierName } = useTiers()

  const selectedInterval = useMemo(() => {
    return meta.selectedInterval as TierIntervalEnum | null
  }, [meta.selectedInterval])

  const { currentTier, currentInterval } = useMemo(() => {
    return {
      currentTier: currentUser.tier,
      currentInterval: currentUser.tierInterval,
    }
  }, [currentUser.tier, currentUser.tierInterval])

  const upcomingInvoice = subscription?.upcomingInvoice

  return (
    <div className="max-w-2xl flex flex-col gap-4">
      <DialogHeader>
        <DialogTitle>
          {selectedInterval !== currentInterval ? (
            <>
              Your Membership change from{" "}
              {formatTierName(currentTier, currentInterval, false)} to{" "}
              {formatTierName(currentTier, selectedInterval, false)} has been
              processed.
            </>
          ) : (
            <>
              Thanks for your continuing your{" "}
              <span className="whitespace-nowrap">
                {community.name} {formatTierName(currentTier, currentInterval)}
              </span>
              !
            </>
          )}
        </DialogTitle>
      </DialogHeader>

      <Alert variant="info" className="flex flex-col gap-4">
        {selectedInterval !== currentInterval ? (
          <>
            <p>
              Your change from{" "}
              {formatTierName(currentTier, currentInterval, false)} to{" "}
              {formatTierName(currentTier, selectedInterval, false)} will go
              into effect after your current subscription period ends on{" "}
              {formatDate(subscription?.currentPeriodEnd, "MMMM d, yyyy")}.{" "}
              Note: you will not lose any benefits as this is simply a change in
              payment plan.
            </p>
            {upcomingInvoice && (
              <p>
                You will be charged{" "}
                {formatCurrency(upcomingInvoice.amountRemaining)} when your new{" "}
                {selectedInterval === TierIntervalEnum.Quarter
                  ? "Quarterly"
                  : "Annual"}{" "}
                payment preference goes into effect on{" "}
                {formatDate(subscription?.currentPeriodEnd, "MMMM d, yyyy")}.
              </p>
            )}
            <p>Thanks for being a part of the {community.name} community!</p>
          </>
        ) : (
          <>
            <p>
              You have not been charged anything at this time. Any pending
              changes to your membership tier have been discarded and you will
              remain on your current plan.
            </p>
            {currentTier!.level !== TierLevelEnum.Free && upcomingInvoice && (
              <p>
                Your next payment of{" "}
                {formatCurrency(upcomingInvoice.amountRemaining)} will be
                processed on{" "}
                {formatDate(subscription?.currentPeriodEnd, "MMMM d, yyyy")}.
              </p>
            )}
            <p>Thanks for being a part of the {community.name} community!</p>
          </>
        )}
      </Alert>

      <DialogFooter className="sm:flex-col gap-6 items-center mt-6">
        <Button type="button" variant="link" size="inline" onClick={close}>
          Close
        </Button>
      </DialogFooter>
    </div>
  )
}

SameTierSuccessStep.displayName = "SameTierSuccessStep"
