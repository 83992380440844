import { useLazyQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export const useTagSuggestions = () => {
  return useLazyQuery(TAG_SUGGESTIONS_QUERY_DOCUMENT)
}

export const TAG_SUGGESTIONS_QUERY_DOCUMENT = gql(`
  query TagSuggestions($content: String!) {
    tagSuggestions(content: $content) {
      id
      name
    }
  }
`)
