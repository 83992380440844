import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from "react"

import { useCallback } from "react"
import gql from "graphql-tag"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  Automation_DisplayFragment,
  RulesEngineEffectTypeEnum,
  RulesEngineEventTypeEnum,
} from "../__generated__/graphql"
import invariant from "tiny-invariant"
import { useLocalStorage } from "usehooks-ts"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { useConfig } from "~/common/ConfigProvider"
import { useCurrentUser } from "~/common/GlobalsProvider"

interface RulesEngineContextType {
  createRulesEngineEvent: (
    eventKey: RulesEngineEventTypeEnum,
    properties?: Record<string, any>
  ) => Promise<void>
}

const RulesEngineContext = createContext<RulesEngineContextType | null>(null)

const intentEvents = [
  "keydown",
  "click",
  "scroll",
  "wheel",
  "mousemove",
  "touchstart",
]

const VISIT_THRESHOLD = 1000 * 60 * 30 // 30 minutes

export const RulesEngineProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const currentUser = useCurrentUser(false)
  const { refetchUserOffers } = useUserOffers()
  const [, setMostRecentActivity] = useLocalStorage<number | null>(
    "mostRecentActivity",
    null
  )
  const [runCreateRulesEngineEvent] = useSafeMutation(CREATE_RULES_ENGINE_EVENT)
  const { qaToolsEnabled } = useConfig()

  const createRulesEngineEvent = useCallback(
    async (
      eventKey: RulesEngineEventTypeEnum,
      params?: Record<string, any>
    ) => {
      const { data, errors } = await runCreateRulesEngineEvent({
        variables: {
          input: {
            eventKey,
            params,
          },
        },
      })

      if (errors) {
        console.error(errors)
      }

      if (data?.rulesEngineEventCreate?.rulesEngineEvent?.automations) {
        if (
          data.rulesEngineEventCreate.rulesEngineEvent.automations.find(
            (a: Automation_DisplayFragment) => {
              return a.effects.find((e) => {
                return [
                  RulesEngineEffectTypeEnum.GiveOffer,
                  RulesEngineEffectTypeEnum.ExpireOffer,
                ].includes(e.type)
              })
            }
          )
        ) {
          refetchUserOffers()
        }
      }

      if (qaToolsEnabled) console.info("Rules Engine Event Created", data)

      return data
    },
    [runCreateRulesEngineEvent, refetchUserOffers, qaToolsEnabled]
  )

  const onUserActivity = useCallback(() => {
    setMostRecentActivity((mostRecentActivity) => {
      if (!currentUser || !currentUser.onboarded) return null

      const now = Date.now()
      if (!mostRecentActivity || now - mostRecentActivity > VISIT_THRESHOLD) {
        hasLoadedRef.current = true
        createRulesEngineEvent(RulesEngineEventTypeEnum.UserBecameActive)
      }

      return now
    })
  }, [setMostRecentActivity, createRulesEngineEvent, currentUser])

  const hasLoadedRef = useRef(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!hasLoadedRef.current) {
        hasLoadedRef.current = true
        onUserActivity()
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [createRulesEngineEvent, onUserActivity])

  useEffect(() => {
    intentEvents.forEach((event) => {
      window.addEventListener(event, onUserActivity)
    })

    return () => {
      intentEvents.forEach((event) => {
        window.removeEventListener(event, onUserActivity)
      })
    }
  }, [onUserActivity])

  return (
    <RulesEngineContext.Provider
      value={{
        createRulesEngineEvent,
      }}
    >
      {children}
    </RulesEngineContext.Provider>
  )
}

export const useRulesEngine = () => {
  const context = useContext(RulesEngineContext)
  invariant(context, "useRulesEngine must be used within a RulesEngineProvider")
  return context
}

gql(`
  fragment Automation_Display on Automation {
    id
    name
    effects {
      id
      type
    }
  }
`)

export const CREATE_RULES_ENGINE_EVENT = gql(`
  mutation RulesEngineEventCreate($input: RulesEngineEventCreateInput!) {
    rulesEngineEventCreate(input: $input) {
      rulesEngineEvent {
        id
        automations {
          id
          name
          effects {
            id
            type
          }
        }
      }
    }
  }
`)
