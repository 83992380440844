import { useState, useCallback, useEffect } from "react"

export type LatLng = {
  lat: number
  lng: number
}

export type NullLatLng = {
  lat: number | null
  lng: number | null
}

export const useDeviceLocation = () => {
  const [deviceLatLng, setDeviceLatLng] = useState<LatLng | null>(null)
  const [locationServicesEnabled, setLocationServicesEnabled] = useState<
    boolean | null
  >(null)
  const [fetchingDeviceLatLng, setFetchingDeviceLatLng] =
    useState<boolean>(false)
  const [fetchingPermissions, setFetchingPermissions] = useState<boolean>(true)

  const fetchLocation = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ refresh = true, callback = (_coords: LatLng) => {} } = {}) => {
      if (deviceLatLng && !refresh) {
        // already fetched
        return
      }

      if ("geolocation" in navigator) {
        setFetchingDeviceLatLng(true)
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            setDeviceLatLng(coords)
            setFetchingDeviceLatLng(false)
            setLocationServicesEnabled(true)
            callback(coords)
          },
          () => {
            setLocationServicesEnabled(false)
          }
        )
      } else {
        setLocationServicesEnabled(false)
      }
    },
    [deviceLatLng]
  )

  useEffect(() => {
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          setLocationServicesEnabled(true)
        }
      })
    }

    setFetchingPermissions(false)
  }, [setFetchingDeviceLatLng, setLocationServicesEnabled])

  return {
    fetchLocation,
    deviceLatLng,
    setDeviceLatLng,
    locationServicesEnabled,
    fetchingDeviceLatLng,
    fetchingPermissions,
  }
}
