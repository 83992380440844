import { RefObject, useCallback, useEffect, useState } from "react"

function useElementDimensions<T extends HTMLElement>(
  ref?: RefObject<T> | null
) {
  const [dimensions, setDimensions] = useState<{
    width: number
    height: number
  } | null>(null)

  const measure = useCallback(() => {
    const rect = ref?.current?.getBoundingClientRect()
    if (rect) {
      setDimensions({ width: rect.width, height: rect.height })
    }
  }, [ref, ref?.current])

  useEffect(() => {
    measure()
    window.addEventListener("resize", measure)
    return () => {
      window.removeEventListener("resize", measure)
    }
  }, [measure])

  return dimensions
}

export { useElementDimensions }
