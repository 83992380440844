import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { cn } from "~/lib/utils"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"

type SelectEntry = {
  label: string
  value: string
}

export const SelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  label,
  labelClassName,
  containerClassName,
  required,
  selectEntries,
  placeholder,
  hideErrorMessage = false,
}: {
  label: string
  labelClassName?: string
  containerClassName?: string
  description?: React.ReactNode
  control: Control<TFieldValues> | undefined
  name: TName
  required?: boolean
  selectEntries: SelectEntry[]
  placeholder?: string
  hideErrorMessage?: boolean
}) => {
  const { formState } = useFormContext()
  const ccls = useCommunityClassname()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName} required={required}>
            {label}
          </FormLabel>
          <Select onValueChange={field.onChange} value={field.value || ""}>
            <FormControl>
              <SelectTrigger
                className={cn(
                  !!formState.errors[name] && "border-red-500",
                  ccls({
                    [CommunitySlug.Gotomillions]: "border-primary",
                    default: "",
                  })
                )}
              >
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {selectEntries.map((entry, index) => (
                <SelectItem value={entry.value} key={index}>
                  {entry.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage className={cn(hideErrorMessage && "sr-only")} />
        </FormItem>
      )}
    />
  )
}
