import { ArticleContentTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useTranslation } from "~/common/Translation"
import { useCommunity } from "~/community/useCommunity"
import safespaceCollectionImage from "~/images/articles/safespace/collection.png"
import safespaceCommunityQAImage from "~/images/articles/safespace/community_q_a.png"
import safespaceCourseImage from "~/images/articles/safespace/course.png"
import safespaceEssayImage from "~/images/articles/safespace/essay.png"
import safespaceGuideImage from "~/images/articles/safespace/guide.png"
import safespaceHRTherapyImage from "~/images/articles/safespace/hr_therapy.png"
import safespaceInterviewImage from "~/images/articles/safespace/interview.png"
import safespaceMastermindImage from "~/images/articles/safespace/mastermind.png"
import safespaceTemplateImage from "~/images/articles/safespace/template.png"
import safespaceVendorDeepDiveImage from "~/images/articles/safespace/vendor_deep_dives.png"
import boardroomCollectionImage from "~/images/articles/boardroom/collection.png"
import boardroomDeepDiveImage from "~/images/articles/boardroom/deep_dive.png"
import boardroomEarningsAnalysisImage from "~/images/articles/boardroom/earnings_analysis.png"
import boardroomExpertPanelImage from "~/images/articles/boardroom/expert_panel.png"
import boardroomFinancialAnalysisImage from "~/images/articles/boardroom/financial_analysis.png"
import boardroomFiresideChatImage from "~/images/articles/boardroom/fireside_chat.png"
import boardroomNewsRoundupImage from "~/images/articles/boardroom/news_roundup.png"
import boardroomNoteImage from "~/images/articles/boardroom/note.png"
import boardroomProductBreakdownImage from "~/images/articles/boardroom/product_breakdown.png"
import boardroomResearchImage from "~/images/articles/boardroom/research.png"
import boardroomRoundtableImage from "~/images/articles/boardroom/roundtable.png"
import boardroomTrendsAnalysisImage from "~/images/articles/boardroom/trends_analysis.png"
import fintechtakesAlexTheoriesImage from "~/images/articles/fintechtakes/alexs_theories.png"
import fintechtakesCollectionImage from "~/images/articles/fintechtakes/collection.png"
import fintechtakesDeepDiveImage from "~/images/articles/fintechtakes/deep_dive.png"
import fintechtakesFintechOfficeHoursImage from "~/images/articles/fintechtakes/fintech_office_hours.png"
import fintechtakesMastermindImage from "~/images/articles/fintechtakes/mastermind.png"
import fintechtakesNewsRoundupImage from "~/images/articles/fintechtakes/news_roundup.png"
import fintechtakesResourceImage from "~/images/articles/fintechtakes/resource.png"
import gotomillionsBrandHighlightsImage from "~/images/articles/gotomillions/brand_highlights.png"
import gotomillionsChecklistImage from "~/images/articles/gotomillions/checklist.png"
import gotomillionsCollectionImage from "~/images/articles/gotomillions/collection.png"
import gotomillionsCommunityJamImage from "~/images/articles/gotomillions/community_jam.png"
import gotomillionsCreativeInspoImage from "~/images/articles/gotomillions/creative_inspo.png"
import gotomillionsDeepDiveImage from "~/images/articles/gotomillions/deep_dive.png"
import gotomillionsHostedByAriImage from "~/images/articles/gotomillions/hosted_by_ari.png"
import gotomillionsMustKnowsImage from "~/images/articles/gotomillions/must_knows.png"
import gotomillionsResourceImage from "~/images/articles/gotomillions/resource.png"
import gotomillionsSwipeFileImage from "~/images/articles/gotomillions/swipe_file.png"
import gotomillionsTemplateImage from "~/images/articles/gotomillions/template.png"
import marketinglandCoachsCornerImage from "~/images/articles/marketingland/coachs_corner.png"
import marketinglandCommunityJamImage from "~/images/articles/marketingland/community_jam.png"
import marketinglandCourseImage from "~/images/articles/marketingland/course.png"
import marketinglandDanielsThoughtsImage from "~/images/articles/marketingland/daniels_thoughts.png"
import marketinglandDeepDiveImage from "~/images/articles/marketingland/deep_dive.png"
import marketinglandGuideImage from "~/images/articles/marketingland/guide.png"
import marketinglandMarketerInterviewImage from "~/images/articles/marketingland/marketer_interview.png"
import marketinglandMarketingCaseStudyImage from "~/images/articles/marketingland/marketing_case_study.png"
import marketinglandMarketinglandImage from "~/images/articles/marketingland/marketingland_festival.png"
import marketinglandPlaybookImage from "~/images/articles/marketingland/playbook.png"
import marketinglandSwipeFileImage from "~/images/articles/marketingland/swipe_file.png"
import marketinglandTemplateImage from "~/images/articles/marketingland/template.png"

const defaultArticleImages: Record<
  CommunitySlug,
  Partial<Record<ArticleContentTypeEnum, string>>
> = {
  [CommunitySlug.Safespace]: {
    [ArticleContentTypeEnum.Collection]: safespaceCollectionImage,
    [ArticleContentTypeEnum.CommunityQa]: safespaceCommunityQAImage,
    [ArticleContentTypeEnum.Course]: safespaceCourseImage,
    [ArticleContentTypeEnum.Essay]: safespaceEssayImage,
    [ArticleContentTypeEnum.Guide]: safespaceGuideImage,
    [ArticleContentTypeEnum.HrTherapy]: safespaceHRTherapyImage,
    [ArticleContentTypeEnum.Interview]: safespaceInterviewImage,
    [ArticleContentTypeEnum.Mastermind]: safespaceMastermindImage,
    [ArticleContentTypeEnum.Template]: safespaceTemplateImage,
    [ArticleContentTypeEnum.VendorDeepDives]: safespaceVendorDeepDiveImage,
  },
  [CommunitySlug.Boardroom]: {
    [ArticleContentTypeEnum.Collection]: boardroomCollectionImage,
    [ArticleContentTypeEnum.DeepDive]: boardroomDeepDiveImage,
    [ArticleContentTypeEnum.EarningsAnalysis]: boardroomEarningsAnalysisImage,
    [ArticleContentTypeEnum.ExpertPanel]: boardroomExpertPanelImage,
    [ArticleContentTypeEnum.FinancialAnalysis]: boardroomFinancialAnalysisImage,
    [ArticleContentTypeEnum.FiresideChat]: boardroomFiresideChatImage,
    [ArticleContentTypeEnum.NewsRoundup]: boardroomNewsRoundupImage,
    [ArticleContentTypeEnum.Note]: boardroomNoteImage,
    [ArticleContentTypeEnum.ProductBreakdown]: boardroomProductBreakdownImage,
    [ArticleContentTypeEnum.Research]: boardroomResearchImage,
    [ArticleContentTypeEnum.Roundtable]: boardroomRoundtableImage,
    [ArticleContentTypeEnum.TrendAnalysis]: boardroomTrendsAnalysisImage,
  },
  [CommunitySlug.Gotomillions]: {
    [ArticleContentTypeEnum.BrandHighlights]: gotomillionsBrandHighlightsImage,
    [ArticleContentTypeEnum.Checklist]: gotomillionsChecklistImage,
    [ArticleContentTypeEnum.Collection]: gotomillionsCollectionImage,
    [ArticleContentTypeEnum.CommunityJam]: gotomillionsCommunityJamImage,
    [ArticleContentTypeEnum.CreativeInspo]: gotomillionsCreativeInspoImage,
    [ArticleContentTypeEnum.DeepDive]: gotomillionsDeepDiveImage,
    [ArticleContentTypeEnum.FunnelHacking]: gotomillionsHostedByAriImage,
    [ArticleContentTypeEnum.MustKnows]: gotomillionsMustKnowsImage,
    [ArticleContentTypeEnum.Resource]: gotomillionsResourceImage,
    [ArticleContentTypeEnum.SwipeFile]: gotomillionsSwipeFileImage,
    [ArticleContentTypeEnum.Template]: gotomillionsTemplateImage,
  },
  [CommunitySlug.Marketingland]: {
    [ArticleContentTypeEnum.CoachsCorner]: marketinglandCoachsCornerImage,
    [ArticleContentTypeEnum.CommunityJam]: marketinglandCommunityJamImage,
    [ArticleContentTypeEnum.Course]: marketinglandCourseImage,
    [ArticleContentTypeEnum.DanielsThoughts]: marketinglandDanielsThoughtsImage,
    [ArticleContentTypeEnum.DeepDive]: marketinglandDeepDiveImage,
    [ArticleContentTypeEnum.Guide]: marketinglandGuideImage,
    [ArticleContentTypeEnum.MarketerInterview]:
      marketinglandMarketerInterviewImage,
    [ArticleContentTypeEnum.MarketingCaseStudy]:
      marketinglandMarketingCaseStudyImage,
    [ArticleContentTypeEnum.MarketinglandFestival]:
      marketinglandMarketinglandImage,
    [ArticleContentTypeEnum.Playbook]: marketinglandPlaybookImage,
    [ArticleContentTypeEnum.SwipeFile]: marketinglandSwipeFileImage,
    [ArticleContentTypeEnum.Template]: marketinglandTemplateImage,
  },
  [CommunitySlug.Fintechtakes]: {
    [ArticleContentTypeEnum.AlexsTheories]: fintechtakesAlexTheoriesImage,
    [ArticleContentTypeEnum.Collection]: fintechtakesCollectionImage,
    [ArticleContentTypeEnum.DeepDive]: fintechtakesDeepDiveImage,
    [ArticleContentTypeEnum.FintechOfficeHours]:
      fintechtakesFintechOfficeHoursImage,
    [ArticleContentTypeEnum.Mastermind]: fintechtakesMastermindImage,
    [ArticleContentTypeEnum.NewsRoundup]: fintechtakesNewsRoundupImage,
    [ArticleContentTypeEnum.Resource]: fintechtakesResourceImage,
  },
}

export const ArticleImage = ({
  contentType,
  className,
  imageUrl,
}: {
  contentType: ArticleContentTypeEnum
  className?: string
  imageUrl?: string
}) => {
  const community = useCommunity()
  const defaultImage = defaultArticleImages[community.slug][contentType]
  const { t } = useTranslation("contentTypes")

  if (!imageUrl && !defaultImage) return null

  return (
    <img
      src={imageUrl || defaultImage}
      alt={`${community.name} ${t(contentType)}`}
      className={className}
      style={{}}
    />
  )
}
