import { useQuery } from "@apollo/client"
import { Lock, Pencil } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  Article,
  ArticleContentTypeEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { BookmarkIcon } from "~/articles/BookmarkIcon"
import { RetweetIcon } from "~/articles/RetweetIcon"
import { ShareIcon } from "~/articles/ShareIcon"
import { SubscribeToReadArticleBanner } from "~/articles/SubscribeToReadArticleBanner"
import { useArticleScreenAnalytics } from "~/articles/useArticleScreenAnalytics"
import { JustBookmarkedCardHeader } from "~/bookmarks/JustBookmarkedCardHeader"
import { useCurrentUser } from "~/common/GlobalsProvider"
import {
  articlePath,
  editArticlePath,
  libraryPath,
  signInPath,
} from "~/common/paths"
import { BookmarkCourseModal } from "~/content/BookmarkCourseModal"
import { CourseArticleContent } from "~/content/CourseArticleContent"
import { LessonFooter } from "~/content/LessonFooter"
import { RenderedArticle } from "~/editor/RenderedArticle"
import ArrowLeft from "~/images/icons/arrow-left.svg?react"
import ThumbsUpLight from "~/images/icons/thumbs-up-light.svg?react"
import { ArticleScreenLayout } from "~/layouts/ArticleScreenLayout"
import { cn } from "~/lib/utils"
import { ComposerContextProvider } from "~/post-composer/ComposerContext"
import { PostComposer } from "~/post-composer/PostComposer"
import { useUpvotes } from "~/post-composer/useUpvotes"
import { RepliesFeed } from "~/posts/RepliesFeed"
import { useArticleReplies } from "~/posts/useArticleReplies"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { ArticleCard } from "~/ui/ArticleCard"
import { Button, LinkButton } from "~/ui/button"
import { Card, CardHeader, CardTitle } from "~/ui/card"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

export enum ArticleScreenPermissionEnum {
  Preview = "PREVIEW",
  Permitted = "PERMITTED",
  UpgradeRequired = "UPGRADE_REQUIRED",
  Pending = "PENDING",
}

export const ArticleScreen = () => {
  const { articleId } = useParams()
  invariant(articleId)

  const currentUser = useCurrentUser(false)
  const [showJustBookmarkedHeader, setShowJustBookmarkedHeader] =
    useState(false)
  const [permission, setPermission] = useState(
    ArticleScreenPermissionEnum.Pending
  )

  const { data, loading, error } = useQuery(LIVE_ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })
  const { addUpvote, removeUpvote } = useUpvotes({ articleId })
  const articleReplies = useArticleReplies({ articleId, skip: !currentUser })
  const { logEvent } = useLogEvent()
  const navigate = useNavigate()
  const hasLoggedEvent = useRef(false)

  const article = data?.article
  useArticleScreenAnalytics({ articleId, article })

  useEffect(() => {
    if (!currentUser && article?.allowExternalShare) {
      setPermission(ArticleScreenPermissionEnum.Preview)
    } else if (!currentUser && article && !article.allowExternalShare) {
      navigate(signInPath.pattern)
    } else if (
      article?.premium &&
      (!currentUser || !currentUser.permissions.canViewPremiumArticles)
    ) {
      logEvent(
        AhoyEventTypeEnum.PremiumContentAccessed,
        {
          content_id: article.id,
        },
        hasLoggedEvent
      )
      setPermission(ArticleScreenPermissionEnum.UpgradeRequired)
    } else if (article) {
      setPermission(ArticleScreenPermissionEnum.Permitted)
    }
  }, [currentUser, article, logEvent, navigate])

  if (loading) return <LoadingIndicatorCentered />
  if (error || !article) return <Error message="Error loading article." />

  const editorjsContent =
    article.revision?.editorjsContent ||
    article.revision?.editorjsContentPreview

  const revision = article.revision
  invariant(revision)

  return (
    <ArticleScreenLayout article={article}>
      <Card>
        {showJustBookmarkedHeader && <JustBookmarkedCardHeader />}
        <CardHeader className="min-h-[60px]">
          {currentUser && (
            <CardTitle>
              <div className="flex justify-between items-center">
                {article.revision?.contentType ===
                  ArticleContentTypeEnum.Lesson &&
                article.approvedLesson?.section.course ? (
                  <Link
                    to={articlePath({
                      articleId:
                        article.approvedLesson.section.course.articleId,
                    })}
                    className="flex-initial flex items-center gap-2"
                  >
                    <ArrowLeft />
                    Back to Course
                  </Link>
                ) : (
                  <Link
                    to={libraryPath.pattern}
                    className="flex-initial flex items-center gap-2"
                  >
                    <ArrowLeft />
                    Back to Library
                  </Link>
                )}
                {article && (
                  <div className="flex flex-1 justify-end text-placeholder">
                    {article.currentUserCanUpdate.value && (
                      <LinkButton
                        to={editArticlePath({ articleId })}
                        className="text-2xs"
                        variant="ghost"
                        pre={<Pencil size={10} className="mr-1" />}
                      >
                        Edit
                      </LinkButton>
                    )}
                    <RetweetIcon article={article} />
                    <BookmarkIcon
                      article={article}
                      onBookmarkToggle={(bookmarkExists) =>
                        setShowJustBookmarkedHeader(bookmarkExists)
                      }
                    />
                    {article.allowExternalShare && (
                      <ShareIcon article={article} />
                    )}
                  </div>
                )}
              </div>
            </CardTitle>
          )}
        </CardHeader>

        <div className="px-6 md:px-10 xl:px-20 py-6 mb-4 border-b border-mercury">
          <ArticleCard
            article={article}
            clickable={false}
            variant="hero"
            className="sm:w-full border-0 h-72 shadow-none [&>div]:p-0"
          />
        </div>

        <div className="mx-6 md:mx-10 xl:mx-20 my-10">
          {permission === ArticleScreenPermissionEnum.Permitted ||
          permission === ArticleScreenPermissionEnum.Preview ? (
            <>
              {revision.contentType === ArticleContentTypeEnum.Course ? (
                <CourseArticleContent course={article.approvedCourse} />
              ) : editorjsContent ? (
                <RenderedArticle
                  editorData={editorjsContent}
                  preview={permission === ArticleScreenPermissionEnum.Preview}
                />
              ) : null}
            </>
          ) : permission === ArticleScreenPermissionEnum.UpgradeRequired ? (
            <div className="h-96 flex items-center justify-center">
              <PromptToUpgrade articleId={article.id} />
            </div>
          ) : null}
        </div>

        {permission === ArticleScreenPermissionEnum.Permitted && (
          <>
            <LessonFooter article={article} />

            <div className="border-t mercury" />

            <div className="my-9 flex flex-col justify-center items-center">
              <div className="mb-4 font-semibold tracking-wide">
                Was this content relevant to you?
              </div>

              <div
                className={cn(
                  "text-xl flex gap-2 items-center border border-mercury rounded-full px-5 py-3 cursor-pointer",
                  {
                    "border-blue-500 bg-blue-50": article.currentUserUpvoted,
                  }
                )}
                onClick={() => {
                  if (article.currentUserUpvoted) {
                    removeUpvote()
                  } else {
                    addUpvote()
                  }
                }}
              >
                <ThumbsUpLight height="24" />
                {article.upvotesCount > 0 && article.upvotesCount}
              </div>
            </div>

            <ComposerContextProvider composerRef={articleReplies.composerRef}>
              <div className="mx-6 md:mx-10 xl:mx-20">
                <PostComposer
                  onSave={articleReplies.createReply}
                  isSaving={articleReplies.replayIsSaving}
                  isReply
                  ref={articleReplies.composerRef}
                  title="Leave Feedback"
                />

                <div className="mt-8">
                  <RepliesFeed repliesResult={articleReplies.repliesResult} />
                </div>
              </div>
            </ComposerContextProvider>
            <BookmarkCourseModal article={article} />
          </>
        )}
      </Card>

      {permission === ArticleScreenPermissionEnum.Preview && (
        <SubscribeToReadArticleBanner article={article} />
      )}
    </ArticleScreenLayout>
  )
}

const PromptToUpgrade = ({ articleId }: { articleId: Article["id"] }) => {
  const promptedOnceRef = useRef(false)
  const { openSubscriptionWizard } = useSubscription()

  useEffect(() => {
    if (promptedOnceRef.current) return
    promptedOnceRef.current = true
    openSubscriptionWizard("PricingTableStep", {
      source: "PremiumArticle",
      requiredFeature: "canViewPremiumArticles",
      contentId: articleId,
    })
  }, [openSubscriptionWizard, articleId])

  return (
    <Button
      type="button"
      onClick={() =>
        openSubscriptionWizard("PricingTableStep", {
          source: "PremiumArticle",
          requiredFeature: "canViewPremiumArticles",
          contentId: articleId,
        })
      }
    >
      Upgrade to view this content
      <Lock className="w-4 h-4 ml-1" />
    </Button>
  )
}

export const LIVE_ARTICLE_QUERY_DOCUMENT = gql(`
  query LiveArticleQueryDocument($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_LiveContent
    }
  }
`)

gql(`
  fragment Article_LiveContent on Article {
    id
    state
    visibility
    featuredUntil
    createdAt
    markedLiveAt
    currentUserUpvoted
    currentUserBookmark {
      ...Bookmark
    }
    upvotesCount
    premium
    allowExternalShare
    collaborators {
      id
      user {
        id
        name
        ...User_Avatar
      }
    }
    revision: approvedRevision {
      id
      title
      description
      contentVersion
      tag {
        id
        name
      }
      contentType
      editorjsContent
      editorjsContentPreview
      portraitImageUrl
    }
    approvedCourse {
      ...Course_LiveContent
    }
    approvedLesson {
      id
      article {
        id
      }
      section {
        course {
          ...Course_LiveContent
        }
      }
    }

    currentUserCanUpdate { value }
  }
`)
