import { ApolloQueryResult, useQuery } from "@apollo/client"
import React, { createContext, useContext, useMemo, useRef } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  Channel_DisplayFragment,
  Community_DisplayFragment,
  GlobalsQuery,
  ScreenVariant_DisplayFragment,
  Tier_DisplayFragment,
  User_CurrentUserFragment,
  UserOffer_DisplayFragment,
} from "~/__generated__/graphql"
import { CurrentLocationProvider } from "~/auth/CurrentLocationContext"
import { ScreenVariantsProvider } from "~/screens/ScreenVariantsProvider"
import { TiersProvider } from "~/tiers/TiersProvider"
import { ConfirmProvider } from "~/ui/Confirm"
import { CommunityProvider } from "~/community/useCommunity"
import { LeadProvider } from "./LeadContext"
import { UserOffersProvider } from "~/offers/UserOffersProvider"

const GlobalsContext = createContext<{
  channels: Channel_DisplayFragment[]
  community: Community_DisplayFragment | null
  currentUser: User_CurrentUserFragment | null
  screenVariants: ScreenVariant_DisplayFragment[]
  refetch: () => Promise<ApolloQueryResult<GlobalsQuery>>
  tags: { id: string; name: string }[]
  tiers: Tier_DisplayFragment[]
  userOffers: UserOffer_DisplayFragment[]
} | null>(null)

export const useCommunity = () => {
  const ctx = useContext(GlobalsContext)
  invariant(ctx, "useCommunity must be used within a GlobalsProvider")
  return ctx.community
}

export const useChannels = () => {
  const ctx = useContext(GlobalsContext)
  invariant(ctx, "useChannels must be used within a GlobalsProvider")
  return ctx.channels
}

export const useGlobals = () => {
  const ctx = useContext(GlobalsContext)
  invariant(ctx, "useGlobals must be used within a GlobalsProvider")
  return ctx
}

export const useTags = () => {
  const ctx = useContext(GlobalsContext)
  invariant(ctx, "useTags must be used within a GlobalsProvider")
  return ctx.tags
}

function useCurrentUser(requireUserLoggedIn?: true): User_CurrentUserFragment
function useCurrentUser(
  requireUserLoggedIn: false
): User_CurrentUserFragment | null
function useCurrentUser(requireUserLoggedIn = true) {
  const ctx = useContext(GlobalsContext)
  invariant(ctx, "useCurrentUser must be used within a GlobalsProvider")
  if (requireUserLoggedIn) invariant(ctx.currentUser, "User must be logged in")
  return requireUserLoggedIn ? ctx.currentUser! : ctx.currentUser
}
export { useCurrentUser }

export const GlobalsProvider = ({
  children,
  communityId,
}: {
  children: React.ReactNode
  communityId: string
}) => {
  const hasPerformedInitialLoad = useRef(false)
  const { data, previousData, refetch } = useQuery(GLOBALS_QUERY_DOCUMENT, {
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60 * 5,
    variables: { communityId },
  })

  const context = useMemo(() => {
    if (!data) {
      if (!previousData) {
        return {
          channels: [],
          community: null,
          currentUser: null,
          screenVariants: [],
          tags: [],
          tiers: [],
          userOffers: [],
        }
      } else {
        return {
          channels: previousData.channels.nodes,
          community: previousData.community || null,
          currentUser: previousData.currentUser || null,
          screenVariants: previousData.screenVariants.nodes,
          tags: previousData.tags.nodes,
          tiers: previousData.tiers,
          userOffers: previousData.userOffers.nodes,
        }
      }
    }

    hasPerformedInitialLoad.current = true
    return {
      channels: data.channels.nodes,
      community: data.community || null,
      currentUser: data.currentUser || null,
      screenVariants: data.screenVariants.nodes,
      tags: data.tags.nodes,
      tiers: data.tiers,
      userOffers: data.userOffers.nodes,
    }
  }, [data, previousData])

  if (!hasPerformedInitialLoad.current) return null
  return (
    <GlobalsContext.Provider value={{ ...context, refetch }}>
      <CommunityProvider id={communityId}>
        <ScreenVariantsProvider>
          <TiersProvider>
            <CurrentLocationProvider>
              <LeadProvider>
                <UserOffersProvider>
                  <ConfirmProvider>{children}</ConfirmProvider>
                </UserOffersProvider>
              </LeadProvider>
            </CurrentLocationProvider>
          </TiersProvider>
        </ScreenVariantsProvider>
      </CommunityProvider>
    </GlobalsContext.Provider>
  )
}

const GLOBALS_QUERY_DOCUMENT = gql(`
  query Globals($communityId: ID!) {
    channels {
      nodes {
        ...Channel_Display
      }
    }

    community(id: $communityId) {
      ...Community_Display
    }

    currentUser: user {
      ...User_CurrentUser
    }

    screenVariants {
      nodes {
        ...ScreenVariant_Display
      }
    }

    tags {
      nodes {
        id
        name
      }
    }

    tiers {
      ...Tier_Display
    }

    userOffers(
      statuses: [CREATED, REDEEMED, ACKNOWLEDGED, ACCEPTED]
    ) {
      nodes {
        ...UserOffer_Display
      }
    }
  }
`)
