import { Star } from "lucide-react"
import { User, UserRoleEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"
import CoachHatIcon from "~/images/icons/coach-hat.svg?react"
import { SimpleTooltip } from "~/ui/SimpleTooltip"

type UserFragment = Pick<User, "name" | "firstName" | "lastName"> & {
  coach?: User["coach"]
  admin?: User["admin"]
  role?: User["role"]
}

export interface UserNameProps {
  user: UserFragment
  formatter?: (userName: string) => React.ReactNode
  customName?: React.ReactNode | string
}

export const UserName = ({ user, formatter, customName }: UserNameProps) => {
  const currentUser = useCurrentUser(false)
  const name =
    customName ||
    user.name ||
    [user.firstName, user.lastName].filter(Boolean).join(" ")
  return (
    <span className="inline-flex gap-2 items-center">
      {formatter ? formatter(user.name!) : name}
      {user.coach && <CoachHatIcon />}
      {currentUser?.admin && user.role === UserRoleEnum.Superadmin && (
        <SimpleTooltip content="Superadmin">
          <Star size={12} />
        </SimpleTooltip>
      )}
    </span>
  )
}
