import invariant from "tiny-invariant"

export const getMetaVars = (names: string[]) => {
  const metaVars: Record<string, any> = {}
  names.forEach((name) => {
    const metaTag = document.querySelector(`meta[name=${name}]`)
    if (!metaTag) {
      console.warn(`missing meta tag: ${name}`)
      return
    }
    const content = metaTag.getAttribute("content")
    if (!content) {
      console.warn(`missing content for meta tag: ${name}`)
    }
    metaVars[name] = content
  })
  return metaVars
}

export const getMetaVar = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag)
  const content = metaTag.getAttribute("content")

  invariant(content)

  return content
}

export const getMetaVarMaybe = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  const content = metaTag?.getAttribute("content") || null
  return content
}
