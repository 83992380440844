import { useLocalStorage } from "@uidotdev/usehooks"
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { gql } from "~/__generated__"
import { useGlobals } from "~/common/GlobalsProvider"
import { LatLng, useDeviceLocation } from "~/common/useDeviceLocation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"

interface CurrentLocationContextType {
  enableLocation: boolean | undefined
  updateUserLocation: (args: { checkPermissions?: boolean }) => void
  setEnableLocationAndDevicePreference: (preference: boolean) => void
  deviceLatLng: LatLng | null
  fetchLocation: () => void
  fetchingDeviceLatLng: boolean
  fetchingPermissions: boolean
  locationServicesEnabled: boolean | null
}

const CurrentLocationContext = createContext<CurrentLocationContextType | null>(
  null
)

export const useCurrentLocation = () => {
  const ctx = React.useContext(CurrentLocationContext)
  if (!ctx) {
    throw new Error(
      "useCurrentLocation must be used within a CurrentLocationProvider"
    )
  }
  return ctx
}

export const CurrentLocationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { currentUser } = useGlobals()
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const [deviceLocationPreference] = useLocalStorage("enableLocation", false)
  const enableLocation = useMemo(() => {
    return !!currentUser?.deviceLocationEnabled
  }, [currentUser])

  const {
    deviceLatLng,
    setDeviceLatLng,
    fetchLocation,
    fetchingDeviceLatLng,
    fetchingPermissions,
    locationServicesEnabled,
  } = useDeviceLocation()

  const [runUpdateCurrentLocation] = useSafeMutation(
    USER_UPDATE_CURRENT_LOCATION_MUTATION
  )

  const [runDestroyCurrentLocation] = useSafeMutation(
    USER_DESTROY_CURRENT_LOCATION_MUTATION
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (deviceLatLng && currentUser) {
        runUpdateCurrentLocation({
          variables: {
            input: { lat: deviceLatLng.lat, lng: deviceLatLng.lng },
          },
        })
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [runUpdateCurrentLocation, deviceLatLng, currentUser])

  const setEnableLocationAndDevicePreference = useCallback(
    (val: boolean) => {
      runUserUpdate({
        variables: {
          input: {
            deviceLocationEnabled: val,
          },
        },
      })

      if (!val) {
        setDeviceLatLng(null)
        runDestroyCurrentLocation({
          variables: {
            input: {},
          },
        })
      }
    },
    [setDeviceLatLng, runUserUpdate, runDestroyCurrentLocation]
  )

  const updateUserLocation = useCallback(
    ({ checkPermissions = true }) => {
      const enabled =
        (checkPermissions && enableLocation && locationServicesEnabled) ||
        !checkPermissions

      if (enabled && !fetchingDeviceLatLng && currentUser) {
        fetchLocation({
          refresh: true,
          callback: (coords: LatLng) => {
            runUpdateCurrentLocation({ variables: { input: coords } })
          },
        })
      }
    },
    [
      fetchLocation,
      locationServicesEnabled,
      enableLocation,
      fetchingDeviceLatLng,
      runUpdateCurrentLocation,
      currentUser,
    ]
  )

  const hasBackfilledDeviceLocationEnabled = useRef(false)
  useEffect(() => {
    if (
      currentUser &&
      currentUser.deviceLocationEnabled === null &&
      !hasBackfilledDeviceLocationEnabled.current
    ) {
      // Previously, the location enabled permissions existed only on the frontend client. If this is the case, update
      // the user record to set `deviceLocationEnabled` to true
      hasBackfilledDeviceLocationEnabled.current = true

      const updateUser = async () => {
        await runUserUpdate({
          variables: {
            input: {
              deviceLocationEnabled: deviceLocationPreference,
            },
          },
        })
      }
      updateUser()
    }
  }, [currentUser, runUserUpdate, deviceLocationPreference])

  // This is to trigger an update on Mount and whenever the location services
  // state is toggled.
  useEffect(() => {
    if (locationServicesEnabled) {
      updateUserLocation({ checkPermissions: true })
    }
  }, [locationServicesEnabled])

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateUserLocation({ checkPermissions: true })
    }, 60_000)

    return () => clearInterval(intervalId)
  }, [updateUserLocation])

  const value = useMemo(() => {
    return {
      enableLocation: enableLocation,
      updateUserLocation: updateUserLocation,
      setEnableLocationAndDevicePreference:
        setEnableLocationAndDevicePreference,
      deviceLatLng: deviceLatLng,
      fetchLocation: fetchLocation,
      fetchingDeviceLatLng: fetchingDeviceLatLng,
      fetchingPermissions: fetchingPermissions,
      locationServicesEnabled: locationServicesEnabled,
    }
  }, [
    enableLocation,
    updateUserLocation,
    setEnableLocationAndDevicePreference,
    deviceLatLng,
    fetchLocation,
    fetchingDeviceLatLng,
    fetchingPermissions,
    locationServicesEnabled,
  ])

  return (
    <CurrentLocationContext.Provider value={value}>
      {children}
    </CurrentLocationContext.Provider>
  )
}

const USER_UPDATE_CURRENT_LOCATION_MUTATION = gql(`
  mutation UserCurrentLocationUpdate($input: UserCurrentLocationUpdateInput!) {
    userCurrentLocationUpdate(input: $input) {
      currentLocation {
        id
        lat
        lng
      }
    }
  }
`)

const USER_DESTROY_CURRENT_LOCATION_MUTATION = gql(`
  mutation UserCurrentLocationDestroy($input: UserCurrentLocationDestroyInput!) {
    userCurrentLocationDestroy(input: $input) {
      success
    }
  }
`)
