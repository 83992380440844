import { Share } from "lucide-react"
import { Article_LiveContentFragment } from "~/__generated__/graphql"
import { IconButton } from "~/ui/IconButton"
import { ShareModal } from "./ShareModal"
import { useState } from "react"

export const ShareIcon = ({
  article,
}: {
  article: Article_LiveContentFragment
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        className="border-0"
        variant="filled"
        aria-label="Share article"
        onClick={() => setOpen(true)}
      >
        <Share size={14} />
      </IconButton>
      <ShareModal article={article} open={open} onOpenChange={setOpen} />
    </>
  )
}
