import { ArticleContentTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { cn } from "~/lib/utils"
import { Compass, FolderClosed, Mic, Scroll } from "lucide-react"
import { useTranslation } from "~/common/Translation"
import Vod from "~/images/icons/vod.svg?react"
import { useCommunity } from "~/community/useCommunity"
import { useMemo } from "react"
import { useCurrentUser } from "~/common/GlobalsProvider"

export type ArticleCategory =
  | "discover"
  | "newsletters"
  | "ondemand"
  | "resources"
  | "podcasts"

export const categoriesToContentTypes: Record<
  ArticleCategory,
  ArticleContentTypeEnum[]
> = {
  discover: Object.values(ArticleContentTypeEnum).filter(
    (contentType) => contentType !== ArticleContentTypeEnum.Lesson
  ),
  newsletters: [
    ArticleContentTypeEnum.Essay,
    ArticleContentTypeEnum.CommunityQa,
    ArticleContentTypeEnum.DeepDive,
    ArticleContentTypeEnum.VendorDeepDives,
    ArticleContentTypeEnum.NewsRoundup,
    ArticleContentTypeEnum.TrendAnalysis,
    ArticleContentTypeEnum.ProductBreakdown,
    ArticleContentTypeEnum.BrandHighlights,
    ArticleContentTypeEnum.CreativeInspo,
    ArticleContentTypeEnum.MustKnows,
    ArticleContentTypeEnum.MarketingCaseStudy,
    ArticleContentTypeEnum.MarketerInterview,
    ArticleContentTypeEnum.DanielsThoughts,
  ],
  ondemand: [
    ArticleContentTypeEnum.HrTherapy,
    ArticleContentTypeEnum.Mastermind,
    ArticleContentTypeEnum.Interview,
    ArticleContentTypeEnum.ExpertPanel,
    ArticleContentTypeEnum.FiresideChat,
    ArticleContentTypeEnum.Roundtable,
    ArticleContentTypeEnum.FunnelHacking,
    ArticleContentTypeEnum.CommunityJam,
    ArticleContentTypeEnum.MarketinglandFestival,
    ArticleContentTypeEnum.CoachsCorner,
    ArticleContentTypeEnum.FintechOfficeHours,
  ],
  resources: [
    ArticleContentTypeEnum.Template,
    ArticleContentTypeEnum.Course,
    ArticleContentTypeEnum.Guide,
    ArticleContentTypeEnum.Collection,
    ArticleContentTypeEnum.Note,
    ArticleContentTypeEnum.FinancialAnalysis,
    ArticleContentTypeEnum.EarningsAnalysis,
    ArticleContentTypeEnum.Research,
    ArticleContentTypeEnum.SwipeFile,
    ArticleContentTypeEnum.Checklist,
    ArticleContentTypeEnum.Resource,
    ArticleContentTypeEnum.Playbook,
    ArticleContentTypeEnum.AlexsTheories,
  ],
  podcasts: [
    ArticleContentTypeEnum.FintechRecap,
    ArticleContentTypeEnum.BankNerdCorner,
    ArticleContentTypeEnum.NotFintechInvestmentAdvice,
  ],
}

export const adminContentTypes: Record<
  CommunitySlug,
  ArticleContentTypeEnum[]
> = {
  [CommunitySlug.Safespace]: [
    ArticleContentTypeEnum.Essay,
    ArticleContentTypeEnum.CommunityQa,
    ArticleContentTypeEnum.VendorDeepDives,
    ArticleContentTypeEnum.HrTherapy,
  ],
  [CommunitySlug.Boardroom]: [
    ArticleContentTypeEnum.NewsRoundup,
    ArticleContentTypeEnum.TrendAnalysis,
    ArticleContentTypeEnum.DeepDive,
    ArticleContentTypeEnum.ProductBreakdown,
  ],
  [CommunitySlug.Gotomillions]: [
    ArticleContentTypeEnum.DeepDive,
    ArticleContentTypeEnum.BrandHighlights,
    ArticleContentTypeEnum.CreativeInspo,
    ArticleContentTypeEnum.FunnelHacking,
    ArticleContentTypeEnum.MustKnows,
  ],
  [CommunitySlug.Marketingland]: [
    ArticleContentTypeEnum.MarketingCaseStudy,
    ArticleContentTypeEnum.MarketerInterview,
    ArticleContentTypeEnum.DanielsThoughts,
    ArticleContentTypeEnum.MarketinglandFestival,
    ArticleContentTypeEnum.CoachsCorner,
    ArticleContentTypeEnum.DeepDive,
  ],
  [CommunitySlug.Fintechtakes]: [
    ArticleContentTypeEnum.NewsRoundup,
    ArticleContentTypeEnum.DeepDive,
    ArticleContentTypeEnum.VendorDeepDives,
    ArticleContentTypeEnum.FintechOfficeHours,
    ArticleContentTypeEnum.AlexsTheories,
    ArticleContentTypeEnum.FintechRecap,
    ArticleContentTypeEnum.BankNerdCorner,
    ArticleContentTypeEnum.NotFintechInvestmentAdvice,
  ],
}

export const useContentTypes = (
  category?: ArticleCategory,
  hideAdminContentTypes = false
) => {
  const { t: translateContentTypes } = useTranslation("contentTypes")
  const community = useCommunity()
  const currentUser = useCurrentUser()

  return useMemo(() => {
    return categoriesToContentTypes[category || "discover"]
      .filter((type) => {
        if (!community.contentTypes.includes(type)) return false
        return !hideAdminContentTypes || currentUser.admin
          ? true
          : !adminContentTypes[community.slug].includes(type)
      })
      .sort((a, b) => {
        const categoryA = Object.entries(categoriesToContentTypes).find(
          ([k, v]) => k !== "discover" && v.includes(a)
        )?.[0]
        const categoryB = Object.entries(categoriesToContentTypes).find(
          ([k, v]) => k !== "discover" && v.includes(b)
        )?.[0]

        if (!categoryA && !categoryB) return 0
        if (!categoryA) return 1
        if (!categoryB) return -1
        if (categoryA === categoryB)
          return translateContentTypes(a).localeCompare(
            translateContentTypes(b)
          )
        if (categoryA === "newsletters") return -1
        if (categoryB === "newsletters") return 1
        if (categoryA === "ondemand") return -1
        if (categoryB === "ondemand") return 1
        if (categoryA === "resources") return -1
        if (categoryB === "resources") return 1
        return 0
      })
  }, [category, community, currentUser])
}

export const useContentTypeOptions = (
  selectedCategory: ArticleCategory = "discover",
  iconClassName = "w-4 h-4",
  hideAdminContentTypes = false
) => {
  const { t: translateContentTypes } = useTranslation("contentTypes")
  const contentTypes = useContentTypes(selectedCategory, hideAdminContentTypes)

  return contentTypes.map((value) => {
    return {
      value: value,
      label: (
        <div className="flex flex-row justify-start items-center space-x-2">
          <ContentTypeIcon contentType={value} className={iconClassName} />
          <div className="whitespace-nowrap">
            {translateContentTypes(value)}
          </div>
        </div>
      ),
    }
  })
}

export const ContentTypeIconWithToolTip = ({
  className = "w-4 h-4",
  contentType,
  colorClass,
}: {
  className?: string
  contentType: ArticleContentTypeEnum
  colorClass?: string
}) => {
  const { t: contentTypes } = useTranslation("contentTypes")
  return (
    <TooltipProvider>
      <Tooltip delayDuration={40}>
        <TooltipTrigger className={cn("z-30", colorClass, "cursor-default")}>
          {contentType && (
            <ContentTypeIcon contentType={contentType} className={className} />
          )}
        </TooltipTrigger>
        <TooltipContent>{contentTypes(contentType)}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export const ContentTypeIcon = ({
  category,
  contentType,
  className,
}: {
  category?: ArticleCategory
  contentType?: ArticleContentTypeEnum
  className?: string
}) => {
  if (contentType) {
    const category = (Object.entries(categoriesToContentTypes)
      .filter(([key]) => key !== "discover")
      .find(([, contentTypes]) => contentTypes.includes(contentType))?.[0] ||
      "discover") as ArticleCategory
    return <ContentTypeIcon category={category} className={className} />
  }

  switch (category) {
    case "discover":
      return <Compass className={className} />
    case "newsletters":
      return <Scroll className={className} />
    case "ondemand":
      return <Vod className={className} />
    case "resources":
      return <FolderClosed className={className} />
    case "podcasts":
      return <Mic className={className} />
    default:
      return <></>
  }
}
