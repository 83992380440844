import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ComposerOnSave } from "./PostComposer"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const useCreateReply = ({
  parentPostId,
  parentReplyId,
  articleId,
  onSuccess,
}: {
  parentPostId?: string
  parentReplyId?: string
  articleId?: string
  onSuccess: () => void
}) => {
  const [runPostCreate, postCreateResult] = useSafeMutation(
    REPLY_CREATE_MUTATION
  )

  const { logEvent } = useLogEvent()

  const createReply: ComposerOnSave = async ({
    content,
    channelId,
    tagId,
    attachmentIds,
    videoUrl,
  }) => {
    const { data, errors } = await runPostCreate({
      variables: {
        input: {
          content,
          channelId,
          tagId,
          attachmentIds,
          parentPostId,
          parentReplyId,
          articleId,
          videoUrl,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      invariant(data?.postCreate.post.id)

      const mentionedUsers = data.postCreate.post.mentionedUsers || []
      const mentionedGroups = data.postCreate.post.mentionedGroups || []

      logEvent(AhoyEventTypeEnum.PostRepliedTo, {
        post_id: data.postCreate.post.id,
        parent_post_id: parentPostId,
        is_anon: data.postCreate.post.anonymous,
        channel_id: data.postCreate.post.channel?.id,
        channel_name: data.postCreate.post.channel?.name || "feed",
        tagged_entities: mentionedUsers
          .map((u) => {
            return { id: u.id, type: "user" }
          })
          .concat(
            mentionedGroups.map((u) => {
              return { id: u.id, type: "group" }
            })
          ),
        number_entities_tagged: mentionedUsers.length + mentionedGroups.length,
      })
      onSuccess()
      return true
    }
  }

  return { createReply, replayIsSaving: postCreateResult.loading }
}

const REPLY_CREATE_MUTATION = gql(`
  mutation ReplyCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      post {
        id
        anonymous
        channel {
          id
          name
        }
        mentionedUsers {
          id
        }
        mentionedGroups {
          id
        }
        parentPost {
          id
          ...Post_Card
        }
        parentReply {
          id
          ...Post_Card
        }
      }
      currentUser {
        id
        onboardingActions {
          introduceYourself
        }
      }
    }
  }
`)
