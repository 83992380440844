import { useQuery } from "@apollo/client"
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  Channel,
  ChannelsQuery,
  ChannelVisibilityEnum,
  GroupUserStatusEnum,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { channelPath, feedPath } from "./paths"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import { DialogProps } from "@radix-ui/react-dialog"
import { Muted } from "~/ui/typography"
import { Card, CardContent } from "~/ui/card"
import ChatLocked from "../images/icons/chat-locked.svg?react"
import { Button } from "~/ui/button"
import { useSafeMutation } from "./useSafeMutation"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser, useGlobals } from "./GlobalsProvider"

const ChannelsContext = createContext<{
  channels: ChannelsQuery["channels"]["nodes"]
  filteredChannels: ChannelsQuery["channels"]["nodes"]
  introductionsChannel: ChannelsQuery["channels"]["nodes"][0] | undefined
  loadingFilteredChannels: boolean
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  goToChannel: (slug: string) => void
  joinChannel: (slug: string) => void
  dismissChannel: (slug: string) => void
  getRequiresUpgradeGroupUser: (channel: { groups: { id: string }[] }) => any
  refetch: () => void
} | null>(null)

export const useChannels = () => {
  const context = useContext(ChannelsContext)
  invariant(context, "useChannels must be used within a ChannelsProvider")
  return context
}

export const ChannelsProvider = ({ children }: PropsWithChildren<object>) => {
  const [searchTerm, setSearchTerm] = useState("")
  const loggedChannelIds = useRef(new Set<string>())
  const { logEvent, currentPageviewId } = useLogEvent()

  useEffect(() => {
    loggedChannelIds.current.clear()
  }, [currentPageviewId])

  const { channels: unsortedChannels, refetch } = useGlobals()

  const { data: filteredChannels, loading: loadingFilteredChannels } = useQuery(
    CHANNELS_QUERY_DOCUMENT,
    {
      variables: { searchTerm },
      skip: !searchTerm,
    }
  )
  const channels = useMemo(() => {
    return [...unsortedChannels].sort((a, b) => {
      // sort by visibility and then by name, case-insensitive
      if (
        a.visibility === ChannelVisibilityEnum.Private &&
        b.visibility === ChannelVisibilityEnum.Public
      ) {
        return -1
      }

      if (
        a.visibility === ChannelVisibilityEnum.Public &&
        b.visibility === ChannelVisibilityEnum.Private
      ) {
        return 1
      }

      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
  }, [unsortedChannels])

  const introductionsChannel = useMemo(
    () => channels?.find((t) => t.usedForIntroductions),
    [channels]
  )
  const currentUser = useCurrentUser(false)
  const { openSubscriptionWizard } = useSubscription()

  const [isJoinChannelModalOpen, setIsJoinChannelModalOpen] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState<
    | (Pick<
        Channel,
        | "id"
        | "modalHeader"
        | "modalSubheader"
        | "slug"
        | "name"
        | "description"
        | "imageUrl"
        | "visibility"
      > & { groups: { id: string }[] })
    | null
  >(null)

  const navigate = useNavigate()
  const goToChannel = useCallback(
    (slug: string) => {
      const channel = channels.find((c) => c.slug === slug)
      if (!channel) {
        return
      }

      if (!channel.hasCurrentUserJoined) {
        // open the modal
        setSelectedChannel(channel)
        if (!loggedChannelIds.current.has(channel.id)) {
          loggedChannelIds.current.add(channel.id)
          logEvent(AhoyEventTypeEnum.PrivateChannelModalViewed, {
            channel_id: channel.id,
          })
        }
        setIsJoinChannelModalOpen(true)
        return
      }

      navigate(channelPath({ channelSlug: channel.slug }))
    },
    [channels, navigate, logEvent]
  )

  const getRequiresUpgradeGroupUser = useCallback(
    (channel: { groups: { id: string }[] }) => {
      if (!currentUser) {
        return
      }
      const matchingGroupUsers = currentUser.groupUsers.filter((gu) => {
        return channel.groups.some((g) => g.id === gu.group.id)
      })

      const requiresUpgradeGroupUser = matchingGroupUsers.find((gu) => {
        return gu.status === GroupUserStatusEnum.RequiresUpgrade
      })

      return requiresUpgradeGroupUser
    },
    [currentUser]
  )

  const [runJoinChannel] = useSafeMutation(CHANNEL_JOIN_MUTATION)
  const joinChannel = useCallback(
    async (slug: string) => {
      const channel = channels.find((c) => c.slug === slug)
      if (!channel) {
        return
      }

      const requiresUpgradeGroupUser = getRequiresUpgradeGroupUser(channel)

      if (requiresUpgradeGroupUser) {
        openSubscriptionWizard("PricingTableStep", {
          minimumTierLevel: requiresUpgradeGroupUser.group.minimumTierLevel,
          maximumTierLevel: requiresUpgradeGroupUser.group.maximumTierLevel,
        })
        return
      }

      await runJoinChannel({
        variables: {
          input: {
            channelId: channel.id,
          },
        },
      })

      logEvent(AhoyEventTypeEnum.PrivateChannelInviteAccepted, {
        channel_id: channel.id,
      })

      setIsJoinChannelModalOpen(false)
      setSelectedChannel(null)
      navigate(channelPath({ channelSlug: channel.slug }))
    },
    [
      runJoinChannel,
      channels,
      navigate,
      openSubscriptionWizard,
      getRequiresUpgradeGroupUser,
      logEvent,
    ]
  )

  const [runDismissChannel] = useSafeMutation(CHANNEL_DISMISS_MUTATION)
  const dismissChannel = useCallback(
    async (slug: string) => {
      const channel = channels.find((c) => c.slug === slug)
      if (!channel) {
        return
      }

      await runDismissChannel({
        variables: {
          input: {
            channelId: channel.id,
          },
        },
      })
    },
    [runDismissChannel, channels]
  )

  return (
    <ChannelsContext.Provider
      value={{
        channels,
        introductionsChannel,
        filteredChannels: searchTerm
          ? filteredChannels?.channels.nodes || []
          : channels,
        loadingFilteredChannels,
        searchTerm,
        setSearchTerm,
        goToChannel,
        joinChannel,
        dismissChannel,
        getRequiresUpgradeGroupUser,
        refetch,
      }}
    >
      <JoinChannelModal
        open={isJoinChannelModalOpen}
        onOpenChange={setIsJoinChannelModalOpen}
        channel={selectedChannel}
      />
      {children}
    </ChannelsContext.Provider>
  )
}

const CHANNEL_JOIN_MUTATION = gql(`
  mutation ChannelJoin($input: ChannelJoinInput!) {
    channelJoin(input: $input) {
      channel {
        ...Channel_Display
      }
    }
  }
`)

const CHANNEL_DISMISS_MUTATION = gql(`
  mutation ChannelDismiss($input: ChannelDismissInput!) {
    channelDismiss(input: $input) {
      channel {
        ...Channel_Display
      }
    }
  }
`)

const JoinChannelModal = ({
  channel,
  onOpenChange,
  ...dialogOptions
}: {
  channel:
    | (Pick<
        Channel,
        | "modalHeader"
        | "modalSubheader"
        | "slug"
        | "name"
        | "description"
        | "imageUrl"
        | "visibility"
      > & { groups: { id: string }[] })
    | null
} & DialogProps) => {
  const { joinChannel, getRequiresUpgradeGroupUser } = useChannels()
  const requiresUpgrade = useMemo(
    () => channel && !!getRequiresUpgradeGroupUser(channel),
    [channel, getRequiresUpgradeGroupUser]
  )
  const location = useLocation()
  const navigate = useNavigate()

  const handleOpenChange = (open: boolean) => {
    if (
      !open &&
      location.pathname.includes(
        channelPath({ channelSlug: channel?.slug || "" })
      )
    ) {
      navigate(feedPath.pattern)
    }
    onOpenChange?.(false)
  }

  if (!channel) {
    return null
  }

  return (
    <Dialog onOpenChange={handleOpenChange} {...dialogOptions}>
      <DialogContent>
        {channel.modalHeader && (
          <DialogHeader>
            <DialogTitle className="text-center">
              {channel.modalHeader}
            </DialogTitle>
            {channel.modalSubheader && (
              <Muted className="text-center">{channel.modalSubheader}</Muted>
            )}
          </DialogHeader>
        )}

        <Card>
          <CardContent className="p-4">
            <div className="flex items-center gap-4">
              {channel.imageUrl && (
                <img
                  src={channel.imageUrl}
                  alt=""
                  className="w-48 h-48 object-cover rounded-sm"
                />
              )}
              <div className="flex flex-col gap-4">
                <div className="font-semibold text-lg flex items-center gap-2">
                  {channel.visibility === ChannelVisibilityEnum.Private && (
                    <ChatLocked className="w-6 h-6" />
                  )}
                  {channel.name}
                </div>
                {channel.description && (
                  <div className="text-sm text-foreground">
                    {channel.description}
                  </div>
                )}
                <div>
                  <Button onClick={() => joinChannel(channel.slug)}>
                    {requiresUpgrade ? "Upgrade to Join" : "Join Channel"}
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

gql(`
  fragment Channel_Display on Channel {
    active
    createdAt
    description
    hasCurrentUserJoined
    hasCurrentUserDismissed
    id
    imageId
    imageUrl
    modalHeader
    modalSubheader
    name
    slug
    unreadFeedPostCount
    usedForIntroductions
    activeUsersCount
    totalUsersCount
    visibility

    groups {
      id
      slug
      groupType
    }
  }
`)

export const CHANNELS_QUERY_DOCUMENT = gql(`
  query Channels($searchTerm: String) {
    channels(search: $searchTerm) {
      nodes {
        ...Channel_Display
      }
    }
  }
`)
