import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { channelPath, editPostPath, feedPath } from "~/common/paths"
import { Post_CardFragment } from "~/__generated__/graphql"
import { MoreHorizontal } from "lucide-react"
import { usePostFlagging } from "~/post-composer/usePostFlagging"
import { usePostDestroy } from "~/post-composer/usePostDestroy"
import { useBookmarks } from "~/post-composer/useBookmarks"
import { usePostPinning } from "~/common/postUtils"
import {
  StyledDropdown,
  StyledDropdownLink,
  StyledDropdownItem,
} from "~/ui/StyledDropdown"

import Flag from "../images/icons/flag.svg?react"
import Pin from "../images/icons/pin.svg?react"
import Bookmark from "../images/icons/bookmark.svg?react"
import Download from "../images/icons/download.svg?react"
import Edit from "../images/icons/edit.svg?react"
import Trash from "../images/icons/trash-2.svg?react"
import { useSharePost } from "./useSharePost"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const PostMenu = ({
  post,
  onSinglePostPage,
}: {
  post: Post_CardFragment
  onSinglePostPage: boolean
}) => {
  const { flagPostWithConfirmation } = usePostFlagging({ postId: post.id })
  const { destroyPostWithConfirmation } = usePostDestroy({ postId: post.id })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const currentUser = useCurrentUser()
  const pinPost = usePostPinning()
  const { bookmark, toggleBookmark } = useBookmarks({ post })
  const sharePost = useSharePost()

  return (
    <>
      <StyledDropdown
        size="small"
        trigger={
          <div className="text-gray-500 p-1 hover:bg-gray-100 rounded-2xl card-clickable">
            <MoreHorizontal size={14} />
          </div>
        }
      >
        {post.currentUserCanFlag.value && (
          <StyledDropdownItem
            onClick={async () => {
              const success = await flagPostWithConfirmation()
              if (onSinglePostPage && success)
                navigate(
                  searchParams.get("from") === "channel" && post.channel
                    ? channelPath({ channelSlug: post.channel.slug })
                    : feedPath.pattern
                )
            }}
            title="Flag / Report Post"
            icon={<Flag />}
          />
        )}
        {post.pinnable && post.currentUserCanPin.value && (
          <StyledDropdownItem
            onClick={() => pinPost(post)}
            icon={<Pin className="w-[16px] h-auto relative top-[1px]" />}
            title={`${
              currentUser.pinnedPostId === post.id ? "Unpin" : "Pin"
            } Post`}
          />
        )}
        <StyledDropdownItem
          onClick={toggleBookmark}
          icon={<Bookmark className="w-[16px] h-auto relative top-[1px]" />}
          title={`${bookmark ? "Unbookmark" : "Bookmark"} Post`}
        />
        <StyledDropdownItem
          data-no-navigate-to-post
          onClick={() => sharePost(post)}
          title="Share Post"
          icon={<Download />}
        />
        {post.currentUserCanUpdate.value && (
          <StyledDropdownLink
            linkProps={{
              to: {
                pathname: editPostPath({ postId: post.id }),
                search:
                  searchParams.get("from") === "channel"
                    ? createSearchParams({ from: "channel" }).toString()
                    : "",
              },
            }}
            title="Edit"
            icon={<Edit />}
          />
        )}
        {post.currentUserCanDestroy.value && (
          <StyledDropdownItem
            onClick={async () => {
              const success = await destroyPostWithConfirmation()
              if (onSinglePostPage && success)
                navigate(
                  searchParams.get("from") === "channel" && post.channel
                    ? channelPath({ channelSlug: post.channel.slug })
                    : feedPath.pattern
                )
            }}
            title="Delete"
            icon={<Trash />}
          />
        )}
      </StyledDropdown>
    </>
  )
}
