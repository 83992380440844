import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from "react"

import * as paths from "~/common/paths"

import { useLocation } from "react-router-dom"
import gql from "graphql-tag"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  InputMaybe,
  Scalars,
  AhoyEventTypeEnum,
  EventPageTypeEnum,
} from "../__generated__/graphql"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import invariant from "tiny-invariant"
import useTimer from "easytimer-react-hook"
import { usePageVisibility } from "~/common/usePageVisibility"
import { generateUUID } from "../common/generateUUID"
import { useCommunity } from "~/community/useCommunity"
import { useLocalStorage } from "usehooks-ts"
import { getCustomFingerprint } from "~/common/customFingerprint"
import { useLead } from "~/common/LeadContext"
import { useConfig } from "~/common/ConfigProvider"
import { useCurrentUser } from "~/common/GlobalsProvider"

type LogEventFunction = (
  name: AhoyEventTypeEnum,
  properties?: InputMaybe<Scalars["EventProperties"]["input"]>,
  preventDuplicateRef?: React.MutableRefObject<boolean>
) => Promise<void>

interface DeferredEvent {
  id: string
  name: AhoyEventTypeEnum
  properties?: InputMaybe<Scalars["EventProperties"]["input"]>
  visitId: string
  visitorId: string
  currentPageType: EventPageTypeEnum | null
}

interface EventsContextType {
  logEvent: LogEventFunction
  logEventDeferred: LogEventFunction
  currentPageviewId: string | null
}

enum EventBufferActionType {
  ADD_EVENT = "ADD_EVENT",
  REMOVE_PROCESSED_EVENTS = "REMOVE_PROCESSED_EVENTS",
  START_PROCESSING = "START_PROCESSING",
  PROCESS_COMPLETED = "PROCESS_COMPLETED",
}

type EventBufferAction =
  | { type: EventBufferActionType.ADD_EVENT; payload: DeferredEvent }
  | {
      type: EventBufferActionType.REMOVE_PROCESSED_EVENTS
      payload: DeferredEvent[]
    }
  | { type: EventBufferActionType.START_PROCESSING }
  | { type: EventBufferActionType.PROCESS_COMPLETED }

type EventBufferState = {
  events: DeferredEvent[]
  processing: boolean
}

const initialState: EventBufferState = {
  events: [],
  processing: false,
}

const eventBufferReducer = (
  state: EventBufferState,
  action: EventBufferAction
): EventBufferState => {
  switch (action.type) {
    case EventBufferActionType.ADD_EVENT:
      return { ...state, events: [...state.events, action.payload] }
    case EventBufferActionType.REMOVE_PROCESSED_EVENTS:
      return {
        ...state,
        events: state.events.filter((event) => !action.payload.includes(event)),
      }
    case EventBufferActionType.START_PROCESSING:
      if (state.processing || state.events.length === 0) {
        return state
      }
      return { ...state, processing: true }
    case EventBufferActionType.PROCESS_COMPLETED:
      return { ...state, processing: false }
    default:
      return state
  }
}

const VISIT_THRESHOLD = 1000 * 60 * 30 // 30 minutes

const EventsContext = createContext<EventsContextType | null>(null)

const fpPromise = FingerprintJS.load()

const getDeviceId = async () => {
  const fp = await fpPromise
  const result = await fp.get()

  return result.visitorId
}

const getViewContext = () => {
  const isPWA = window.matchMedia("(display-mode: standalone)").matches
  return isPWA ? "pwa" : "web"
}

const INACTIVITY_THRESHOLD = 60

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const { qaToolsEnabled } = useConfig()
  const { lead } = useLead({ readOnly: true })
  const [state, dispatch] = useReducer(eventBufferReducer, initialState)
  const origin = window.location.origin
  const location = useLocation()
  const { slug, brand } = useCommunity()
  const [currentPageviewId, setCurrentPageviewId] = useState<string | null>(
    null
  )

  const [timer] = useTimer()
  const isVisible = usePageVisibility()
  const [currentPageTime, setCurrentPageTime] = useState(0)
  const currentPageTimeRef = useRef(currentPageTime)
  const lastTrackedPageviewIdRef = useRef<string | undefined>()

  const currentUser = useCurrentUser(false)
  const currentLeadMaybe = currentUser?.lead

  const [currentPageType, setCurrentPageType] =
    useState<EventPageTypeEnum | null>(null)

  const [runLogAnalyticsEvent] = useSafeMutation(LOG_ANALYTICS_EVENT)

  const [visitHeaderValues, setVisitHeaderValues] = useLocalStorage<
    string | null
  >("visitHeaders", null)

  const visitHeaderValuesRef = useRef(visitHeaderValues)

  useEffect(() => {
    currentPageTimeRef.current = currentPageTime
  }, [currentPageTime])

  useEffect(() => {
    if (!timer.isRunning()) {
      timer.start({
        precision: "seconds",
      })
    }
  }, [timer])

  useEffect(() => {
    const secondsElapsed = timer.getTimeValues().seconds

    if (isVisible === null) {
      if (qaToolsEnabled) {
        console.log(
          "[analytics] - Unable to detect browser visibility, aborting activity tracking"
        )
      }
    } else if (!isVisible || secondsElapsed > INACTIVITY_THRESHOLD) {
      // User has likely stopped interacting with the page
      timer.pause()
    } else {
      timer.start()
    }
  }, [isVisible, timer, qaToolsEnabled])

  useEffect(() => {
    setCurrentPageType(paths.getPageType(location.pathname))
    setCurrentPageviewId(generateUUID())
  }, [location.pathname, setCurrentPageviewId, setCurrentPageType])

  const [runBulkLogAnalyticsEvents] = useSafeMutation(BULK_LOG_ANALYTICS_EVENTS)

  const processEvents = useCallback(
    async (eventsToProcess: DeferredEvent[]) => {
      if (eventsToProcess.length === 0) return
      if (qaToolsEnabled) {
        console.log("Processing deferred events:", eventsToProcess)
      }

      const eventsByVisitId = state.events.reduce(
        (acc, event) => {
          const visitId = event.visitId
          if (!acc[visitId]) {
            acc[visitId] = []
          }
          acc[visitId].push(event)
          return acc
        },
        {} as Record<string, typeof state.events>
      )

      try {
        await Promise.all(
          Object.entries(eventsByVisitId).map(async ([visitId, events]) => {
            const visitorId = events[0].visitorId
            await runBulkLogAnalyticsEvents({
              variables: {
                input: {
                  events: events.map((event) => ({
                    name: event.name,
                    pageType: event.currentPageType,
                    properties: event.properties,
                  })),
                },
              },
              context: {
                headers: {
                  "Ahoy-Visit": visitId,
                  "Ahoy-Visitor": visitorId,
                },
              },
            })
          })
        )

        dispatch({
          type: EventBufferActionType.REMOVE_PROCESSED_EVENTS,
          payload: eventsToProcess,
        })
      } catch (error) {
        if (qaToolsEnabled) {
          console.error("An error occurred processing the event buffer:", error)
        }
      } finally {
        dispatch({ type: EventBufferActionType.PROCESS_COMPLETED })
      }
    },
    [runBulkLogAnalyticsEvents, state, qaToolsEnabled]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: EventBufferActionType.START_PROCESSING })
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (state.processing) {
      processEvents([...state.events])
    }
  }, [state.processing])

  const getActivityProperties = useCallback(
    (name: AhoyEventTypeEnum) => {
      const activityProperties = {} as {
        time_on_page: number
        time_since_last_event_in_session: number
      }

      if (isVisible !== null) {
        const activeTime = timer.getTimeValues().seconds
        const sessionTimedOut = activeTime >= INACTIVITY_THRESHOLD
        const activeSecsSinceLastEvent = sessionTimedOut
          ? INACTIVITY_THRESHOLD / 2
          : activeTime

        const newCurrentPageTime =
          name === AhoyEventTypeEnum.PageViewed
            ? 0
            : activeSecsSinceLastEvent + currentPageTimeRef.current

        activityProperties["time_on_page"] = newCurrentPageTime
        activityProperties["time_since_last_event_in_session"] =
          activeSecsSinceLastEvent
      }

      return activityProperties
    },
    [timer, isVisible, currentPageTimeRef]
  )

  const getEventProperties = useCallback(
    async (
      properties: InputMaybe<Scalars["EventProperties"]["input"]>,
      activityProperties: {
        time_on_page: number
        time_since_last_event_in_session: number
      }
    ) => {
      const [deviceId, customFingerprint] = await Promise.all([
        getDeviceId(),
        getCustomFingerprint(),
      ])

      return {
        url: origin + location.pathname + location.search + location.hash,
        device_id: deviceId,
        custom_fingerprint: `${customFingerprint}`,
        pageview_id: currentPageviewId,
        community_name: slug,
        community_brand: brand,
        ...activityProperties,
        ...properties,
      }
    },
    [
      origin,
      location.pathname,
      currentPageviewId,
      slug,
      brand,
      location.search,
      location.hash,
    ]
  )

  const getVisitIds = useCallback(() => {
    const now = Date.now()
    const currentUserIdMaybe = currentUser ? currentUser.id : ""

    const parseVisitHeaderValues = (
      values: string | null
    ): [string | null, string | null, number | null] => {
      if (!values) return [null, null, null]

      const [visitId, visitorId, timestamp] = values.split(":")

      return [visitId, visitorId, Number(timestamp)]
    }

    const visitHeaderValues = parseVisitHeaderValues(
      visitHeaderValuesRef.current
    )
    let visitId = visitHeaderValues[0]
    const visitorId = visitHeaderValues[1]
    let lastVisitTime = visitHeaderValues[2]

    const shouldInitializeNewVisit = () => {
      if (!visitHeaderValuesRef.current) return true

      if (
        currentUserIdMaybe &&
        visitorId &&
        currentUserIdMaybe !== visitorId &&
        visitorId !== currentLeadMaybe?.id
      ) {
        return true
      }

      if (lastVisitTime && now - lastVisitTime > VISIT_THRESHOLD) return true

      return false
    }

    if (shouldInitializeNewVisit()) {
      if (qaToolsEnabled) {
        console.log("[analytics] initializing new visit")
      }

      visitId = generateUUID()
      lastVisitTime = now
    }

    const updatedVisitHeaderValues = `${visitId}:${
      currentUserIdMaybe || lead?.id
    }:${now}`

    setVisitHeaderValues(updatedVisitHeaderValues)
    visitHeaderValuesRef.current = updatedVisitHeaderValues

    return [visitId, currentUserIdMaybe || lead?.id]
  }, [
    currentUser,
    setVisitHeaderValues,
    currentLeadMaybe,
    lead,
    qaToolsEnabled,
  ])

  const logEvent: LogEventFunction = useCallback(
    async (
      name: AhoyEventTypeEnum,
      properties?: InputMaybe<Scalars["EventProperties"]["input"]>,
      preventDuplicateRef?: React.MutableRefObject<boolean>
    ) => {
      if (preventDuplicateRef) {
        if (preventDuplicateRef.current) {
          return
        }
        preventDuplicateRef.current = true
      }

      const activityProperties = getActivityProperties(name)
      const eventProperties = await getEventProperties(
        activityProperties,
        properties
      )
      const [visitId, visitorId] = getVisitIds()
      const currentUserIdMaybe = currentUser ? currentUser.id : ""

      if (qaToolsEnabled) {
        console.log(
          "[analytics]\n - visit_id: %s\n - visitor_id: %s\n - event_name: %s\n - lead_event: %s\n - page_type: %s",
          visitId,
          visitorId,
          name,
          !!(!currentUserIdMaybe && lead?.id),
          currentPageType,
          eventProperties
        )
      }

      runLogAnalyticsEvent({
        variables: {
          input: {
            name,
            pageType: currentPageType,
            properties: { ...eventProperties, lead_id: lead?.id },
          },
        },
        context: {
          headers: {
            "Ahoy-Visit": visitId,
            "Ahoy-Visitor": visitorId,
          },
        },
      })
      setCurrentPageTime(activityProperties["time_on_page"])
      timer.reset()
    },
    [
      currentPageType,
      currentUser,
      getActivityProperties,
      getEventProperties,
      getVisitIds,
      lead,
      runLogAnalyticsEvent,
      setCurrentPageTime,
      timer,
      qaToolsEnabled,
    ]
  )

  const logEventDeferred: LogEventFunction = useCallback(
    async (
      name: AhoyEventTypeEnum,
      properties?: InputMaybe<Scalars["EventProperties"]["input"]>,
      preventDuplicateRef?: React.MutableRefObject<boolean>
    ) => {
      if (preventDuplicateRef) {
        if (preventDuplicateRef.current) {
          return
        }
        preventDuplicateRef.current = true
      }

      const activityProperties = getActivityProperties(name)
      const eventProperties = await getEventProperties(
        activityProperties,
        properties
      )
      const [visitId, visitorId] = getVisitIds()

      if (!visitId || !visitorId) {
        return
      }

      dispatch({
        type: EventBufferActionType.ADD_EVENT,
        payload: {
          name,
          properties: eventProperties,
          visitId,
          visitorId,
          currentPageType,
        } as DeferredEvent,
      })

      setCurrentPageTime(activityProperties["time_on_page"])
      timer.reset()
    },
    [
      getActivityProperties,
      getEventProperties,
      getVisitIds,
      timer,
      setCurrentPageTime,
      currentPageType,
    ]
  )

  useEffect(() => {
    if (
      currentPageviewId &&
      currentPageviewId !== lastTrackedPageviewIdRef.current
    ) {
      lastTrackedPageviewIdRef.current = currentPageviewId
      logEvent(AhoyEventTypeEnum.PageViewed, {
        view_context: getViewContext(),
      })
    }
  }, [currentPageviewId, logEvent])

  const value = useMemo(() => {
    return {
      logEvent,
      logEventDeferred,
      currentPageviewId,
    }
  }, [logEvent, logEventDeferred, currentPageviewId])

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  )
}

export const useLogEvent = () => {
  const contextValue = useContext(EventsContext)

  invariant(contextValue, "Context has not been Provided!")

  return useMemo(
    () => ({
      logEvent: contextValue.logEvent,
      logEventDeferred: contextValue.logEventDeferred,
      currentPageviewId: contextValue.currentPageviewId,
    }),
    [contextValue]
  )
}

export const LOG_ANALYTICS_EVENT = gql`
  mutation logAnalyticsEvent($input: LogEventInput!) {
    logEvent(input: $input) {
      event {
        name
      }
    }
  }
`

export const BULK_LOG_ANALYTICS_EVENTS = gql`
  mutation bulkLogAnalyticsEvents($input: BulkLogEventsInput!) {
    bulkLogEvents(input: $input) {
      events {
        name
      }
    }
  }
`
