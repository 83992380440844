import { Post_CardFragment, AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { PostCard } from "./PostCard"
import { gql } from "~/__generated__"
import { LIVE_ARTICLE_QUERY_DOCUMENT } from "~/screens/ArticleScreen"
import { ArticleCard } from "~/ui/ArticleCard"
import { X } from "lucide-react"
import { useLogEvent } from "~/analytics/EventsContext"
import { Card, CardContent } from "~/ui/card"
import { useConfig } from "~/common/ConfigProvider"
import { forwardRef, useMemo, useRef } from "react"
import { useElementDimensions } from "~/common/useElementDimensions"

export const DetectInternalContentShareSection = ({
  post,
  trackImpressions = false,
}: {
  post: Post_CardFragment
  trackImpressions?: boolean
}) => {
  const { domain } = useConfig()
  const contentRegex = useMemo(
    () =>
      new RegExp(
        `${domain}/(posts|library)/([a-zA-Z0-9]{8}(?:-[a-zA-Z0-9]{4}){3}-[a-zA-Z0-9]{12})`
      ),
    [domain]
  )
  if (!post.content) return null

  const match = post.content.match(contentRegex)
  if (!match || !(match[1] && match[2])) return null

  if (match[1] === "library") {
    return (
      <RenderEmbeddedArticle
        articleId={match[2]}
        parentPost={post}
        trackImpressions={trackImpressions}
      />
    )
  }

  if (match[1] === "posts") {
    return (
      <RenderEmbeddedPost
        postId={match[2]}
        parentPost={post}
        trackImpressions={trackImpressions}
      />
    )
  }
}

const EmbeddedContent = forwardRef<
  HTMLDivElement,
  {
    content: React.ReactNode
    onClose?: () => void
    handleOnClick?: (e: React.MouseEvent) => void
  }
>(({ content, onClose, handleOnClick }, ref) => {
  return (
    <div
      ref={ref}
      className="mb-2 card-clickable"
      onClick={(e: React.MouseEvent) => {
        handleOnClick?.(e)
      }}
    >
      {onClose && (
        <div
          className="absolute right-0 mr-2 mt-2 card-clickable cursor-pointer"
          onClick={onClose}
          aria-label="Remove embedded content"
        >
          <X size={16} />
        </div>
      )}
      {content}
    </div>
  )
})

export const RenderEmbeddedPost = ({
  postId,
  onClose,
  parentPost,
  trackImpressions = true,
}: {
  postId: string
  onClose?: () => void
  trackImpressions?: boolean
  parentPost?: Post_CardFragment
}) => {
  const { data: postData } = useQuery(POST_QUERY_DOCUMENT, {
    variables: { postId },
  })

  const { logEvent } = useLogEvent()
  const renderedInClosableContext = onClose !== undefined

  if (!postData) return null

  return (
    <EmbeddedContent
      content={
        <PostCard
          post={postData.post}
          hidePostMenu
          hidePostActions
          cardClickable
          embedVersion
          trackImpressions={!renderedInClosableContext && trackImpressions}
        />
      }
      handleOnClick={(e: React.MouseEvent) => {
        if (e.target instanceof HTMLAnchorElement && parentPost) {
          logEvent(AhoyEventTypeEnum.EmbeddedLinkClicked, {
            url_clicked: e.target.href,
            location: parentPost.isReply ? "reply" : "post",
          })
        }
      }}
      onClose={onClose}
    />
  )
}

export const RenderEmbeddedArticle = ({
  articleId,
  onClose,
  parentPost,
  trackImpressions = true,
}: {
  articleId: string
  onClose?: () => void
  parentPost?: Post_CardFragment
  context?: string
  trackImpressions?: boolean
}) => {
  const { logEvent } = useLogEvent()
  const renderedInClosableContext = onClose !== undefined

  const { data: articleData } = useQuery(LIVE_ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })
  const embeddedContentRef = useRef<HTMLDivElement>(null)
  const dimensions = useElementDimensions(embeddedContentRef)

  if (!articleData?.article || !articleData.article.revision) {
    return (
      <Card>
        <CardContent className="pt-4">
          <div className="text-center text-gray-600">
            This content has been deleted.
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <EmbeddedContent
      ref={embeddedContentRef}
      content={
        <ArticleCard
          article={articleData.article}
          embedded
          trackImpressions={!renderedInClosableContext && trackImpressions}
          variant={dimensions && dimensions.width < 400 ? "default" : "hero"}
          state="default"
          className="sm:w-full"
        />
      }
      onClose={onClose}
      handleOnClick={(e: React.MouseEvent) => {
        if (e.target instanceof HTMLAnchorElement && parentPost) {
          logEvent(AhoyEventTypeEnum.EmbeddedLinkClicked, {
            url_clicked: e.target.href,
            location: parentPost.isReply ? "reply" : "post",
          })
        }
      }}
    />
  )
}

const POST_QUERY_DOCUMENT = gql(`
  query PostRetweetPost($postId: ID!) {
    post(postId: $postId) {
      ...Post_Card
    }
  }
`)
