import Select from "react-select"
import clsx from "clsx"
import { gql } from "~/__generated__"
import { useChannels } from "~/common/ChannelsProvider"
import { useMemo } from "react"
import { Channel, ChannelVisibilityEnum } from "~/__generated__/graphql"

export const ChannelSelector = ({
  channelId,
  setChannelId,
  disabled = false,
  post,
}: {
  channelId: string | null
  setChannelId: (channelId: string | null) => void
  disabled?: boolean
  post?: { channel?: Pick<Channel, "id" | "visibility"> | null }
}) => {
  const { channels } = useChannels()
  const joinedChannels = useMemo(
    () => channels.filter((c) => c.hasCurrentUserJoined),
    [channels]
  )

  const options = useMemo(
    () =>
      joinedChannels
        .filter((c) => {
          if (!post) return true
          if (!post.channel)
            return c.visibility === ChannelVisibilityEnum.Public
          if (c.id === post.channel.id) return true
          if (post.channel.visibility === ChannelVisibilityEnum.Public)
            return c.visibility === ChannelVisibilityEnum.Public
          return false
        })
        .map((c) => ({ value: c.id, label: c.name })),
    [joinedChannels, post]
  )
  const selectedOption = useMemo(
    () => options.find((option) => option.value === channelId) || null,
    [options, channelId]
  )

  const canDeselect = post?.channel?.visibility === ChannelVisibilityEnum.Public

  if (!joinedChannels.length) return null

  return (
    <div
      className={clsx(
        "text-xs font-medium items-center justify-between px-6 tracking-wide border-y border-mercury",
        { "cursor-not-allowed": disabled }
      )}
    >
      <Select
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        isDisabled={disabled || !options.length || options.length === 1}
        isClearable={canDeselect}
        isSearchable
        name="channel"
        placeholder="Select channel..."
        options={options}
        value={selectedOption}
        onChange={(channel) => {
          setChannelId(channel?.value || null)
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: 0,
            outline: "none !important",
            boxShadow: "none",
            cursor: "pointer !important",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            fontSize: 14,
            fontWeight: 500,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100,
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            cursor: "pointer !important",
          }),
        }}
      />
    </div>
  )
}

gql(`
  fragment Selector_Channel on Channel {
    id
    name
    slug
    channelOldestUnreadPostTime
  }
`)

gql(`
  fragment ChannelConnection_Composer on ChannelConnection {
    nodes {
      ...Selector_Channel
    }
  }
`)
