import { useEffect } from "react"
import toast from "react-hot-toast"
import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { signInPath } from "~/common/paths"

export const RequireUserSignedIn = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser(false)

  useEffect(() => {
    if (!currentUser) {
      toast.error("Please sign in to continue.")
      navigate(signInPath.pattern)
    }
  }, [currentUser, navigate])

  if (!currentUser) {
    return null
  }

  return <Outlet />
}
