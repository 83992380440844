import HTMLReactParser from "html-react-parser"
import { RenderFn } from "editorjs-blocks-react-renderer"
import clsx from "clsx"

export interface WarningBlockData {
  title: string
  message: string
}

export const WarningRenderer: RenderFn<WarningBlockData> = ({
  data,
  className = "",
}) => {
  const props: {
    [s: string]: string
  } = {}

  props.className = clsx(
    "flex bg-[hsl(var(--callout-background))] rounded px-4 py-3",
    className
  )

  return (
    <div {...props}>
      <div className="text-2xl mr-4">{data?.title || "💡"}</div>
      <div className="my-[4px]">
        {data?.message && HTMLReactParser(data.message)}
      </div>
    </div>
  )
}
