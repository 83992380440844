import { cva, VariantProps } from "class-variance-authority"
import React from "react"
import { cn } from "~/lib/utils"

export const typographyVariants = cva("text-foreground", {
  variants: {
    variant: {
      default: "",
      h1: "font-serif text-5xl font-medium tracking-tight",
      h2: "font-sans text-4xl font-medium tracking-tight",
      h3: "font-serif text-3xl font-medium tracking-tight",
      h4: "font-sans text-2xl font-medium tracking-tight",
      h5: "font-serif text-1.5xl font-normal leading-normal tracking-tight",
      h6: "font-sans text-lg font-medium",
      h7: "font-sans text-base font-medium",
      h8: "font-sans text-sm font-medium",
      h9: "font-sans text-xs font-semibold leading-tighter tracking-wide",
      body: "",
      small: "text-sm font-medium leading-none",
      code: "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
      blockquote: "border-l-2 pl-6 italic",
      p: "leading-7",
      ul: "ml-6 list-disc [&>li]:mt-2",
      ol: "ml-6 list-decimal [&>li]:mt-2",
      li: "",
      lead: "text-xl text-muted-foreground",
      muted: "text-muted-foreground text-2xs",
      strong: "font-semibold",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

interface TypographyProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof typographyVariants> {}
interface HeadingProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof typographyVariants> {}
interface ParagraphProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof typographyVariants> {}
interface UnorderedListProps
  extends React.HTMLAttributes<HTMLUListElement>,
    VariantProps<typeof typographyVariants> {}
interface OrderedListProps
  extends React.HTMLAttributes<HTMLOListElement>,
    VariantProps<typeof typographyVariants> {}
interface ListItemProps
  extends React.HTMLAttributes<HTMLLIElement>,
    VariantProps<typeof typographyVariants> {}
interface BlockquoteProps
  extends React.HTMLAttributes<HTMLQuoteElement>,
    VariantProps<typeof typographyVariants> {}
interface CodeProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {}
interface SmallProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {}
interface LeadProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {}
interface MutedProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {}

interface StrongProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {}

export const Typography = React.forwardRef<HTMLDivElement, TypographyProps>(
  ({ className, variant, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    >
      {children}
    </div>
  )
)

export const H1 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h1", ...props }, ref) => (
    <h1
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H2 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h2", ...props }, ref) => (
    <h2
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H3 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h3", ...props }, ref) => (
    <h3
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H4 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h4", ...props }, ref) => (
    <h4
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H5 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h5", ...props }, ref) => (
    <h5
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H6 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h6", ...props }, ref) => (
    <h6
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H7 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h7", ...props }, ref) => (
    <h6
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H8 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h8", ...props }, ref) => (
    <h6
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const H9 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant = "h9", ...props }, ref) => (
    <h6
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const P = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, variant = "p", ...props }, ref) => (
    <p
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Ul = React.forwardRef<HTMLUListElement, UnorderedListProps>(
  ({ className, variant = "ul", ...props }, ref) => (
    <ul
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Ol = React.forwardRef<HTMLOListElement, OrderedListProps>(
  ({ className, variant = "ol", ...props }, ref) => (
    <ol
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Li = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, variant = "li", ...props }, ref) => (
    <li
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Blockquote = React.forwardRef<HTMLQuoteElement, BlockquoteProps>(
  ({ className, variant = "blockquote", ...props }, ref) => (
    <blockquote
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Code = React.forwardRef<HTMLElement, CodeProps>(
  ({ className, variant = "code", ...props }, ref) => (
    <code
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Small = React.forwardRef<HTMLElement, SmallProps>(
  ({ className, variant = "small", ...props }, ref) => (
    <small
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Lead = React.forwardRef<HTMLParagraphElement, LeadProps>(
  ({ className, variant = "lead", ...props }, ref) => (
    <p
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Muted = React.forwardRef<HTMLParagraphElement, MutedProps>(
  ({ className, variant = "muted", ...props }, ref) => (
    <p
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

export const Strong = React.forwardRef<HTMLElement, StrongProps>(
  ({ className, variant = "strong", ...props }, ref) => (
    <strong
      ref={ref}
      className={cn(typographyVariants({ variant }), className)}
      {...props}
    />
  )
)

Typography.displayName = "Typography"
H1.displayName = "H1"
H2.displayName = "H2"
H3.displayName = "H3"
H4.displayName = "H4"
H5.displayName = "H5"
H6.displayName = "H6"
P.displayName = "P"
Ul.displayName = "Ul"
Ol.displayName = "Ol"
Li.displayName = "Li"
Blockquote.displayName = "Blockquote"
Code.displayName = "Code"
Small.displayName = "Small"
Lead.displayName = "Lead"
Muted.displayName = "Muted"
Strong.displayName = "Strong"
