import { useCallback, useMemo } from "react"
import { gql } from "~/__generated__"
import {
  Post_CardFragment,
  Article_CardFragment,
  Article_LiveContentFragment,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { useLogEvent } from "~/analytics/EventsContext"

export const useBookmarks = ({
  article,
  post,
}: {
  article?: Article_CardFragment | Article_LiveContentFragment
  post?: Post_CardFragment
}) => {
  const { logEvent } = useLogEvent()
  const bookmark = useMemo(
    () => post?.currentUserBookmark || article?.currentUserBookmark,
    [post, article]
  )
  const [runBookmarkCreate] = useSafeMutation(BOOKMARK_CREATE_MUTATION)
  const [runBookmarkDestroy] = useSafeMutation(BOOKMARK_DESTROY_MUTATION)

  const addBookmark = useCallback(
    async ({ onSuccess }: { onSuccess?: () => void } = {}) => {
      const { errors } = await runBookmarkCreate({
        variables: {
          input: {
            articleId: article?.id,
            postId: post?.id,
          },
        },
      })

      if (errors) {
        displayErrors(errors)
        console.log(errors)
      } else {
        if (post?.id) {
          logEvent(AhoyEventTypeEnum.PostBookmarked, {
            post_id: post.id,
            channel_name: post.channel?.name || "feed",
            channel_id: post.channel?.id,
          })
        } else if (article?.id) {
          logEvent(AhoyEventTypeEnum.ArticleBookmarked, {
            article_id: article.id,
          })
        }
        onSuccess?.()
      }
    },
    [post, article, runBookmarkCreate, logEvent]
  )

  const removeBookmark = useCallback(async () => {
    if (!bookmark) {
      return
    }

    const { errors } = await runBookmarkDestroy({
      variables: {
        input: {
          bookmarkId: bookmark.id,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      if (post?.id) {
        logEvent(AhoyEventTypeEnum.PostUnbookmarked, {
          post_id: post.id,
          channel_name: post.channel?.name || "feed",
          channel_id: post.channel?.id,
        })
      } else if (article?.id) {
        logEvent(AhoyEventTypeEnum.ArticleUnbookmarked, {
          article_id: article.id,
        })
      }
    }
  }, [bookmark, runBookmarkDestroy, logEvent, post, article])

  const toggleBookmark = useCallback(async () => {
    const bookmarkExists = !!bookmark
    if (bookmarkExists) {
      await removeBookmark()
    } else {
      await addBookmark()
    }
    return !bookmarkExists
  }, [bookmark, addBookmark, removeBookmark])

  return { bookmark, addBookmark, removeBookmark, toggleBookmark }
}

gql(`
  fragment Bookmark on Bookmark {
    id
    createdAt
    isDestroyed
  }
`)

const BOOKMARK_CREATE_MUTATION = gql(`
  mutation BookmarkCreate($input: BookmarkCreateInput!) {
    bookmarkCreate(input: $input) {
      bookmark {
        ...Bookmark
        article {
          ...Article_Card
        }
        post {
          ...Post_Card
        }
      }
    }
  }
`)

const BOOKMARK_DESTROY_MUTATION = gql(`
  mutation BookmarkDestroy($input: BookmarkDestroyInput!) {
    bookmarkDestroy(input: $input) {
      bookmark {
        ...Bookmark
        article {
          ...Article_Card
        }
        post {
          ...Post_Card
        }
      }
    }
  }
`)
