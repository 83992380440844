import { ReactNode } from "react"
import { Switch } from "./switch"

export type ButtonToggleGroupProps = {
  leftButtonCopy: ReactNode
  leftButtonOnClick: () => void
  rightButtonCopy: ReactNode
  rightButtonOnClick: () => void
  activeButton: "left" | "right"
}

/**
 * DEPRECATED: Use the Switch component with `variant="selector"` instead.
 * @deprecated
 */
export const ButtonGroupToggle = ({
  leftButtonCopy,
  leftButtonOnClick,
  rightButtonCopy,
  rightButtonOnClick,
  activeButton,
}: ButtonToggleGroupProps) => {
  return (
    <Switch
      variant="selector"
      checked={activeButton === "right"}
      onCheckedChange={(val) => {
        if (val) {
          rightButtonOnClick()
        } else {
          leftButtonOnClick()
        }
      }}
      options={[leftButtonCopy, rightButtonCopy]}
    />
  )
}
