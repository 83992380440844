import { Namespace, ParseKeys, TOptions, TypeOptions } from "i18next"
import { CircleCheck, Plus } from "lucide-react"
import { useMemo } from "react"
import {
  Trans,
  TransProps,
  useTranslation as useTranslationI18next,
  UseTranslationResponse,
} from "react-i18next"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { CommunityName } from "~/community/CommunityName"
import { CommunityUrl } from "~/community/CommunityUrl"
import { CreatorSignature } from "~/community/CreatorSignature"
import { useCommunity } from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { Alert } from "~/ui/alert"
import { Muted, Strong } from "~/ui/typography"

type _DefaultNamespace = TypeOptions["defaultNS"]

const useComponents = () => {
  const community = useCommunity()
  return {
    Alert: <Alert />,
    AlertHighlight: <Alert variant="highlight" />,
    ListImageCheckmark: (
      <CircleCheck
        className={cn(
          "w-5 h-5 fill-highlight text-white",
          community.slug === CommunitySlug.Gotomillions && "fill-primary"
        )}
      />
    ),
    ListImagePlus: (
      <Plus
        className={cn(
          "w-4 h-4 text-highlight",
          community.slug === CommunitySlug.Gotomillions && "text-primary",
          community.slug === CommunitySlug.Fintechtakes && "text-primary"
        )}
      />
    ),
    ListItem: <li className="flex items-center gap-2" />,
    CommunityName: <CommunityName />,
    CommunityUrl: <CommunityUrl />,
    CreatorSignature: <CreatorSignature />,
    Muted: <Muted />,
    Strong: <Strong />,
    h5: <h5 className="text-sm font-semibold" />,
    li: <li />,
    ul: <ul className="flex flex-col gap-1" />,
    ol: <ol className="flex flex-col gap-1 list-decimal list-inside" />,
    CenteredText: <div className="text-center" />,
  }
}

export function Translation<
  Key extends ParseKeys<Ns, TOpt, KPrefix>,
  Ns extends Namespace = _DefaultNamespace,
  KPrefix = undefined,
  TContext extends string | undefined = undefined,
  TOpt extends TOptions & { context?: TContext } = { context: TContext },
  E = React.HTMLProps<HTMLDivElement>,
>({
  values: valuesFromProps,
  components: componentsFromProps,
  ...props
}: TransProps<Key, Ns, KPrefix, TContext, TOpt, E>) {
  const currentUser = useCurrentUser(false)
  const community = useCommunity()

  const values = useMemo(() => {
    if (!currentUser) {
      return valuesFromProps || {}
    }

    return {
      ...currentUser,
      communityName: community.name,
      ...valuesFromProps,
    }
  }, [currentUser, valuesFromProps, community])

  const defaultComponents = useComponents()

  const components = useMemo(() => {
    return {
      ...defaultComponents,
      ...(componentsFromProps || {}),
    }
  }, [componentsFromProps, defaultComponents])

  return <Trans values={values} components={components} {...props} />
}

export const useTranslation = (ns?: Namespace) => {
  const translation = useTranslationI18next(ns)
  const community = useCommunity()
  const currentUser = useCurrentUser(false)

  return {
    ...translation,
    t: (key: string, options?: TOptions) => {
      return translation.t(key, {
        ...options,
        community_name: community.name,
        currentUser: {
          ...(currentUser || {}),
        },
      })
    },
  } as UseTranslationResponse<any, undefined>
}
