import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CREATE_MERGE_LINK_MUTATION = gql(`
  mutation CreateMergeLink($input: CreateMergeLinkInput!) {
    createMergeLink(input: $input) {
      mergeLink {
        identifier
      }

      currentUser: user {
        ...User_CurrentUser
      }
    }
  }
`)
