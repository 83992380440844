/**
 * Please keep this file neat and tidy. It's a bit of a beast, so keeping things consistent is going to make everyone's
 * lives easier. Keep the keys in alphabetical order. Use the `useTranslation` and `<Translation>` element from
 * `~/common/Translation`.
 */

import i18n from "i18next"
import {
  ArticleContentTypeEnum,
  CelebrationTypeEnum,
  CommunitySlug,
  IndustryEnum,
  JobFunctionEnum,
  RulesEngineConditionOperatorEnum,
  ScreenEnum,
  UserResponsibilityQuestionsEnum,
  VerticalEnum,
} from "~/__generated__/graphql"

type CommunityTranslations = {
  addToHomescreenModal: {
    title: string
    body: string
    bookmark_instructions: string
    close: string
  }
  applicationLandingScreen: {
    title: string
    subtitle: string
    emailAddressLabel: string
    madlibs: string
    responsibilityQuestionsLabel: string
    submitCopy: string
    disclaimer: string
    bannerCopy: string
  }
  bot: {
    headerTitle: string
    headerSubtitle: string
    iframeTitle: string
    name: string
    rechargeTitle: string
    rechargeSubtitle: string
    rechargeMessage: string
    rechargeCta: string
  }
  celebrations: {
    [key in CelebrationTypeEnum]: {
      label: string
      emoji: string
    }
  }
  community: {
    creatorFirstName: string
    directoryLabel: string
    libraryLabel: string
  }
  contentTypes: {
    [key in ArticleContentTypeEnum]: string
  }
  ctaChecklist: {
    introduction_template: string
    reviewCommunityValues: string
    enhanceYourProfile: string
    introduceYourself: string
    downloadOurApp: string
    optIntoIntroductions: string
    unlockWelcomeGift: string
  }
  industries: {
    [key in IndustryEnum]: string
  }
  intentStep: {
    title: string
    questions: string
  }
  job_functions: {
    [key in JobFunctionEnum]: string
  }
  libraryScreen: {
    title: string
    description: string
    communityArticlesTitle: string
    featuredArticlesTitle: string
    libraryArticlesTitle: string
    newlyAddedArticlesTitle: string
    trendingArticlesTitle: string
    searchInputPlaceholder: string
  }
  messages: {
    matchedCount: string
    matchedCount_plural: string
    matchedCount_zero: string
  }
  offers: {
    plusUpsell: {
      features: string
      title: string
      body: string
      thankYou: string
      moduleTitle: string
    }
    countdownModule: {
      cta: string
    }
  }
  onboarding: {
    activateScreenTitle: string
    applicationsPausedModal: {
      title: string
      body: string
      confirmText: string
    }
    homeBaseLabel: string
    bioScreenTitle: string
    bioFieldLabel: string
    bioFieldPlaceholder: string
    interestsScreenTitle: string
    expertiseScreenTitle: string
    introductionsScreen: {
      title: string
      subtitle: string
      introName: string
      introFirstName: string
      introLastName: string
      introCompany: string
      introBio: string
      introLocation: string
      introTitle: string
      introInterests: string[]
      introExpertise: string[]
    }
    experienceScreen: {
      companySize: string
      industry: string
      jobFunction: string
      linkedinProfileUrl: string
      oops: string
      oops_plural: string
      title: string
      vertical: string
      yearsOfExperience: string
    }
    videoIntro: string
  }
  pricingTableStep: {
    title: string
    subtitle: string
    newMemberTitle: string
    recommendedTierHeader: string
    discountSticker: string
    plusTitle: string
    plusSubtitle: string
    proTitle: string
    proSubtitle: string
    freeTierFeatures: string
    plusTierFeatures: string
    proTierFeatures: string
  }
  referral: {
    ctaText: string
    description: string
  }
  responsibilityQuestions: {
    [key in UserResponsibilityQuestionsEnum]: string
  }
  rulesEngine: {
    operators: {
      [key in RulesEngineConditionOperatorEnum]: string
    }
  }
  screens: {
    [key in ScreenEnum]: string
  }
  signin: {
    signinTitle: string
    signinSubtitle: string
  }
  signup: {
    confirmation: string
    consent: string
    submit: string
    title: string
  }
  socialContractStep: {
    title: string
    body: string
    disclaimer: string
    confirmText: string
    values: string
  }
  time: {
    dayWithCount: string
    dayWithCount_plural: string
    hourWithCount: string
    hourWithCount_plural: string
    minuteWithCount: string
    minuteWithCount_plural: string
  }
  verticals: {
    [key in VerticalEnum]: string
  }
  unfitScreen: {
    title: string
    body: string
  }
  fitScreen: {
    title: string
    subtitle: string
    reviewTime: string
  }
  introductionsModule: {
    description: string
    ctaButtonText: string
  }
  newInLibraryModule: {
    title: string
  }
  directoryMap: {
    title: string
  }
  upload: {
    description: string
  }
  fileUploader: {
    title: string
    subtitle: string
  }
}

const DEFAULT_TRANSLATIONS: CommunityTranslations = {
  addToHomescreenModal: {
    title: "Save the app to homescreen",
    body: `
      <p>It's a great morning when I can hop into <CommunityName /> before work Slack...straight from my phone...on my morning walk. You too can add <CommunityName /> to your home screen to keep your mobile access easy peasy.</p>
      <ol>
        <li>Go to <CommunityUrl /> from your mobile browser</li>
        <li>Click on the share icon</li>
        <li>Scroll down and "Add to Homepage"</li>
        <li>Rename the app to "<CommunityName />"</li>
      </ol>
      <p>Boom! It will show up on your homescreen and you can set it right next to your (other) favorite apps 👀</p>
    `
      .replace(/\s+/g, " ")
      .trim(),
    bookmark_instructions: `
      <strong>Won't remember these instructions?</strong><br/>
      <Muted>No Problem. Click the bookmark button and we'll save it for you there.</Muted>
    `
      .replace(/\s+/g, " ")
      .trim(),
    close: "Close",
  },
  applicationLandingScreen: {
    title: "Default Application Landing Title",
    subtitle: "Default Application Landing Subtitle",
    emailAddressLabel: "Email Address",
    madlibs: `
      I have worked in my field for <YearsOfExperienceInput /> years, primarily specializing in <JobFunctionSelect />.<br/>
      Now I'm the <JobTitleInput /><br/> where I <RoleInput /> of an HR team of <TeamSizeInput />.
    `
      .replace(/\s+/g, " ")
      .trim(),
    responsibilityQuestionsLabel:
      "A few things I have been or am currently responsible for are:",
    submitCopy: "Submit & Continue",
    disclaimer: `
      By clicking “Submit & Continue” I recognize <CommunityName /> is a
      place for building authentic relationships, sharing knowledge
      and advice, and supporting other members. As a member
      of <CommunityName />, l agree to contribute positively to the
      community and will not sell or promote products or services
      unless explicitly asked.
    `
      .replace(/\s+/g, " ")
      .trim(),
    bannerCopy: "",
  },
  bot: {
    headerTitle: "Reply to Recharge",
    headerSubtitle:
      "$t(name) is powered by your engagement with peers. Reply to peers' posts to keep the battery charged!",
    iframeTitle: "Chat with $t(name)",
    name: "Community Bot",
    rechargeTitle: "Low Battery",
    rechargeSubtitle: "Reply to recharge",
    rechargeMessage:
      "$t(name) is fueled by your engagement with peers in the community ✨",
    rechargeCta: "Go to Feed",
  },
  celebrations: {
    [CelebrationTypeEnum.Birthday]: {
      label: "Birthday",
      emoji: "🎂",
    },
    [CelebrationTypeEnum.WorkAnniversary]: {
      label: "Work Anniversary",
      emoji: "📆",
    },
    [CelebrationTypeEnum.ToxicWorkplaceBreakup]: {
      label: "Toxic Workplace Breakup Anniversary",
      emoji: "🍾",
    },
    [CelebrationTypeEnum.Other]: {
      label: "Other",
      emoji: "✨",
    },
  },
  community: {
    creatorFirstName: "[CREATOR]",
    directoryLabel: "Directory",
    libraryLabel: "Library",
  },
  contentTypes: {
    [ArticleContentTypeEnum.AlexsTheories]: "Alex's Theories",
    [ArticleContentTypeEnum.BankNerdCorner]: "Bank Nerd Corner",
    [ArticleContentTypeEnum.BrandHighlights]: "Brand Highlights",
    [ArticleContentTypeEnum.Checklist]: "Checklist",
    [ArticleContentTypeEnum.CoachsCorner]: "Coach's Corner",
    [ArticleContentTypeEnum.Collection]: "Collection",
    [ArticleContentTypeEnum.CommunityJam]: "Community Jam",
    [ArticleContentTypeEnum.CommunityQa]: "Community Q&A",
    [ArticleContentTypeEnum.Course]: "Course",
    [ArticleContentTypeEnum.CreativeInspo]: "Creative Inspo",
    [ArticleContentTypeEnum.DanielsThoughts]: "Daniel's Thoughts",
    [ArticleContentTypeEnum.DeepDive]: "Deep Dive",
    [ArticleContentTypeEnum.EarningsAnalysis]: "Earnings Analysis",
    [ArticleContentTypeEnum.Essay]: "Essay",
    [ArticleContentTypeEnum.ExpertPanel]: "Expert Panel",
    [ArticleContentTypeEnum.FinancialAnalysis]: "Financial Analysis",
    [ArticleContentTypeEnum.FintechOfficeHours]: "Fintech Office Hours",
    [ArticleContentTypeEnum.FintechRecap]: "Fintech Recap",
    [ArticleContentTypeEnum.FiresideChat]: "Fireside Chat",
    [ArticleContentTypeEnum.FunnelHacking]: "Funnel Hacking",
    [ArticleContentTypeEnum.Guide]: "Guide",
    [ArticleContentTypeEnum.HrTherapy]: "HR Therapy",
    [ArticleContentTypeEnum.Interview]: "Interview",
    [ArticleContentTypeEnum.Lesson]: "Lesson",
    [ArticleContentTypeEnum.MarketerInterview]: "Marketer Interview",
    [ArticleContentTypeEnum.MarketingCaseStudy]: "Marketing Case Study",
    [ArticleContentTypeEnum.MarketinglandFestival]: "Marketingland Festival",
    [ArticleContentTypeEnum.Mastermind]: "Mastermind",
    [ArticleContentTypeEnum.MustKnows]: "Must Knows",
    [ArticleContentTypeEnum.NewsRoundup]: "News Roundup",
    [ArticleContentTypeEnum.Note]: "Note",
    [ArticleContentTypeEnum.NotFintechInvestmentAdvice]:
      "Not Fintech Investment Advice",
    [ArticleContentTypeEnum.Playbook]: "Playbook",
    [ArticleContentTypeEnum.ProductBreakdown]: "Product Breakdown",
    [ArticleContentTypeEnum.Research]: "Research",
    [ArticleContentTypeEnum.Resource]: "Resource",
    [ArticleContentTypeEnum.Roundtable]: "Roundtable",
    [ArticleContentTypeEnum.SwipeFile]: "Swipe File",
    [ArticleContentTypeEnum.Template]: "Template",
    [ArticleContentTypeEnum.TrendAnalysis]: "Trend Analysis",
    [ArticleContentTypeEnum.Unknown]: "<strong>!!!UNKNOWN!!!</strong>",
    [ArticleContentTypeEnum.VendorDeepDives]: "Vendor Deep Dives",
  },
  ctaChecklist: {
    introduction_template: `
      Hi, I'm {{currentUser.firstName}}

      I've been in HR for {{currentUser.yearsOfExperience}} years primarily specializing in {{currentUser.jobFunction}}.

      Today, I'm the {{currentUser.jobTitle}} where I {{currentUser.manageContribute}} to an HR team of {{currentUser.teamSize}}.

      When I'm not at work, you can find me...

      My HR hot take is...

      Excited to be here and looking forward to connecting with you all!

      P.S. upload a photo to go with your post. A view from my desk photo always works great.
    `
      .replace(/^\s+/gm, " ")
      .trim(),
    reviewCommunityValues: "Review Community Values",
    enhanceYourProfile: "Enhance Your Profile",
    introduceYourself: "Introduce Yourself",
    downloadOurApp: "Download Our App",
    optIntoIntroductions: "Opt Into Introductions",
    unlockWelcomeGift: "🎁 Unlock Welcome Gift",
  },
  industries: {
    [IndustryEnum.AccountingFinance]: "Accounting & Finance",
    [IndustryEnum.AerospaceDefense]: "Aerospace & Defense",
    [IndustryEnum.AgricultureFarming]: "Agriculture & Farming",
    [IndustryEnum.Architecture]: "Architecture",
    [IndustryEnum.Art]: "Art",
    [IndustryEnum.Automotive]: "Automotive",
    [IndustryEnum.Beauty]: "Beauty",
    [IndustryEnum.Biotech]: "Biotech",
    [IndustryEnum.CannabisRecreationalSupplements]:
      "Cannabis & Recreational Supplements",
    [IndustryEnum.CommunityOrganizations]: "Community Organizations",
    [IndustryEnum.Construction]: "Construction",
    [IndustryEnum.Consulting]: "Consulting",
    [IndustryEnum.ConsumerPackagedGoods]: "Consumer Packaged Goods",
    [IndustryEnum.ConsumerServices]: "Consumer Services",
    [IndustryEnum.ConsumerTech]: "Consumer Tech",
    [IndustryEnum.CorporateServices]: "Corporate Services",
    [IndustryEnum.Cybersecurity]: "Cybersecurity",
    [IndustryEnum.Design]: "Design",
    [IndustryEnum.Ecom]: "Ecom",
    [IndustryEnum.EducationEdtech]: "Education & Edtech",
    [IndustryEnum.Electronics]: "Electronics",
    [IndustryEnum.Energy]: "Energy",
    [IndustryEnum.Entertainment]: "Entertainment",
    [IndustryEnum.EnvironmentalServices]: "Environmental Services",
    [IndustryEnum.EventProduction]: "Event Production",
    [IndustryEnum.Fashion]: "Fashion",
    [IndustryEnum.FinancialServices]: "Financial Services",
    [IndustryEnum.Fintech]: "Fintech",
    [IndustryEnum.Gaming]: "Gaming",
    [IndustryEnum.GovernmentAffairs]: "Government Affairs",
    [IndustryEnum.Healthcare]: "Healthcare",
    [IndustryEnum.HealthWellness]: "Health & Wellness",
    [IndustryEnum.Hr]: "HR",
    [IndustryEnum.Insurance]: "Insurance",
    [IndustryEnum.It]: "IT",
    [IndustryEnum.Legal]: "Legal",
    [IndustryEnum.LuxuryGoods]: "Luxury Goods",
    [IndustryEnum.Manufacturing]: "Manufacturing",
    [IndustryEnum.Marketing]: "Marketing",
    [IndustryEnum.Media]: "Media",
    [IndustryEnum.Medtech]: "Medtech",
    [IndustryEnum.MentalHealth]: "Mental Health",
    [IndustryEnum.NonProfit]: "Non Profit",
    [IndustryEnum.PetCare]: "Pet Care",
    [IndustryEnum.Policy]: "Policy",
    [IndustryEnum.PublicAffairs]: "Public Affairs",
    [IndustryEnum.PublicRelations]: "Public Relations",
    [IndustryEnum.PublicSafety]: "Public Safety",
    [IndustryEnum.RealEstate]: "Real Estate",
    [IndustryEnum.ResearchAcademia]: "Research & Academia",
    [IndustryEnum.Restaurants]: "Restaurants",
    [IndustryEnum.Retail]: "Retail",
    [IndustryEnum.SoftwareTech]: "Software & Tech",
    [IndustryEnum.Sports]: "Sports",
    [IndustryEnum.SupplyChainLogistics]: "Supply Chain & Logistics",
    [IndustryEnum.Sustainability]: "Sustainability",
    [IndustryEnum.Telecom]: "Telecom",
    [IndustryEnum.Transportation]: "Transportation",
    [IndustryEnum.TravelTourism]: "Travel & Tourism",
    [IndustryEnum.VentureCapitalPrivateEquity]:
      "Venture Capital & Private Equity",
  },
  intentStep: {
    title: "So glad you're here!<br/>What are you most looking forward to?",
    questions: "",
  },
  job_functions: {
    [JobFunctionEnum.Accounting]: "Accounting",
    [JobFunctionEnum.Business]: "Business",
    [JobFunctionEnum.Corporate]: "Corporate",
    [JobFunctionEnum.Finance]: "Finance",
    [JobFunctionEnum.Growth]: "Growth",
    [JobFunctionEnum.Hr]: "HR",
    [JobFunctionEnum.Innovation]: "Innovation",
    [JobFunctionEnum.MA]: "M&A",
    [JobFunctionEnum.Marketing]: "Marketing",
    [JobFunctionEnum.Operations]: "Operations",
    [JobFunctionEnum.Other]: "Other",
    [JobFunctionEnum.Sales]: "Sales",
    [JobFunctionEnum.Strategy]: "Strategy",
  },
  libraryScreen: {
    title: "Content Library!",
    description:
      "Lorem ipsum dolor sit amet consectetur. Est integer tristique viverra adipiscing ut eget lobortis. Semper scelerisque viverra justo velit elementum gravida ullamcorper. Scelerisque augue ullamcorper praesent massa dui ut. Netus tempus integer laoreet dictum dictum rhoncus lorem.",
    communityArticlesTitle: "From the Community",
    featuredArticlesTitle: "Featured Collection",
    libraryArticlesTitle: "Library",
    newlyAddedArticlesTitle: "Newly Added",
    trendingArticlesTitle: "Trending",
    searchInputPlaceholder: "Search our library...",
  },
  messages: {
    matchedCount: "<strong>{{count}} Match</strong> for your search criteria",
    matchedCount_plural:
      "<strong>{{count}} Matches</strong> for your search criteria",
    matchedCount_zero:
      "<strong>No matches.</strong> Your search did not match any existing messages.",
  },
  offers: {
    plusUpsell: {
      title: "Hey {{firstName}},",
      features: `
        <ul>
          <li>📓 Premium Content</li>
          <li>🤝 Curated Introductions</li>
          <li>📩 DMs</li>
          <li>💡 Member-Led Masterminds</li>
        </ul>
      `,
      body: `
        <p>You’ve been loving {{communityName}} and we thought we'd give you a reason to join us as a Plus member so you can get SO. MUCH. MORE. including:</p>
        <p>
          $t(plusUpsell.features)
        </p>
        <p>Try Plus Quarterly and take {{discountAmount}} off the first Quarter!</p>
        <ClaimButton>Explore Now</ClaimButton>
        <CreatorSignature/>
        <p>P.S. I'm holding this sweet deal for you for 7 days.</p>
      `,
      thankYou: `
        <h5><CommunityName /> just got so much better!</h5>
        <p>You now have access to:</p>
        <AlertHighlight>
          $t(plusUpsell.features)
        </AlertHighlight>
        <p>Cheers,</p>
        <CreatorSignature />
      `,
      moduleTitle: "Try Plus Membership with 40% off Quarterly",
    },
    countdownModule: {
      cta: "Save Now",
    },
  },
  onboarding: {
    activateScreenTitle: "",
    applicationsPausedModal: {
      title: "You're on the waitlist!",
      body: `
        <p>Thank you SO MUCH for applying to join!</p>
        <p>We're currently experiencing a high volume of applications and are working hard to review them all.</p>
        <p>We'll be in touch soon with an update on your application status.</p>
      `,
      confirmText: "Got it!",
    },
    homeBaseLabel: "Your Location",
    bioScreenTitle: "",
    bioFieldLabel: "",
    bioFieldPlaceholder: "",
    interestsScreenTitle: "What are you most excited to learn about?",
    expertiseScreenTitle: "What do you believe you’re an expert in?",
    introductionsScreen: {
      title: "Wait, one more question!",
      subtitle: "",
      introName: "",
      introFirstName: "",
      introLastName: "",
      introCompany: "",
      introBio: "",
      introLocation: "",
      introTitle: "",
      introInterests: ["", "", ""],
      introExpertise: ["", "", ""],
    },
    experienceScreen: {
      companySize: "What is your company size?",
      industry: "Which industry do you work in?",
      jobFunction: "What is your job function?",
      linkedinProfileUrl: "Please drop your Linkedin profile URL",
      oops: "Welp. We missed asking you this question. Can you help us out and answer this so we can make your membership experience even better?",
      oops_plural:
        "Welp. We missed asking you these questions. Can you help us out and answer these so we can make your membership experience even better?",
      title: "Title",
      vertical: "Which industry vertical do you work in?",
      yearsOfExperience: "How many years of experience do you have?",
    },
    videoIntro:
      "Before you dive in, here's a short video from {{creatorName}}.<br>We're so glad you're here.",
  },
  pricingTableStep: {
    title: "Upgrade your experience in one step",
    subtitle:
      "Try the membership with Free or jump into getting the most value with Plus. Unlock <em>everything</em> with Pro.",
    newMemberTitle: "Join with the right plan for you!",
    recommendedTierHeader: "Recommended for You",
    discountSticker: `
      <Small>Get</Small>
      <Large><Amount/></Large>
      <Large>Off</Large>
      <Small>Today</Small>
    `
      .replace(/\s+/g, " ")
      .trim(),
    plusTitle: "Make the most of <CommunityName /> with Plus",
    plusSubtitle: "Plus unlocks premium events, content, and intros.",
    proTitle: "Reserve your seat at every event",
    proSubtitle:
      "<div>Ticket to the in-person summit? Yes.</div><div>Access to premium virtual events? Yes.</div>",
    freeTierFeatures: `
      <ul>
        <ListItem><ListImageCheckmark /> Discussions</ListItem>
        <ListItem><ListImageCheckmark /> Guides, templates, & more</ListItem>
        <ListItem><ListImageCheckmark /> Virtual & IRL meet ups</ListItem>
        <ListItem><ListImageCheckmark /> Member directory</ListItem>
      </ul>
    `
      .replace(/\s+/g, " ")
      .trim(),
    plusTierFeatures: `
      <p>Additional features with Plus:</p>
      <ul>
        <ListItem><ListImagePlus /> Private virtual events</ListItem>
        <ListItem><ListImagePlus /> 1:1 Introductions</ListItem>
        <ListItem><ListImagePlus /> Early and discounted event tickets</ListItem>
        <ListItem><ListImagePlus /> Premium Content</ListItem>
      </ul>
    `
      .replace(/\s+/g, " ")
      .trim(),
    proTierFeatures: `
      <p>Additional features with Pro:</p>
      <ul>
        <ListItem><ListImagePlus /> Premium Virtual Events</ListItem>
        <ListItem><ListImagePlus /> Annual in-person summit</ListItem>
      </ul>
    `
      .replace(/\s+/g, " ")
      .trim(),
  },
  referral: {
    ctaText: "Invite A Friend!",
    description:
      "Your friend will receive a personal invitation to join the community via email.<br />Don't worry, you'll also be cc'ed on the email.",
  },
  responsibilityQuestions: {
    [UserResponsibilityQuestionsEnum.DefiningGuidingAndImplementingThePeopleStrategy]:
      "Defining, guiding, and implementing the People strategy at my company",
    [UserResponsibilityQuestionsEnum.ActingAsAKeyDecisionMaker]:
      "Acting as a key decision-maker on my company's HR initiatives, systems, and/or policies",
    [UserResponsibilityQuestionsEnum.RecruitingAmazingTalent]:
      "Recruiting amazing talent",
    [UserResponsibilityQuestionsEnum.BuildingAndMaintainingHighPerformingTeams]:
      "Building and maintaining high-performing teams",
    [UserResponsibilityQuestionsEnum.OnboardingCompensationPerformanceManagement]:
      "Onboarding, compensation, performance management, employee engagement, employee development, and/or benefits programs",
    [UserResponsibilityQuestionsEnum.ManagingHrBudgetAndAllocatingResources]:
      "Managing the HR budget and allocating resources effectively",
    [UserResponsibilityQuestionsEnum.DesigningAndImplementingTrainingPrograms]:
      "Designing and implementing training programs to enhance employee skills and growth",
    [UserResponsibilityQuestionsEnum.AllOperationsWithinMyCompany]:
      "All operations within my company, expanding across numerous departments",
    [UserResponsibilityQuestionsEnum.NoneOfTheAbove]: "None of the above",
    [UserResponsibilityQuestionsEnum.LeadingOrAdvisingOnLongTermStrategy]:
      "Leading or advising on long-term strategy",
    [UserResponsibilityQuestionsEnum.LeadingOrContributingToCorporateDevelopmentEfforts]:
      "Leading or contributing to corporate development efforts (M&A, capital strategies, partnerships, joint ventures, and/or innovation efforts)",
    [UserResponsibilityQuestionsEnum.ManagingMyPatientsAndTheirHealthJourney]:
      "Managing my patients and their health journey",
    [UserResponsibilityQuestionsEnum.OverseeingDailyOperationsThatHelpMyOrgGrowAndOperate]:
      "Overseeing daily operations that help my org grow and operate",
    [UserResponsibilityQuestionsEnum.EnsuringOrganizationalComplianceWithHealthcareLaws]:
      "Ensuring organizational compliance with healthcare laws, regulations, and standards",
    [UserResponsibilityQuestionsEnum.ContributingToHealthcareInvestmentResearchAndOrAnalysis]:
      "Contributing to healthcare investment research and/or analysis",
    [UserResponsibilityQuestionsEnum.WorkingWithInfluentialStakeholdersToDriveHealthSystem]:
      "Working with influential stakeholders to drive health system transformation",
    [UserResponsibilityQuestionsEnum.FacilitatingDevelopmentAndOrBrokerageOfRealEstate]:
      "Facilitating development and/or brokerage of real estate transactions",
    [UserResponsibilityQuestionsEnum.ExpandingBusinessDevelopmentEffortsSalesMarketing]:
      "Expanding business development efforts (sales, marketing, communication)",
    [UserResponsibilityQuestionsEnum.ManagementOfAMarketingTeam]:
      "Management of a marketing team",
    [UserResponsibilityQuestionsEnum.DevelopmentAndExecutionOfStrategicMarketingPlans]:
      "Development and execution of strategic marketing plans",
    [UserResponsibilityQuestionsEnum.MarketResearchAndConsumerInsights]:
      "Market research and consumer insights",
    [UserResponsibilityQuestionsEnum.ContentAndBrandStrategy]:
      "Content and brand strategy",
    [UserResponsibilityQuestionsEnum.DrivingRevenueThroughLeadGenerationEfforts]:
      "Driving revenue through lead generation efforts",
    [UserResponsibilityQuestionsEnum.SalesBusinessDevelopmentTeam]:
      "Sales & business development team",
    [UserResponsibilityQuestionsEnum.AStrategyAndBudgetForAtLeastOneMarketingChannel]:
      "A strategy and budget for at least one marketing channel",
    [UserResponsibilityQuestionsEnum.AssessmentAndSelectionOfOutsideVendorsToolsAndAgencies]:
      "Assessment and selection of outside vendors, tools, and agencies",
    [UserResponsibilityQuestionsEnum.MakingProductAndBrandLaunchesSuccessful]:
      "Making product and brand launches successful",
    [UserResponsibilityQuestionsEnum.TheMarginLtvAndOverallGrowthOfOurEcomBusiness]:
      "The margin, LTV, and overall growth of our ecom business",
    [UserResponsibilityQuestionsEnum.SupplyChainAndOperations]:
      "Supply chain and operations",
    [UserResponsibilityQuestionsEnum.OurCompanySMarketingBudgetInPartOrFull]:
      "Our company's marketing budget (in part or full)",
    [UserResponsibilityQuestionsEnum.TheDevelopmentAndExecutionOfStrategicMarketingPlans]:
      "The development and execution of strategic marketing plans",
    [UserResponsibilityQuestionsEnum.TheDesignEditingOrCreativeDirectionOfOurMarketing]:
      "The design, editing, or creative direction of our marketing assets",
    [UserResponsibilityQuestionsEnum.ManagementAndSelectionOfVendorsTechnologyAndAgencies]:
      "Management and selection of vendors, technology, and agencies",
    [UserResponsibilityQuestionsEnum.DevelopingPartnershipsWithEcomBrandsAsAVendorOrAgency]:
      "Developing partnerships with ecom brands",
    [UserResponsibilityQuestionsEnum.DrivingTheDevelopmentOfInnovativeFinancialProducts]:
      "<strong>Driving the development</strong> of innovative financial products, platforms, or services that significantly impact company growth or customer engagement.",
    [UserResponsibilityQuestionsEnum.AnalyzingMarketTrendsAndLeveragingDataInsights]:
      "<strong>Analyzing market trends</strong> and leveraging data insights to identify industry patterns and inform strategic decisions.",
    [UserResponsibilityQuestionsEnum.EnhancingRiskAndComplianceFrameworks]:
      "<strong>Enhancing risk and compliance frameworks</strong> to effectively navigate regulatory challenges and build trust.",
    [UserResponsibilityQuestionsEnum.ManagingLargeScaleInitiatives]:
      "<strong>Managing large-scale initiatives</strong> such as capital allocation, funding strategies, or technology investments to drive operational success.",
    [UserResponsibilityQuestionsEnum.FocusingOnB2cMarketingEfforts]:
      "<strong>Focusing primarily on B2C marketing efforts to drive consumer purchases or product usage</strong>",
    [UserResponsibilityQuestionsEnum.BuildingStrategicPartnerships]:
      "<strong>Building strategic partnerships</strong> with financial institutions, technology providers, or industry leaders to expand organizational reach and capabilities.",
    [UserResponsibilityQuestionsEnum.ApplyingAdvancedAnalyticsOrMachineLearning]:
      "<strong>Applying advanced analytics</strong> or machine learning to optimize growth strategies, improve product performance, or mitigate risks.",
    [UserResponsibilityQuestionsEnum.LeadingOperationalScalingEffortsThroughProcess]:
      "<strong>Leading operational scaling efforts</strong> through process automation, system upgrades, or digital transformation initiatives.",
    [UserResponsibilityQuestionsEnum.HandlingHrAdministrationCompanyPolicies]:
      "<strong>Handling HR administration, company policies, or recruitment</strong> without a strategic focus on talent development or organizational design.",
    [UserResponsibilityQuestionsEnum.BuildingProductsAndServicesThatCustomersWillLove]:
      "Building products and services that customers will love.",
    [UserResponsibilityQuestionsEnum.FiguringOutWhatsHappeningInTheIndustry]:
      "Figuring out what's happening in the industry and what it means for my company.",
    [UserResponsibilityQuestionsEnum.ManagingCreditFraudAndComplianceRisk]:
      "Managing credit, fraud, and/or compliance risk.",
    [UserResponsibilityQuestionsEnum.DevelopingOrMaintainingMyCompanysTechStack]:
      "Developing or maintaining my company's tech stack.",
    [UserResponsibilityQuestionsEnum.RunningB2cMarketingCampaigns]:
      "Running B2C marketing campaigns.",
    [UserResponsibilityQuestionsEnum.BuildingPartnershipsWithThirdParties]:
      "Building partnerships with third parties.",
    [UserResponsibilityQuestionsEnum.DevelopingAnalyticModels]:
      "Developing analytic models.",
    [UserResponsibilityQuestionsEnum.SolvingOperationalProblemsAndHelpingMyCompany]:
      "Solving operational problems and helping my company scale efficiently.",
    [UserResponsibilityQuestionsEnum.ManagingHumanResourcesHr]:
      "Managing human resources (HR). ",
  },
  rulesEngine: {
    operators: {
      [RulesEngineConditionOperatorEnum.Includes]: "contains",
      [RulesEngineConditionOperatorEnum.Excludes]: "does not contain",
      [RulesEngineConditionOperatorEnum.Equal]: "is",
      [RulesEngineConditionOperatorEnum.GreaterThan]: "is greater than",
      [RulesEngineConditionOperatorEnum.GreaterThanOrEqual]:
        "is greater than or equal to",
      [RulesEngineConditionOperatorEnum.LessThan]: "is less than",
      [RulesEngineConditionOperatorEnum.LessThanOrEqual]:
        "is less than or equal to",
      [RulesEngineConditionOperatorEnum.NotEqual]: "is not",
      [RulesEngineConditionOperatorEnum.IsBlank]: "is blank",
      [RulesEngineConditionOperatorEnum.IsPresent]: "is present",
      [RulesEngineConditionOperatorEnum.Matches]: "matches",
      [RulesEngineConditionOperatorEnum.DoesNotMatch]: "does not match",
      [RulesEngineConditionOperatorEnum.LocatedWithin]: "is located within",
      [RulesEngineConditionOperatorEnum.NotLocatedWithin]:
        "is not located within",
    },
  },
  screens: {
    [ScreenEnum.AddToHomescreenModal]: "Add to Homescreen Modal",
    [ScreenEnum.ApplicationLandingScreen]: "Application Landing Screen",
    [ScreenEnum.LibraryScreen]: "Library Screen",
    [ScreenEnum.PricingTableStep]: "Pricing Table Step",
  },
  signin: {
    signinTitle: "Sign in to [COMMUNITY]",
    signinSubtitle: "",
  },
  signup: {
    confirmation:
      "By confirming your subscription, you allow <CommunityName /> to charge you for future payments in accordance with their terms. You can always cancel your subscription.",
    consent:
      "I agree to <CommunityName />'s <TermsOfService>Terms of Service</TermsOfService>, <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <SubscriberAgreement>Subscriber Agreement</SubscriberAgreement>.",
    submit: "Subscribe for Free",
    title: "Subscribe to {{tierName}}",
  },
  socialContractStep: {
    title: "Welcome to your {{tierName}} Membership!",
    body: "Please review our shared community values.<br/>They mean a great deal to us. We hope you love em’ too.",
    disclaimer:
      "I understand that this community does not permit self or company promotion",
    confirmText: "Sounds great!",
    values: `
      <MicrophoneValue>
        What's said here, stays here!
      </MicrophoneValue>
      <HeartValue>
        Shared knowledge > siloed research
      </HeartValue>
      <BackpackValue>
        Relationships, not networking
      </BackpackValue>
      <SmileyValue>
        Less hangups, more hangouts
      </SmileyValue>
    `,
  },
  time: {
    dayWithCount: "{{count}} day",
    dayWithCount_plural: "{{count}} days",
    hourWithCount: "{{count}} hour",
    hourWithCount_plural: "{{count}} hours",
    minuteWithCount: "{{count}} minute",
    minuteWithCount_plural: "{{count}} minutes",
  },
  verticals: {
    [VerticalEnum.Academia]: "Academia",
    [VerticalEnum.AmbulatorySurgicalCenter]: "Ambulatory Surgical Center",
    [VerticalEnum.BankingPe]: "Banking / PE",
    [VerticalEnum.BehavioralHealth]: "Behavioral Health",
    [VerticalEnum.ConsultingAdvisor]: "Consulting / Advisor",
    [VerticalEnum.HealthItSoftwareHealthTech]:
      "Health IT / Software / Health Tech",
    [VerticalEnum.HospitalHealthSystem]: "Hospital / Health System",
    [VerticalEnum.Legal]: "Legal",
    [VerticalEnum.OtherRiskBearingOrgAcoCinEnablement]:
      "Other Risk-Bearing Org (ACO/CIN/Enablement)",
    [VerticalEnum.PayorHealthPlan]: "Payor / Health Plan",
    [VerticalEnum.PhysicianPractice]: "Physician Practice",
    [VerticalEnum.TechEnabledServices]: "Tech-Enabled Services",
  },
  unfitScreen: {
    title: "We're Sorry",
    body: `
      <p>Unfortunately, based on the information you gave us, we won't be able to activate your <CommunityName /> Membership.</p>
      <p>We know this isn't what you want to hear, but rest assured, we are developing future memberships for professionals like you.</p>
      <p>We are excited to connect with you in the future.</p>
      <p>If you have any questions or believe we've made a mistake, please reach out to us at <EmailLink />.</p>
    `,
  },
  fitScreen: {
    title: "Thanks!",
    subtitle: "Your application is under review",
    reviewTime: "⏳ Current Review Time: 48 Hours",
  },
  introductionsModule: {
    description:
      "Get relevant introductions to peers in the community. We curate these carefully based on your location, interests, and title.",
    ctaButtonText: "Turn On",
  },
  newInLibraryModule: {
    title: "New in Library",
  },
  directoryMap: {
    title: "Peers Near You",
  },
  upload: {
    description: `
      Shared knowledge is a core value of our community.
      <br />
      Skip ahead in line by sharing your favorite resource? You will be able
      to add it as your first contribution to our Shared Library later!
    `,
  },
  fileUploader: {
    title: "Share Content",
    subtitle: "Upload your best piece<br />of content",
  },
}

const overrideTranslations = (
  translations: any,
  acc: any = DEFAULT_TRANSLATIONS
) => {
  const result = { ...acc }

  for (const key in translations) {
    if (typeof translations[key] === "string") {
      result[key] = translations[key]
    } else {
      result[key] = overrideTranslations(translations[key], result[key])
    }
  }

  return result
}

export const languageResources: Record<CommunitySlug, any> = {
  [CommunitySlug.Safespace]: overrideTranslations({
    addToHomescreenModal: {
      title: "Download the App",
      body: `
        <p>Doomscrolling is out. Spending more time in a <CommunityName /> is IN.</p>
        <p>Add <CommunityName /> to your home screen to keep your mobile access easy peasy and never miss a thing. Best part? No app download necessary.</p>
        <ol>
          <li>Go to <CommunityUrl /> from your mobile browser</li>
          <li>Click on the share icon</li>
          <li>Scroll down and "Add to Homepage"</li>
          <li>Rename the app to "<CommunityName />"</li>
        </ol>
        <p>Boom! It will show up on your homescreen and you can set it right next to your (other) favorite apps 👀</p>
      `
        .replace(/\s+/g, " ")
        .trim(),
      bookmark_instructions: `
        <strong>Won't remember these instructions?</strong><br/>
        <Muted>Bookmark it!</Muted>
      `
        .replace(/\s+/g, " ")
        .trim(),
    },
    applicationLandingScreen: {
      title: "The community that's in <em>your</em> corner",
      subtitle: "Apply to join a community of People Leaders who get it.",
      submitCopy: "I'm in!",
    },
    bot: {
      name: "The Hive",
    },
    community: {
      creatorFirstName: "Hebba",
    },
    ctaChecklist: {
      introduction_template: `
        Hi, I'm {{currentUser.firstName}}

        I've been in HR for {{currentUser.yearsOfExperience}} years primarily specializing in {{currentUser.jobFunction}}.

        Today, I'm the {{currentUser.jobTitle}} on a HR team of {{currentUser.teamSize}}.

        When I'm not at work, you can find me...

        My HR hot take is...

        Excited to be here and looking forward to connecting with you all!

        P.S. upload a photo to go with your post. A view from my desk photo always works great.
      `
        .replace(/^ +/gm, "")
        .trim(),
    },
    intentStep: {
      questions: `
        <Question question="Connecting with HR leaders like myself who get it" />
        <Question question="Learning from a group of peers" />
        <Question question="Collaborating to create shared experiences and resources with peers" />
        <Question question="Have opportunities to grow my personal brand via the I Hate it Here universe" />
        <Question question="Feeling celebrated and see my peers in this community" />
      `,
    },
    signin: {
      signinTitle: "The HR community that's in your corner",
      signinSubtitle: "For the people prioritizing their people",
    },
    onboarding: {
      activateScreenTitle: "Welcome to your Safe Space",
      bioScreenTitle: "We all have a HR <em>hot</em> take. What's yours?",
      bioFieldLabel:
        "Feel free to also add anything else you'd like displayed on your profile",
      bioFieldPlaceholder:
        "As a new mom, I fight for better parental leave benefits at every organization. I'm also a book worm and love Gone Girl. DM me anytime!",
      introductionsScreen: {
        subtitle:
          "HR is more fun when you can jam with other People Leaders who get it. That’s why our premium memberships includes curated introductions it as a default benefit.",
        introName: "KP Larimore",
        introCompany: "Indiana Packers",
        introBio:
          "Accidentally fell into HR without a formal HR background and education. Strongly feel that experience goes longer than education validation. Always looking to learn different perspectives and ideas.",
        introLocation: "Logansport, IN",
        introTitle: "Sr. HR Generalist & OD Specialist",
        introFirstName: "KP",
        introLastName: "Larimore",
        introInterests: ["Culture", "Career Dev", "Org Dev"],
        introExpertise: ["Conflict Resolution", "Performance", "Wellbeing"],
      },
      experienceScreen: {
        title: "Your HR experience",
      },
      videoIntro:
        "Hear from Hebba who shares her freshest tips on how to make the most of your membership.",
    },
    pricingTableStep: {
      subtitle:
        "Try out the membership with Free<br/> or get the <Strong>most</Strong> value with Plus.",
      freeTierFeatures: `
        <ul>
          <ListItem><ListImageCheckmark /> Discussions</ListItem>
          <ListItem><ListImageCheckmark /> Guides, templates, & more</ListItem>
          <ListItem><ListImageCheckmark /> Virtual & IRL meet ups</ListItem>
          <ListItem><ListImageCheckmark /> HR search engine</ListItem>
          <ListItem><ListImageCheckmark /> Member directory</ListItem>
        </ul>
      `
        .replace(/\s+/g, " ")
        .trim(),
    },
    socialContractStep: {
      disclaimer:
        "I understand that Safe Space is not the place for company promotion",
      confirmText: "I'm in!",
      values: `
      <MicrophoneValue>
        What's said here,<br/>stays here!
      </MicrophoneValue>
      <BackpackValue>
        Shared knowledge > siloed research
      </BackpackValue>
      <HeartValue>
        Relationships, not networking
      </HeartValue>
      <SmileyValue>
        Less hangups, more hangouts
      </SmileyValue>
    `,
    },
  }),
  [CommunitySlug.Boardroom]: overrideTranslations({
    addToHomescreenModal: {
      title: "Save the app to homescreen",
      body: `
        <p>Doomscrolling is out. Spending more time with smart folks in Healthcare is IN.</p>
        <p>Add <CommunityName /> to your home screen for easy mobile access. No app download necessary.</p>
        <ol>
          <li>Go to <CommunityUrl /> from your mobile browser</li>
          <li>Click on the share icon</li>
          <li>Scroll down and "Add to Homepage"</li>
          <li>Rename the app to "<CommunityName />"</li>
        </ol>
        <p>Boom! It will show up on your homescreen and you can set it right next to your (other) favorite apps 👀</p>
      `
        .replace(/\s+/g, " ")
        .trim(),
      bookmark_instructions: `
        <strong>Won't remember these instructions?</strong><br/>
        <Muted>No Problem. Click the bookmark button and we'll save it for you there.</Muted>
      `
        .replace(/\s+/g, " ")
        .trim(),
    },
    applicationLandingScreen: {
      title: "Your home base for healthcare strategy",
      subtitle: "Apply to join a community of healthcare strategists",
      madlibs: `
      I have been in the healthcare industry for <YearsOfExperienceInput /><br/> years, primarily specializing in <JobFunctionSelect />.<br/>
      Now I'm the <JobTitleInput /> at a <OrganizationTypeInput />.
    `
        .replace(/\s+/g, " ")
        .trim(),
      submitCopy: "Submit",
    },
    bot: {
      name: "The Braintrust",
    },
    community: {
      creatorFirstName: "Blake",
    },
    contentTypes: {
      [ArticleContentTypeEnum.DeepDive]: "Exclusive Deep Dive",
    },
    ctaChecklist: {
      introduction_template: `
        Hi Hospitalogists!

        I'm {{currentUser.firstName}} and I've been in the healthcare industry for {{currentUser.yearsOfExperience}} years primarily specializing in {{currentUser.jobFunction}}.

        Today, I'm the {{currentUser.jobTitle}} at {{currentUser.companyName}}

        My healthcare hot take is...

        You can find me in (where you're based) spending quality time with (your people or favorite activity) on the weekends!

        Looking forward to connecting with you all.
      `
        .replace(/^ +/gm, "")
        .trim(),
    },
    intentStep: {
      questions: `
        <Question question="Connect with other high-signal leaders in strategy and operations" />
        <Question question="Access strategic insights and analytics to help me make better decisions" />
        <Question question="Have discussions about the latest trends and transformations in healthcare" />
      `,
    },
    signin: {
      signinTitle: "Your home base for healthcare strategy",
      signinSubtitle:
        "For the people strengthening the pulse of the healthcare industry",
    },
    offers: {
      plusUpsell: {
        features: `
          <ul>
            <li>
              📈 Deep Dives I spend 20 hours putting together
            </li>
            <li>
              🤝 Curated Introductions
            </li>
            <li>
              📩 Direct Messaging to all community members
            </li>
            <li>
              💡 Fireside Chats
            </li>
          </ul>
        `,
        body: `
          <p>
            You've already added value to this community by being here and I want to make sure you get the COMPLETE Hospitalogy experience. Pair the discussions with Plus membership so you can bring next-level Strategic advice to your organization and grow your rolodex on auto.
          </p>
          <p>
            $t(plusUpsell.features)
          </p>
          Try Plus Quarterly and take 40% off the first quarter!
          <ClaimButton>Explore Now</ClaimButton>
          <CreatorSignature />
          <p>
            P.S. I'm holding this offer for your for 7 days in case you can get it expensed
          </p>
        `,
      },
    },
    onboarding: {
      activateScreenTitle: "Welcome to Hospitalogy!",
      bioScreenTitle:
        "How would you introduce yourself to peer healthcare strategists?",
      bioFieldLabel:
        "This short bio will be used to introduce you to peers bi-weekly!",
      bioFieldPlaceholder:
        "Since starting my career in hospital administration, I've forged hundreds of partnerships for new local clinics to start and scale. I live with my Wife and two children in Nashville, TN! You can likely find me reading Blake's Deep Dives or on the golf course.",
      introductionsScreen: {
        title: "Expand your healthcare network via introductions?",
        subtitle:
          "Get relevant introductions to peers in the community. We curate intros carefully based on your location, interests, and title.  <br><br>Introductions are sent directly via email every other Monday morning! You will be able to set up time to connect with a peer by replying to the email or via Direct Messages in the Hospitalogy web app.",
        introName: "Ann Somers Hogg",
        introBio:
          "Ann Somers is the director of health care research at the Clayton Christensen Institute. Her research focuses on business model innovation and disruption, particularly as they relate to drivers of health and maternal health. Prior to joining the Institute, Ann Somers worked for Atrium Health (now Advocate Health), where she most recently served as the AVP of Strategy and Transformation.",
        introLocation: "Richmond, VA",
        introCompany: "Christensen Institute",
        introTitle: "Director, Health Care Research",
        introFirstName: "Ann",
        introLastName: "Somers Hogg",
        introInterests: [
          "Workforce & Talent",
          "Tech & Innovation",
          "Public Health",
        ],
        introExpertise: [
          "Tech & Innovation",
          "Public Health",
          "Value-Based Care",
        ],
      },
      experienceScreen: {
        title: "Your healthcare experience",
        vertical: "Which healthcare vertical do you work in?",
        yearsOfExperience:
          "How many years of healthcare experience do you have?",
      },
    },
    libraryScreen: {
      contributeBannerTitle:
        "Share resources, research, or notes with the Hospitalogy community",
      contributeBannerCopy:
        "Contribute to the private Hospitalogy library and get listed as an author! Shared knowledge is always better.",
    },
    socialContractStep: {
      values: `
      <MicrophoneValue>
        What's said here, stays here!
      </MicrophoneValue>
      <BackpackValue>
        Shared knowledge > siloed research
      </BackpackValue>
      <HeartHandshakeValue>
        Relationships, not networking
      </HeartHandshakeValue>
      <SirenValue>
        Diversity of perspectives leads to unlocked insights
      </SirenValue>
    `,
    },
  }),
  [CommunitySlug.Marketingland]: overrideTranslations({
    addToHomescreenModal: {
      title: "Save the app to homescreen",
      body: `
        <p>Doomscrolling is out. Spending more time with Marketing Besties is IN.</p>
        <p>Add <CommunityName /> to your home screen to keep your mobile access. No app download necessary.</p>
        <ol>
          <li>Go to <CommunityUrl /> from your mobile browser</li>
          <li>Click on the share icon</li>
          <li>Scroll down and "Add to Homepage"</li>
          <li>Rename the app to "<CommunityName />"</li>
        </ol>
        <p>Boom! It will show up on your homescreen and you can set it right next to your (other) favorite apps 👀</p>
      `
        .replace(/\s+/g, " ")
        .trim(),
      bookmark_instructions: `
        <strong>Won't remember these instructions?</strong><br/>
        <Muted>No Problem. Click the bookmark button and we'll save it for you there.</Muted>
      `
        .replace(/\s+/g, " ")
        .trim(),
    },

    applicationLandingScreen: {
      title: "Where marketers gather to stay ahead of the curve",
      subtitle:
        "Marketing moves fast. Apply to join Marketingland Besties who move faster together.",
      madlibs: `
      I'm a <BusinessSelect /> Marketer with <YearsOfExperienceInput /> years of experience.
      Currently, my title is <JobTitleInput /> and I primarily focus on <JobFunctionInput /> marketing <OrganizationTypeInput /> on a team of <TeamSizeInput />.<br/>
      To keep me cruising through the day, I drink <CupsOfCoffeeInput /> of coffee.
    `
        .replace(/\s+/g, " ")
        .trim(),
      submitCopy: "Submit",
    },
    bot: {
      name: "The Professor",
    },
    community: {
      creatorFirstName: "Daniel",
      directoryLabel: "Besties",
    },
    ctaChecklist: {
      introduction_template: `
        Hi Marketing Besties, I'm {{currentUser.firstName}}

        I'm a marketer in the {{currentUser.business}} industry

        I have about {{currentUser.yearsOfExperience}} experience years of experience, with my primary experience in {{currentUser.jobFunction}}.

        Today, I'm the {{currentUser.jobTitle}} and go through {{currentUser.cupsOfCoffee}} cups of coffee per day lol

        When I'm not posting memes on the internet, you can find me in (where are you based)

        Can't wait to meet you all!
      `
        .replace(/^ +/gm, "")
        .trim(),
    },
    intentStep: {
      questions: `
        <Question question="Connect and meet with other marketing besties" />
        <Question question="Get best practices, templates, swipe files, and guides from other marketing besties" />
        <Question question="Get advice from other marketing besties when I need it most" />
      `,
    },
    signin: {
      signinTitle: "Where Marketers gather to stay ahead of the curve",
      signinSubtitle:
        "Marketing moves fast.<br>The Marketingland Besties move faster together.",
    },
    offers: {
      plusUpsell: {
        features: `
          <ul>
            <li>
              📈 Relevant, actionable content
            </li>
            <li>
              🤝 Curated Introductions to peer Besties
            </li>
            <li>
              📩 Direct Messaging
            </li>
            <li>
              💡 Coach's Corner Events
            </li>
          </ul>
        `,
        body: `
          <p>
            Joining Marketingland makes you a Bestie, but I want you to have ALL. THE. PERKS. Let's grow together because marketing moves fast, but we can move faster when you have access to everything including:
          </p>
          <p>
            $t(plusUpsell.features)
          </p>
          Try Plus Quarterly and take 40% off the first quarter!
          <ClaimButton>Explore Now</ClaimButton>
          <CreatorSignature />
          <p>
            P.S. i'm holding this sweet deal for you for 7 days
          </p>
        `,
      },
    },
    onboarding: {
      activateScreenTitle: "Welcome home,<br>Marketing Bestie",
      applicationsPausedModal: {
        body: `
          <p>
            Thank you SO MUCH for applying to join the Marketingland Membership!
          </p>
          <p>
            We're temporarily pausing new members — want to make sure we get things right for our first 400 Marketing
            Besties on the ground floor.
          </p>
          <p>
            Doors will re-open in a few weeks. Stay tuned...
          </p>
        `,
      },
      bioScreenTitle: "How would you introduce yourself to peers in marketing?",
      bioFieldLabel: "Keep it simple like Sabrina Carpenter, short and sweet",
      bioFieldPlaceholder:
        "I’ve been a B2B marketer for 10 years and let me tell you, I love that memes are a part of my vocabulary now. I live in upstate New York with my partner and two dogs. When I’m not churning out content and ideating on new strategies, you can find me in my backyard gardening!",
      interestsScreenTitle:
        "What are you excited to dive into in the next year?",
      expertiseScreenTitle: "What do you believe is your marketing superpower?",
      introductionsScreen: {
        subtitle:
          "Marketing is more fun when you can jam with other top marketers who get it. That’s why our premium memberships include it as a default benefit.",
        introName: "Jay Schwedelson",
        introCompany: "SubjectLine",
        introBio:
          "Jay is the Founder of SubjectLine.com, the leading free subject-line rating tool, ranked in the top 1% of all websites worldwide. He is also the Founder of GURU Media Hub, which hosts the GURU conference, the world’s largest email marketing event, as well as other major marketing events that attract more than 50,000 attendees annually.",
        introLocation: "Boca Raton, FL",
        introTitle: "Marketing Expert",
        introFirstName: "Jay",
        introLastName: "Schwedelson",
        introInterests: ["Content Marketing", "Demand Gen", "Event Marketing"],
        introExpertise: [
          "Content Marketing",
          "Email Marketing",
          "Event Marketing",
        ],
      },
      experienceScreen: {
        title: "Your marketing experience",
        yearsOfExperience:
          "How many years of marketing experience do you have?",
      },
    },
    pricingTableStep: {
      subtitle:
        "Try the membership with Free<br/> or get the <Strong>most</Strong> value with Plus.",
    },
    socialContractStep: {
      values: `
      <HeartHandshakeValue>
        Friendship is mandatory
      </HeartHandshakeValue>
      <ChartLineValue>
        Growth is required
      </ChartLineValue>
      <SirenValue>
        As is marketing excellence
      </SirenValue>
      <SmileyValue>
        As are memes.<br/>Sorry, we're strict
      </SmileyValue>
    `,
    },
  }),
  [CommunitySlug.Gotomillions]: overrideTranslations({
    applicationLandingScreen: {
      title: "Ready to join Go-to-Millions Membership?",
      subtitle:
        "Apply to the community for marketers behind million-dollar ecom brands",
      madlibs: `
      I've been in ecom for <YearsOfExperienceInput /> years<br/>
      Now, I'm the <JobTitleInput /> at a/an <OrganizationTypeInput /> in the <EcomIndustrySelect /> industry where I spend most of my workday on <JobFunctionSelect /><br/>
      My company is expecting <RevenueSelect /> in revenue in the next 12 months!
    `
        .replace(/\s+/g, " ")
        .trim(),
      submitCopy: "Submit",
    },
    contentTypes: {
      [ArticleContentTypeEnum.CommunityJam]: "Member Meeting",
      [ArticleContentTypeEnum.FunnelHacking]: "Hosted by Ari",
    },
    intentStep: {
      title:
        "WELCOME, Millionaire.<br/> Tell me. What're you <strong>most</strong> looking forward to? 🦢",
      questions: `
        <Question question="Connecting with ecom marketers who get it" />
        <Question question="Learning from a group of my peers" />
        <Question question="Collabing in real-time and getting detailed feedback" />
        <Question question="Staying in the know and 1 step ahead" />
        <Question question="Going behind-the-scenes of brands that win" />
      `,
    },
    ctaChecklist: {
      introduction_template: `
        Hi! I’m {{currentUser.firstName}}.

        I’ve been in ecom for {{currentUser.yearsOfExperience}} years and am the {{currentUser.jobTitle}} at a {{currentUser.organizationType}}. I spend most of my time focusing on {{currentUser.jobFunction}}.

        When I’m not thinking about work, you can find me...

        I’m excited for... as a part of this community.

        BTW: a brand I’m currently into is...
      `
        .replace(/^ +/gm, "")
        .trim(),
      enhanceYourProfile: "Polish Your Profile",
      introduceYourself: "Intro Yourself",
      downloadOurApp: "Download the App",
      unlockWelcomeGift: "Get Your Welcome Present",
    },
    pricingTableStep: {
      subtitle: "Join for Free OR get everything with Plus.",
      newMemberTitle: "Add to cart & checkout",
      freeTierFeatures: `
      <ul>
        <ListItem><ListImageCheckmark /> 24/7 conversations</ListItem>
        <ListItem><ListImageCheckmark /> Channels</ListItem>
        <ListItem><ListImageCheckmark /> Content library</ListItem>
        <ListItem><ListImageCheckmark /> Peer Millionaire directory</ListItem>
      </ul>
    `
        .replace(/\s+/g, " ")
        .trim(),
      plusTierFeatures: `
      <p>Additional features with Plus:</p>
      <ul>
        <ListItem><ListImagePlus /> Private convos & channels</ListItem>
        <ListItem><ListImagePlus /> Curated 1:1 intros</ListItem>
        <ListItem><ListImagePlus /> Events (with my fav brands)</ListItem>
        <ListItem><ListImagePlus /> Premium swipe files & spreadsheets</ListItem>
      </ul>
    `
        .replace(/\s+/g, " ")
        .trim(),
    },
    signin: {
      signinTitle: "Sign in to Go-to-Millions Membership",
      signinSubtitle: "The community for million-dollar ecom brands",
    },
    community: {
      creatorFirstName: "Ari",
      directoryLabel: "Millionaires",
      libraryLabel: "The Garden",
    },
    libraryScreen: {
      libraryArticlesTitle: "In the Garden",
      searchInputPlaceholder: "Search the Community Garden...",
    },
    socialContractStep: {
      title: "You're in 🦢",
      body: "Please find our <strong>strict</strong> core values below:",
      confirmText: "Let's go",
      values: `
      <GtmTrophyValue>
        Learn and ITERATE<br/>
        (not optional)
      </GtmTrophyValue>
      <GtmHandshakeValue>
        Share your experience
      </GtmHandshakeValue>
      <GtmBullseyeValue>
        Stay brutally honest
      </GtmBullseyeValue>
      <GtmHandValue>
        Hone your taste
      </GtmHandValue>
    `,
    },
    referral: {
      ctaText: "Invite A Friend",
    },
    fitScreen: {
      title: "Merci beaucoup!",
    },
    introductionsModule: {
      description:
        "Get relevant intros to peer Millionaires in the community every other week. We curate these carefully.",
      ctaButtonText: "Opt In",
    },
    newInLibraryModule: {
      title: "New in The Garden",
    },
    directoryMap: {
      title: "Millionaires Near You",
    },
    upload: {
      description: `
      ~Sharing your experience~ is a core value of our community.
      <br />
      Skip ahead in line by sending in your favorite resource?
      <br />
      You’ll be able to add it as your 1st contribution to our Library later.
    `,
    },
    fileUploader: {
      title: "Share Content",
      subtitle: "Upload your favorite<br />piece of content",
    },
    addToHomescreenModal: {
      title: '"Download" Go-to-Millions on Mobile',
      body: `
      <p>"Download" this app:<br/><br/></p>
      <p>
        <ol>
          <li>Go to <CommunityUrl /> from your mobile browser</li>
          <li>Click on the share icon</li>
          <li>Scroll down and "Add to Homepage"</li>
          <li>Rename the app to "<CommunityName />"</li>
        </ol>
      </p>
      <br/>
      <p>Boom! It will show up on your homescreen and you can set it right next to your favorite apps 👀</p>
    `
        .replace(/\s+/g, " ")
        .trim(),
      bookmark_instructions: `
      <strong>Want to come back to these instructions?</strong><br/>
      <Muted>Bookmark for later.</Muted>
    `
        .replace(/\s+/g, " ")
        .trim(),
    },
    offers: {
      plusUpsell: {
        title: "Hey {{firstName}},",
        features: `
        <ul>
          <li>🦢: Swipe files, spreadsheets, & more</li>
          <li>🦢: Curated intros by yours truly</li>
          <li>🦢: Access to private channels</li>
          <li>🦢: Invites to exclusive events</li>
        </ul>
      `,
        body: `
        <p>Glad you tried the Free version. Let's get upgraded.<br/>Here's what you'll get...</p>
        <p>
        $t(plusUpsell.features)
        </p>
        <p>Try Plus and take {{discountAmount}} off the 1st quarter.</p>
        <ClaimButton>Explore Now</ClaimButton>
      `,
        thankYou: `
        <h5><CommunityName /> just got so much better!</h5>
        <p>You now have access to:</p>
        <AlertHighlight>
          $t(plusUpsell.features)
        </AlertHighlight>
        <CenteredText>🫡🔮🌠🌹❤️‍🔥</CenteredText>
      `,
        moduleTitle: "🦢 Try Plus Membership with 40% off Quarterly",
      },
    },
  }),

  [CommunitySlug.Fintechtakes]: overrideTranslations({
    applicationLandingScreen: {
      title: "Ready to join<br/>Fintech Takes Membership?",
      subtitle: "Where fintech’s brightest minds turn curiosity into expertise",
      madlibs: `
      I have been in the financial services industry for <YearsOfExperienceInput /><br/> years, primarily specializing in <JobFunctionSelect />.<br/>
      Now I'm the <JobTitleInput /> at a <OrganizationTypeInput />.
    `
        .replace(/\s+/g, " ")
        .trim(),
      submitCopy: "Submit",
    },
    bot: {
      name: "The Professor",
    },
    community: {
      creatorFirstName: "Alex",
    },
    contentTypes: {},
    ctaChecklist: {
      introduction_template:
        "Share an observation, question, or introduction here. OR reply to a peer nerd's post to get started!"
          .replace(/^ +/gm, "")
          .trim(),
      introduceYourself: "Post or Reply",
    },
    fileUploader: {
      title: "Upload",
      subtitle: "Share your favorite<br />resource",
    },
    intentStep: {
      title:
        "Welcome!<br/>I must know, what're you <strong>most</strong> looking forward to?",
      questions: `
        <Question question="Connecting with other financial services leaders" />
        <Question question="Learning from a group of peers" />
        <Question question="Collaborating to create shared experiences and resources with peers" />
        <Question question="Have opportunities to grow my personal brand" />
      `,
    },
    referral: {
      description:
        "Your friend will receive a personal email invite to join the community and you'll be cc'ed.",
    },
    offers: {
      plusUpsell: {
        title: "Hey {{firstName}},",
        features: `
        <ul>
          <li>🏔️ Private conversation & groups</li>
          <li>🏔️ Monthly Office Hours with me</li>
          <li>🏔️ Curated 1:1 introductions</li>
          <li>🏔️ Premium research reports & more</li>
        </ul>
      `,
        body: `
        <p>Thanks for being here. I hope you're enjoying being a part of the Fintech Takes Network!</p>
        <p>Now, I want you to have the full experience. Here's what comes with Fintech Takes Plus.</p>
        <p>
          $t(plusUpsell.features)
        </p>
        <p>Try Plus Quarterly and take {{discountAmount}} off the first quarter!</p>
        <ClaimButton>Explore Now</ClaimButton>
        <CreatorSignature/>
      `,
        thankYou: `
        <h5><CommunityName /> just got so much better!</h5>
        <p>You now have access to:</p>
        <AlertHighlight>
          $t(plusUpsell.features)
        </AlertHighlight>
        <p>Cheers,<br/><CreatorSignature /></p>
      `,
        moduleTitle: "Try Plus Membership with 40% off Quarterly",
      },
      countdownModule: {
        cta: "Claim Offer",
      },
    },
    pricingTableStep: {
      subtitle: "Join for Free OR get the full experience with Plus.",
    },
    signin: {
      signinTitle: "Sign in to Fintech Takes Membership",
      signinSubtitle:
        "Where fintech’s brightest minds turn curiosity into expertise",
    },
    socialContractStep: {
      title: "Welcome to your {{tierName}} Membership",
      disclaimer:
        "I understand that this network does not permit self or company promotion.",
      body: "Please review our core values below:",
      values: `
      <ChartLineArrowValue>
        Be a beginner
      </ChartLineArrowValue>
      <HeartCrackValue>
        Don't be an asshole
      </HeartCrackValue>
      <LightbulbValue>
        Be smart, not stubborn
      </LightbulbValue>
      <BinocularsValue>
        Don't be a lurker
      </BinocularsValue>
    `,
    },
    upload: {
      description: `
        Skip ahead in line by sharing your favorite resource?<br/>You will be able to add it as your first contribution to our Library later.
      `,
    },
    addToHomescreenModal: {
      title: "Get Fintech Takes on Mobile",
      body: `
      <p>To "download" the app:<br/><br/></p>
      <p>
        <ol>
          <li>Go to <CommunityUrl /> from your mobile browser<br/><br/></li>
          <li>Click on the share icon<br/><br/></li>
          <li>Scroll down and select "Add to Home Screen"<br/><br/></li>
        </ol>
      </p>
      <br/>
      <p>That's it!</p>
      <br/>
      <p>Set the app anywhere you'd like on your mobile home screen.</p>
    `
        .replace(/\s+/g, " ")
        .trim(),
      bookmark_instructions: `
      <strong>Wont remember these instructions?</strong><br/>
      <Muted>Bookmark and revisit later.</Muted>
    `
        .replace(/\s+/g, " ")
        .trim(),
    },
  }),
}

export default i18n
