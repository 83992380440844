import { formatDate } from "date-fns"
import { useMemo } from "react"
import { formatCurrency } from "~/common/formatCurrency"
import { useCommunity } from "~/community/useCommunity"
import { useTiers } from "~/tiers/TiersProvider"
import { useSubscription } from "./SubscriptionProvider"
import { SubscriptionStatus } from "./SubscriptionStatus"
import { TierLevelEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/common/GlobalsProvider"

export const SubscriptionOverview = () => {
  const community = useCommunity()
  const { subscription } = useSubscription()
  const currentUser = useCurrentUser()
  const { formatTierName } = useTiers()

  const upcomingTier = useMemo(() => {
    return currentUser.upcomingTier
  }, [currentUser])

  const upcomingTierInterval = useMemo(() => {
    return currentUser.upcomingTierInterval
  }, [currentUser])

  const isMovingToFreeTier = useMemo(() => {
    return currentUser.upcomingTier?.level === TierLevelEnum.Free
  }, [currentUser.upcomingTier])

  const interval = useMemo(() => {
    if (!currentUser.tierInterval) return null

    if (upcomingTierInterval) {
      return upcomingTierInterval === "year"
        ? "annually"
        : "per quarter (every 12 weeks)"
    }
    return currentUser.tierInterval === "year"
      ? "annually"
      : "per quarter (every 12 weeks)"
  }, [upcomingTierInterval, currentUser.tierInterval])

  if (!subscription?.status) return null

  return (
    <div className="flex flex-col gap-4">
      {upcomingTier && subscription?.subscriptionSchedule ? (
        <>
          <p>
            Your current {community.name}{" "}
            {formatTierName(currentUser.tier, currentUser.tierInterval)} is{" "}
            <SubscriptionStatus
              status={subscription?.status}
              className="mx-1"
            />{" "}
            until the end of your billing period on{" "}
            <strong>
              {formatDate(
                subscription.subscriptionSchedule.currentPhase?.endDate,
                "MMM d, yyyy"
              )}
            </strong>
            .{" "}
          </p>
          <p>
            Your membership is set to change to{" "}
            {formatTierName(upcomingTier, upcomingTierInterval)} on{" "}
            <strong>
              {formatDate(
                subscription.subscriptionSchedule.phases[1]?.startDate,
                "MMM d, yyyy"
              )}
            </strong>
            {!isMovingToFreeTier &&
              ` at ${formatCurrency(
                subscription.upcomingInvoice!.amountRemaining
              )} ${interval}`}
            .
          </p>
        </>
      ) : (
        <>
          <p>
            Your current {community.name}{" "}
            {formatTierName(currentUser.tier, currentUser.tierInterval)} is{" "}
            <SubscriptionStatus
              status={subscription?.status}
              className="mx-1"
            />
            {!subscription.cancelAtPeriodEnd && (
              <>
                and renews
                {subscription.upcomingInvoice &&
                  ` at ${formatCurrency(
                    subscription.upcomingInvoice!.amountRemaining
                  )} ${interval}`}{" "}
                on{" "}
                <strong>
                  {formatDate(subscription.currentPeriodEnd, "MMM d, yyyy")}
                </strong>
                .
              </>
            )}
          </p>
        </>
      )}
      {subscription.cancelAtPeriodEnd && (
        <p>
          Your membership will be canceled at the end of the current billing
          period on{" "}
          <strong>{formatDate(subscription.cancelAt, "MMM d, yyyy")}</strong>.
        </p>
      )}
    </div>
  )
}
