import { parseISO, isAfter, sub, format, formatDistance } from "date-fns"

export const postDateDisplay = (dateIso: string) => {
  const date = parseISO(dateIso)

  if (isAfter(date, sub(new Date(), { seconds: 5 }))) {
    return "Just now"
  } else if (isAfter(date, sub(new Date(), { hours: 24 }))) {
    return formatDistance(date, new Date(), { addSuffix: true })
  } else {
    return format(date, "M/d/yyyy")
  }
}
