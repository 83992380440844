import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { Button } from "~/ui/button"

import { DialogHeader } from "~/ui/dialog"
import { ArticleCard } from "~/ui/ArticleCard"

export const Base = ({
  header,
  title,
  prev,
  next,
  disabled,
  submit,
  editContext = false,
  callback,
  children,
  articleId,
}: {
  header?: string
  title: string
  prev?: () => void
  next?: () => void
  disabled: boolean
  editContext?: boolean
  submit?: () => void
  callback?: () => void
  children: React.ReactNode
  articleId?: string | null
}) => {
  return (
    <div>
      {header && (
        <div className="flex justify-between p-4 border-b border-mercury">
          <DialogHeader className="items-center text-sm tracking-wide font-medium">
            {header}
          </DialogHeader>
        </div>
      )}

      <div className="p-6 pb-0">
        {articleId && <ExplainerSection articleId={articleId} />}
        <h2 className="pt-2 my-4 font-serif text-2xl">{title}</h2>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          {children}

          {!editContext ? (
            <div className="py-6 flex justify-between items-center border-t border-mercury">
              {prev && !editContext && (
                <div
                  onClick={prev}
                  className="text-xs text-textLight cursor-pointer"
                >
                  Back
                </div>
              )}
              {next && !editContext && (
                <Button
                  onClick={next}
                  disabled={disabled}
                  className="min-w-[100px]"
                >
                  Next
                </Button>
              )}
            </div>
          ) : (
            <div className="py-6 flex justify-end items-center border-t border-mercury">
              <Button
                onClick={() => {
                  submit?.()
                  callback?.()
                }}
                disabled={disabled}
              >
                Update
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const ExplainerSection = ({ articleId }: { articleId: string }) => {
  const { data } = useQuery(EXPLAINER_ARTICLE_QUERY, {
    variables: { articleId },
  })

  const article = data?.article

  if (!article || !article.revision) return null

  return <ArticleCard article={article} state="default" variant="hero" />
}

const EXPLAINER_ARTICLE_QUERY = gql(`
  query ExplainerArticle($articleId: ID!) {
    article(articleId: $articleId) {
      ...Article_Card
    }
  }
`)
