import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useCommunity } from "~/community/useCommunity"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { useEffect, useRef } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const TakeAnExpenseTemplateStep = () => {
  const { goToStep, meta } = useWizard()
  const { logEvent } = useLogEvent()
  const community = useCommunity()
  const [runSendExpenseTemplate] = useSafeMutation(
    SEND_EXPENSE_TEMPLATE_MUTATION
  )
  const currentUser = useCurrentUser()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.ExpenseFormModalViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const unableToExpense = async () => {
    logEvent(AhoyEventTypeEnum.ExpenseFormDeclined, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
    })
    goToStep("LookupSaveBeforeLoseOffersStep", "forward", false)
  }

  const sendExpenseTemplate = async () => {
    goToStep("LoadingStep", "forward", false)
    logEvent(AhoyEventTypeEnum.ExpenseFormAccepted, {
      cancellation_reason: meta.cancellationReason,
      other_cancellation_reason: meta.otherReason,
    })
    window.open("/expense-template", "_blank")?.focus()
    await runSendExpenseTemplate({
      variables: {
        input: {
          userId: currentUser.id,
        },
      },
    })
    goToStep("SentAnExpenseTemplateStep")
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          75% of {community.name} Members are able to expense their membership.
        </DialogTitle>
      </DialogHeader>
      <Alert>
        Would you like to leverage our expense template to see if this
        membership may be eligible as a professional expense or employee
        benefit?
      </Alert>

      <Separator />

      <DialogFooter className="sm:flex-col gap-6 items-center">
        <Button onClick={sendExpenseTemplate}>
          Yes, I'd like the expense template
        </Button>
        <Button
          type="button"
          variant="link"
          size="inline"
          className="text-2xs"
          onClick={unableToExpense}
        >
          I’m unable to expense my membership
        </Button>
      </DialogFooter>
    </>
  )
}

TakeAnExpenseTemplateStep.displayName = "TakeAnExpenseTemplateStep"

const SEND_EXPENSE_TEMPLATE_MUTATION = gql(`
  mutation SendExpenseTemplate($input: UserSendExpenseTemplateInput!) {
    userSendExpenseTemplate(input: $input) {
      user {
        id
      }
    }
  }
`)
