import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { subscribePath } from "~/common/paths"

export const RequireUserActive = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser(false)

  useEffect(() => {
    if (currentUser && !currentUser.active) {
      navigate(subscribePath.pattern)
    }
  }, [navigate, currentUser])

  if (currentUser && !currentUser.active) {
    return null
  }

  return <Outlet />
}
