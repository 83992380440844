import { useCommunityClassname } from "~/community/useCommunity"
import { useCurrentUser } from "~/common/GlobalsProvider"
import { CommunitySlug, TierLevelEnum } from "~/__generated__/graphql"
import Medal from "~/images/icons/medal.svg?react"
import { cn } from "~/lib/utils"
import { CommunityLogo } from "~/community/CommunityLogo"

const TIERS: Record<TierLevelEnum, string> = {
  [TierLevelEnum.Free]: "Free Member",
  [TierLevelEnum.Plus]: "Plus Member",
  [TierLevelEnum.Pro]: "Pro Member",
}

const tierDisplay = (tier?: TierLevelEnum) => TIERS[tier || TierLevelEnum.Free]

type ActivationStatus = "Not Activated" | "Activating" | "Activated"

export const NewMembershipCard = ({
  containerClassName,
}: {
  containerClassName?: string
}) => {
  const currentUser = useCurrentUser()
  const name = currentUser.name || "New Member"

  const ccls = useCommunityClassname()

  let status: ActivationStatus = "Not Activated"
  if (currentUser.fit) {
    status = "Activated"
  } else if (currentUser.fitWaiting) {
    status = "Activating"
  }

  return (
    <div
      className={cn(
        "p-2 rounded-[6px] w-[200px] aspect-video flex-shrink-0 flex flex-col justify-between",
        ccls({
          [CommunitySlug.Safespace]:
            "safespace-membership-card-new-bg text-white",
          [CommunitySlug.Boardroom]:
            "boardroom-membership-card-new-bg text-white",
          [CommunitySlug.Marketingland]:
            "marketingland-membership-card-new-bg text-white",
          [CommunitySlug.Gotomillions]:
            "gotomillions-membership-card-new-bg text-white",
          [CommunitySlug.Fintechtakes]:
            "fintechtakes-membership-card-new-bg text-white",
        }),
        containerClassName
      )}
    >
      <div className="flex items-start justify-between">
        <CommunityLogo
          className={cn(
            "w-auto",
            ccls({
              [CommunitySlug.Gotomillions]: "max-h-10",
              [CommunitySlug.Fintechtakes]: "max-h-6 text-white",
              default: "max-h-[10px]",
            })
          )}
        />
        <div
          className={cn(
            "px-[6px] py-1 text-[4px] rounded-[2px] tracking-wide text-white uppercase",
            ccls({
              [CommunitySlug.Safespace]: "bg-subtlePink text-black",
              default: "bg-highlight",
            }),
            status === "Activating" && "bg-[#FFD829] text-black",
            status === "Activated" &&
              ccls({
                [CommunitySlug.Gotomillions]: "bg-primary text-white",
                [CommunitySlug.Fintechtakes]: "bg-[#12A7C9] text-white",
                default: "bg-seaGreen text-black",
              })
          )}
        >
          {status}
        </div>
      </div>
      <div className="text-[9px]">5892 7629 1003 2241</div>
      <div className="flex items-center justify-between">
        <div className="text-[7px]">{name}</div>
        <div className="flex items-center gap-[2px] text-[5px] mt-1">
          <Medal
            className={cn(
              "mt-[2px] w-[6px] h-[6px]",
              ccls({
                [CommunitySlug.Safespace]: "text-primary",
                [CommunitySlug.Fintechtakes]: "text-link",
                default: "text-highlight",
              })
            )}
          />
          {tierDisplay(currentUser.tier?.level)}
        </div>
      </div>
    </div>
  )
}
