import { CommunitySlug } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

export const TagBubble = ({
  tagName,
  className,
  colorClass,
  borderClass,
}: {
  tagName: string
  className?: string
  colorClass?: string
  borderClass?: string
}) => {
  const ccls = useCommunityClassname()

  if (!colorClass) {
    colorClass = ccls({
      [CommunitySlug.Gotomillions]: "text-primary",
      [CommunitySlug.Fintechtakes]: "text-primary",
      default: "text-highlight",
    })
  }
  if (!borderClass) {
    borderClass = ccls({
      [CommunitySlug.Gotomillions]: "border-primary",
      [CommunitySlug.Fintechtakes]: "border-primary",
      default: "border-highlight",
    })
  }
  return (
    <span
      className={cn(
        "border rounded-md text-[10px] uppercase",
        "tracking-[0.1em] px-[6px] py-[3px]",
        colorClass,
        borderClass,
        className
      )}
    >
      {tagName}
    </span>
  )
}
