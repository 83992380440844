import {
  CommunitySlug,
  Post_CardFragment,
  Post_CardMainDataFragment,
} from "~/__generated__/graphql"
import { PostCard } from "./PostCard"
import { useQuery } from "@apollo/client"
import { REPLIES_QUERY_DOCUMENT } from "~/posts/RepliesFeed"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

export const PostNestedReplies = ({
  post,
  expanded,
  setExpanded,
  onReplyClick,
}: {
  post: Post_CardFragment
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  onReplyClick: (post: Post_CardFragment | Post_CardMainDataFragment) => void
}) => {
  const repliesResult = useQuery(REPLIES_QUERY_DOCUMENT, {
    variables: { parentReplyId: post.id },
    notifyOnNetworkStatusChange: true,
    skip: !expanded,
  })
  const ccls = useCommunityClassname()

  const expandedReplies =
    repliesResult.data?.posts.edges.map((e) => e.node) || []

  if (!post.mostRecentNestedReply) return null

  return (
    <div className="flex flex-col gap-4">
      {expanded && expandedReplies.length > 0 ? (
        expandedReplies
          .filter((post) => !post.currentUserFlagged && !post.deleted)
          .map((post) => (
            <PostCard
              key={post.id}
              post={post}
              isReply={true}
              canReplyInLine={false}
              cardClickable={false}
              trackReadStates={false}
              onReplyClick={onReplyClick}
              trackImpressions
            />
          ))
      ) : (
        <PostCard
          key={post.id}
          post={post.mostRecentNestedReply}
          isReply={true}
          canReplyInLine={false}
          cardClickable={false}
          trackReadStates={false}
          onReplyClick={onReplyClick}
          trackImpressions
        />
      )}

      {post.nestedRepliesCount > 1 && (
        <div className="mt-6 mb-2 text-xs flex items-center">
          {repliesResult.loading && <LoadingIndicator className="mr-2" />}
          {(!expanded ||
            (!repliesResult.loading &&
              !!repliesResult.data?.posts.pageInfo.hasNextPage)) && (
            <span
              className={cn(
                "hover:underline cursor-pointer mr-4",
                ccls({
                  [CommunitySlug.Gotomillions]: "text-highlight",
                  default: "",
                })
              )}
              onClick={() => {
                if (!expanded) {
                  setExpanded(true)
                } else {
                  repliesResult.fetchMore({
                    variables: {
                      repliesCursor:
                        repliesResult.data?.posts.pageInfo.endCursor,
                    },
                  })
                }
              }}
            >
              See more replies
            </span>
          )}
          {expanded && !repliesResult.loading && (
            <span
              className="hover:underline cursor-pointer"
              onClick={() => setExpanded(false)}
            >
              See fewer replies
            </span>
          )}
        </div>
      )}
    </div>
  )
}
