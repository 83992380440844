import { useEffect, useRef, useState } from "react"
import { Outlet } from "react-router-dom"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { ReloadIcon } from "@radix-ui/react-icons"
import BezierEasing from "bezier-easing"

const easing = BezierEasing(0.42, 0, 1.0, 1.0)
const overscrollerMessageHeight = 63

type UseOverscrollProps = {
  onOverscroll: () => void
  messageHeight: number
}

export const useOverscroll = ({
  onOverscroll,
  messageHeight,
}: UseOverscrollProps) => {
  const [startWindowScrollY, setStartWindowScrollY] = useState(0)
  const [current, setCurrent] = useState(0)
  const [isOverscrolling, setIsOverscrolling] = useState(false)
  const [hasOverscrolled, setHasOverscrolled] = useState(false)

  const onStart = (e: TouchEvent) => {
    if (e.targetTouches.length !== 1) return
    setHasOverscrolled(false)
    setIsOverscrolling(false)
    setStartWindowScrollY(window.scrollY)
  }

  const onDrag = (e: TouchEvent) => {
    if (e.targetTouches.length !== 1) return
    if (startWindowScrollY > window.screen.height / 3) return

    setIsOverscrolling(window.scrollY < 0)
    if (window.scrollY < 0) {
      setCurrent(Math.abs(window.scrollY))
    } else {
      setCurrent(0)
    }
  }

  const onEnd = () => {
    setStartWindowScrollY(0)
    setCurrent(0)
    setIsOverscrolling(false)

    if (current >= messageHeight / 2) {
      setHasOverscrolled(true)
      setTimeout(async () => {
        await onOverscroll()
      }, 500)
    }
  }

  useEffect(() => {
    window.addEventListener("touchstart", onStart)
    window.addEventListener("touchmove", onDrag)
    window.addEventListener("touchend", onEnd)

    return () => {
      window.removeEventListener("touchstart", onStart)
      window.removeEventListener("touchmove", onDrag)
      window.removeEventListener("touchend", onEnd)
    }
  })

  return { isOverscrolling, hasOverscrolled, current }
}

export const PullToRefresh = () => {
  const overscrollMessageRef = useRef<HTMLDivElement>(null)
  const { isOverscrolling, hasOverscrolled, current } = useOverscroll({
    onOverscroll: async () => {
      if (isStandalone) {
        window.location.reload()
      }
    },
    messageHeight: overscrollerMessageHeight,
  })

  const [isStandalone] = useState(
    window.matchMedia("(display-mode: standalone").matches
  )

  useEffect(() => {
    if (!overscrollMessageRef.current) return

    const overscroller = overscrollMessageRef.current
    if (hasOverscrolled || isOverscrolling) {
      overscroller.classList.remove("hidden")
    }

    if (hasOverscrolled) {
      overscroller.style.transition = "opacity 0.2s, height 0.2s"
      overscroller.style.height = overscrollerMessageHeight + "px"
      overscroller.style.opacity = "1"
    } else if (isOverscrolling) {
      overscroller.style.transition = ""
      overscroller.style.height =
        Math.min(overscrollerMessageHeight, current) + "px"
      overscroller.style.opacity = easing(
        Math.min(1, current / overscrollerMessageHeight)
      ).toFixed(2)
    } else {
      overscroller.style.transition = "opacity 0.2s, height 0.2s"
      overscroller.style.height = "0"
      overscroller.style.opacity = "0"
    }
  }, [current, hasOverscrolled, isOverscrolling])

  return !isStandalone ? (
    <Outlet />
  ) : (
    <>
      <div
        ref={overscrollMessageRef}
        className={`hidden border-b flex gap-2 items-center justify-center overflow-hidden`}
      >
        {hasOverscrolled && <LoadingIndicator />}
        {!hasOverscrolled && (
          <>
            <ReloadIcon />
            <div>Drop to refresh</div>
          </>
        )}
      </div>
      <Outlet />
    </>
  )
}
