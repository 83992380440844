import { useEffect, useMemo, useState } from "react"
import { useCommunity } from "~/community/useCommunity"
import { Celebration, CelebrationTypeEnum } from "~/__generated__/graphql"
import { useUpdateCelebration } from "./useUpdateCelebration"
import { Button } from "~/ui/button"
import { CelebrationForm } from "./CelebrationForm"
import { displayErrors } from "~/common/validations"
import { toCalendarDate } from "~/common/formatDate"
import { useCurrentUser } from "~/common/GlobalsProvider"

type CelebrationFragment = Pick<
  Celebration,
  "id" | "celebrationType" | "customCelebrationType" | "date"
>

type UpdateCelebrationProps = {
  celebration: CelebrationFragment
  onSave?: () => void
}

export const UpdateCelebration = ({
  celebration,
  onSave,
}: UpdateCelebrationProps) => {
  const community = useCommunity()
  const currentUser = useCurrentUser()
  const currentCelebrations = useMemo(
    () => currentUser?.celebrations ?? [],
    [currentUser]
  )
  const { update, loading } = useUpdateCelebration()

  const [celebrationType, setCelebrationType] = useState(
    celebration.celebrationType || undefined
  )
  const [customCelebrationType, setCustomCelebrationType] = useState(
    celebration.customCelebrationType || ""
  )
  const [date, setDate] = useState<Date | undefined>(
    toCalendarDate(new Date(celebration.date))
  )

  useEffect(() => {
    setDate(toCalendarDate(new Date(celebration.date)))
  }, [celebration.date])

  useEffect(() => {
    if (celebrationType !== CelebrationTypeEnum.Other) {
      setCustomCelebrationType("")
    }
  }, [celebrationType])

  const enabledCelebrationTypes = useMemo(() => {
    const enabled = community.celebrationTypes.filter((type) => {
      const isInCurrentCelebrations = currentCelebrations.some(
        (celebration) => celebration.celebrationType === type
      )
      const isCurrentCelebrationType = type === celebration.celebrationType

      return isCurrentCelebrationType || !isInCurrentCelebrations
    })
    return enabled
  }, [community, currentCelebrations, celebration.celebrationType])

  const onUpdate = async () => {
    const { errors } = await update({
      celebrationId: celebration.id,
      date: date?.toISOString() ?? "",
      celebrationType: celebrationType,
      customCelebrationType: customCelebrationType,
    })

    if (errors) {
      displayErrors(errors)
    } else {
      onSave?.()
    }
  }

  return enabledCelebrationTypes.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="flex space-x-4 items-center">
        <CelebrationForm
          celebrationType={celebrationType}
          customCelebrationType={customCelebrationType}
          date={date}
          isDisabled={loading}
          availableTypes={enabledCelebrationTypes}
          onChangeCelebrationType={setCelebrationType}
          onChangeCustomCelebrationType={setCustomCelebrationType}
          onChangeDate={setDate}
        />
        <div>
          <Button type="submit" onClick={onUpdate} disabled={loading || !date}>
            Save
          </Button>
        </div>
      </div>
    </>
  )
}
