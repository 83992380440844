import { Outlet, ScrollRestoration } from "react-router-dom"
import { EventsProvider } from "~/analytics/EventsContext"
import { ChannelsProvider } from "~/common/ChannelsProvider"
import { NewFeedPostProvider } from "~/feed/NewFeedPostContext"
import { PostReadProvider } from "~/feed/PostReadContext"
import { UserOffersProvider } from "~/offers/UserOffersProvider"
import { RulesEngineProvider } from "~/rules-engine/RulesEngineContext"
import { SubscriptionProvider } from "~/subscriptions/SubscriptionProvider"

export const RootLayout = () => (
  <EventsProvider>
    <SubscriptionProvider>
      <ChannelsProvider>
        <UserOffersProvider>
          <RulesEngineProvider>
            <PostReadProvider>
              <NewFeedPostProvider>
                <Outlet />
                <ScrollRestoration />
              </NewFeedPostProvider>
            </PostReadProvider>
          </RulesEngineProvider>
        </UserOffersProvider>
      </ChannelsProvider>
    </SubscriptionProvider>
  </EventsProvider>
)
