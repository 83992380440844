import { useEffect, useRef, useState } from "react"
import {
  AhoyEventTypeEnum,
  Article_LiveContentFragment,
  ArticleContentTypeEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"

export const useArticleScreenAnalytics = ({
  articleId,
  article,
}: {
  articleId: string
  article?: Article_LiveContentFragment | null
}) => {
  const [loggedViewEvent, setLoggedViewEvent] = useState<string | null>(null)

  const { logEvent, currentPageviewId } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    if (
      currentPageviewId &&
      articleId &&
      loggedViewEvent !== articleId &&
      article?.revision
    ) {
      const approvedRevision = article?.revision
      const contentType = approvedRevision?.contentType
      const lessonProperties =
        contentType === ArticleContentTypeEnum.Lesson
          ? {
              lesson_id: article?.approvedLesson?.id,
              course_id: article?.approvedLesson?.section.course.id,
              course_article_id:
                article?.approvedLesson?.section.course.article.id,
            }
          : {}

      const courseProperties =
        contentType === ArticleContentTypeEnum.Course
          ? {
              course_id: article?.approvedCourse?.id,
            }
          : {}

      logEvent(
        AhoyEventTypeEnum.LibraryContentViewed,
        {
          article_id: articleId,
          revision_id: approvedRevision?.id,
          content_type: contentType,
          ...lessonProperties,
          ...courseProperties,
        },
        hasLoggedEvent
      )
      setLoggedViewEvent(articleId)
    }
  }, [currentPageviewId, article, articleId, logEvent, loggedViewEvent])
}
