import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import {
  Channel,
  ChannelVisibilityEnum,
  CommunitySlug,
} from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import House from "../images/icons/house.svg?react"
import Brain from "../images/icons/brain.svg?react"
import Cup from "../images/icons/cup.svg?react"
import Driver from "../images/icons/driver.svg?react"
import People from "../images/icons/people.svg?react"
import VideoAdd from "../images/icons/video-add.svg?react"
import Messages from "../images/icons/messages.svg?react"
import ChevronDown from "../images/icons/chevron-down.svg?react"
import ChatLocked from "../images/icons/chat-locked.svg?react"
import {
  feedPath,
  channelPath,
  benchmarkPath,
  libraryPath,
  directoryPath,
  eventsPath,
  askTheBotPath,
} from "~/common/paths"
import { ReactNode, useState } from "react"
import {
  useCommunityClassname,
  useCommunityFeatures,
} from "~/community/useCommunity"
import { cn } from "~/lib/utils"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { EyeOffIcon, X } from "lucide-react"
import { useTranslation } from "react-i18next"
import { Indicator } from "~/ui/Indicator"
import { Battery } from "./Battery"
import { useChannels } from "~/common/ChannelsProvider"
import { IconButton } from "~/ui/IconButton"
import { useCurrentUser } from "~/common/GlobalsProvider"

const SidebarItem = ({
  icon,
  title,
  selected,
  onClick,
  extra = null,
  expanded = null,
  indicator = 0,
  highlight = false,
}: {
  icon: ReactNode
  title: string
  selected: boolean
  extra?: ReactNode
  expanded?: boolean | null
  indicator?: number
  onClick: () => void
  highlight?: boolean
}) => {
  const ccls = useCommunityClassname()
  return (
    <li>
      <button
        onClick={onClick}
        className={cn(
          "w-full flex items-center text-xl px-5 py-3 rounded-full cursor-pointer whitespace-nowrap",
          selected &&
            ccls({
              [CommunitySlug.Marketingland]:
                "bg-marketingland-purple text-white font-semibold",
              [CommunitySlug.Gotomillions]:
                "bg-primary text-white font-semibold",
              [CommunitySlug.Fintechtakes]:
                "bg-primary text-white font-semibold",
              default: "bg-white text-black font-semibold",
            }),
          highlight &&
            !selected &&
            ccls({
              [CommunitySlug.Boardroom]: "border border-primary bg-primary/20",
              [CommunitySlug.Marketingland]:
                "border border-marketingland-light-pink bg-marketingland-light-pink text-black",
              default: "border border-highlight bg-highlight/10",
            })
        )}
      >
        <div className="flex flex-1 items-center justify-between">
          <div className="w-[24px] text-center flex justify-center relative mr-5">
            {icon}
          </div>
          <div className="flex-1 text-left">{title}</div>
          {extra}
          <Indicator value={indicator} />
          {(expanded !== null || !!indicator) && (
            <ChevronDown
              className={cn("ml-2", {
                invisible: expanded === null,
                "rotate-180": expanded,
              })}
            />
          )}
        </div>
      </button>
    </li>
  )
}

const SidebarChannel = ({
  channel,
  selected,
  routeCallback,
  indicator,
}: {
  channel: Pick<
    Channel,
    | "slug"
    | "name"
    | "active"
    | "unreadFeedPostCount"
    | "visibility"
    | "hasCurrentUserJoined"
    | "hasCurrentUserDismissed"
  >
  selected: boolean
  routeCallback?: () => void
  indicator: number
}) => {
  const { goToChannel, dismissChannel } = useChannels()
  const handleClick = () => {
    routeCallback?.()
    goToChannel(channel.slug)
  }
  const ccls = useCommunityClassname()

  const onDismiss = (e: React.MouseEvent) => {
    e.stopPropagation()
    dismissChannel(channel.slug)
  }

  return (
    <div
      onClick={handleClick}
      className={cn(
        "flex items-center gap-2 cursor-pointer px-5 py-1 text-sm rounded-full",
        {
          "text-foreground/60": !channel.active,
        },
        selected &&
          ccls({
            [CommunitySlug.Marketingland]: "bg-marketingland-purple text-white",
            default: "bg-white text-foreground",
          })
      )}
    >
      {!channel.active && (
        <SimpleTooltip content="Channel is inactive; you can see this because you are an admin">
          <EyeOffIcon className="w-4 h-4" />
        </SimpleTooltip>
      )}
      {channel.visibility === ChannelVisibilityEnum.Private && (
        <ChatLocked className="w-4 h-4" />
      )}
      <div className="flex flex-1 items-center gap-2">
        {channel.name}
        {channel.hasCurrentUserJoined && (
          <Indicator value={indicator} size="sm" />
        )}
      </div>
      {!channel.hasCurrentUserJoined && (
        <>
          <Button
            variant="outline"
            theme="current"
            size="sm"
            className="text-2xs uppercase"
          >
            Join
          </Button>
          <IconButton
            onClick={onDismiss}
            size="none"
            chrome="none"
            variant="none"
          >
            <X className="w-4 h-4" />
          </IconButton>
        </>
      )}
    </div>
  )
}

const SidebarAction = ({
  routeCallback,
  title,
  path,
}: {
  title: string
  path: string
  routeCallback?: () => void
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    routeCallback?.()
    navigate(path)
  }

  return (
    <div className="w-full px-6 mt-3">
      <Button className="w-full" onClick={handleClick}>
        {title}
      </Button>
    </div>
  )
}

export const NavItems = ({ routeCallback }: { routeCallback?: () => void }) => {
  const currentUser = useCurrentUser(false)
  const { channels } = useChannels()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { channelSlug } = useParams()
  const [channelsExpanded, setChannelsExpanded] = useState<boolean>(
    localStorage.getItem("collapseChannels") !== "true"
  )

  const navigateTo = (path: string) => {
    routeCallback?.()
    navigate(path)
  }

  const toggleChannels = () => {
    localStorage.setItem("collapseChannels", channelsExpanded.toString())
    setChannelsExpanded(!channelsExpanded)
  }
  const features = useCommunityFeatures()
  const { t } = useTranslation("community")
  const { t: tBot } = useTranslation("bot")

  return (
    <div className="flex flex-col gap-2 text-foreground">
      <ul>
        <SidebarItem
          icon={<House />}
          title="Feed"
          onClick={() => navigateTo(feedPath.pattern)}
          selected={!!matchPath({ path: feedPath.pattern }, pathname)}
        />
        {features.Benchmark && (
          <SidebarItem
            icon={<Cup />}
            title="Benchmark"
            onClick={() => navigateTo(benchmarkPath.pattern)}
            selected={!!matchPath({ path: benchmarkPath.pattern }, pathname)}
          />
        )}
        <SidebarItem
          icon={<Driver />}
          title={t("libraryLabel")}
          onClick={() => navigateTo(libraryPath.pattern)}
          indicator={currentUser?.unviewedLibraryCount}
          selected={
            !!matchPath({ path: libraryPath.pattern, end: false }, pathname)
          }
        />
        {features.AskTheBot && (
          <SidebarItem
            icon={<Brain className="w-6 h-6" />}
            title={tBot("name")}
            extra={
              <div className="flex items-center gap-1">
                <Battery
                  level={currentUser?.botBatteryBalance || 0}
                  className="mx-2"
                />
              </div>
            }
            onClick={() => navigateTo(askTheBotPath.pattern)}
            selected={!!matchPath({ path: askTheBotPath.pattern }, pathname)}
          />
        )}
        <SidebarItem
          icon={<People />}
          title={t("directoryLabel")}
          onClick={() => navigateTo(directoryPath.pattern)}
          indicator={currentUser?.unviewedDirectoryCount}
          selected={!!matchPath({ path: directoryPath.pattern }, pathname)}
        />
        <SidebarItem
          icon={<VideoAdd />}
          title="Events"
          onClick={() => navigateTo(eventsPath.pattern)}
          indicator={currentUser?.unviewedEventsCount}
          selected={!!matchPath({ path: eventsPath.pattern }, pathname)}
        />
        <SidebarItem
          icon={<Messages />}
          title="Channels"
          onClick={toggleChannels}
          expanded={channelsExpanded}
          selected={
            !!matchPath({ path: channelPath.pattern, end: false }, pathname)
          }
          indicator={currentUser?.unreadChannelPostsCount}
        />

        {channels && channelsExpanded && (
          <div className="py-3">
            {channels
              .filter((c) => !c.hasCurrentUserDismissed)
              .map((c) => (
                <SidebarChannel
                  channel={c}
                  key={`c-${c.id}`}
                  selected={channelSlug === c.slug}
                  routeCallback={routeCallback}
                  indicator={c.unreadFeedPostCount}
                />
              ))}
          </div>
        )}

        <SidebarAction
          title="Post"
          path={feedPath.pattern + "?post=focus"}
          routeCallback={routeCallback}
        />
      </ul>
    </div>
  )
}
