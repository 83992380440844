import {
  Channel,
  ChannelVisibilityEnum,
  CommunitySlug,
  Post_CardFragment,
} from "~/__generated__/graphql"
import { PostCard } from "./PostCard"
import Messages from "../images/icons/messages-thin.svg?react"
import House from "../images/icons/house.svg?react"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"
import { Card, CardContent } from "~/ui/card"
import ChatLocked from "../images/icons/chat-locked.svg?react"
import { Button } from "~/ui/button"
import { Fragment, useMemo } from "react"
import { useChannels } from "~/common/ChannelsProvider"

export const PostFeed = ({
  posts,
  isReplies = false,
  isChannel = false,
  isFiltered = false,
  trackReadStates = false,
  trackImpressions = false,
  advertizedChannel,
}: {
  posts: Post_CardFragment[]
  isReplies?: boolean
  isChannel?: boolean
  isFiltered?: boolean
  trackReadStates?: boolean
  trackImpressions?: boolean
  advertizedChannel?: Pick<
    Channel,
    "id" | "slug" | "name" | "description" | "imageUrl" | "visibility"
  > & { groups: { id: string }[] }
}) => {
  const ccls = useCommunityClassname()
  const { joinChannel, getRequiresUpgradeGroupUser } = useChannels()

  const requiresUpgrade = useMemo(
    () => advertizedChannel && !!getRequiresUpgradeGroupUser(advertizedChannel),
    [advertizedChannel, getRequiresUpgradeGroupUser]
  )

  return (
    <div className="flex flex-col gap-4">
      {!isReplies && posts.length === 0 && (
        <div
          className={cn(
            "mt-10 flex flex-col items-center text-center",
            ccls({
              [CommunitySlug.Safespace]: "text-primary",
              default: "text-foreground",
            })
          )}
        >
          <div
            className={cn(
              "w-[84px] h-[84px] border-2 rounded-full flex items-center justify-center",
              ccls({
                [CommunitySlug.Safespace]: "border-primary",
                default: "border-foreground",
              })
            )}
          >
            {isChannel ? (
              <Messages className="h-9 w-auto relative top-[2px]" />
            ) : (
              <House className="h-8 w-auto relative top-[-1px]" />
            )}
          </div>
          <div className="mt-4 font-semibold text-2xl">No Posts</div>
          <div className="mt-3 text-sm">
            {isFiltered ? (
              "No posts match the selected filters."
            ) : (
              <div>
                Be the first to share your thoughts
                {isChannel ? " in this channel." : "."}
                <br />
                Say something to the community above.
              </div>
            )}
          </div>
        </div>
      )}

      {posts
        .filter((post) => !post.currentUserFlagged && !post.deleted)
        .map((post, index) => (
          <Fragment key={post.id}>
            <PostCard
              post={post}
              isReply={isReplies}
              canReplyInLine={true}
              cardClickable={!isReplies}
              trackReadStates={trackReadStates}
              trackImpressions={trackImpressions}
            />
            {index === 0 && advertizedChannel && (
              <Card>
                <CardContent className="p-4">
                  <div className="flex items-center gap-4">
                    {advertizedChannel.imageUrl && (
                      <img
                        src={advertizedChannel.imageUrl}
                        alt=""
                        className="w-48 h-48 object-cover rounded-sm"
                      />
                    )}
                    <div className="flex flex-col gap-4">
                      <div className="font-semibold text-lg flex items-center gap-2">
                        {advertizedChannel.visibility ===
                          ChannelVisibilityEnum.Private && (
                          <ChatLocked className="w-6 h-6" />
                        )}
                        {advertizedChannel.name}
                      </div>
                      {advertizedChannel.description && (
                        <div className="text-sm text-foreground">
                          {advertizedChannel.description}
                        </div>
                      )}
                      <div>
                        <Button
                          onClick={() => joinChannel(advertizedChannel.slug)}
                        >
                          {requiresUpgrade ? "Upgrade to Join" : "Join Channel"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </Fragment>
        ))}
    </div>
  )
}
