import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"
import { Link, LinkProps } from "react-router-dom"

export const buttonVariantsConfigSchema = {
  chrome: {
    default:
      "inline-flex items-center justify-center whitespace-nowrap no-underline text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 rounded-full no-underline hover:no-underline",
    none: "",
  },
  theme: {
    current: "",
    primary: "text-primary",
    highlight: "text-highlight",
    success: "text-green-500",
    green: "text-seaGreen",
    destructive: "text-red-600",
    foreground: "text-foreground",
    link: "text-link",
    onboarding: "text-onboarding-primary", // TODO: the naming on this is a bit off
  },
  variant: {
    none: "",
    filled:
      "bg-current border border-current [&>*]:text-white hover:bg-current/90",
    unselected:
      "bg-card border border-slate-200 text-primary hover:bg-slate-100",
    outline: "border border-current hover:bg-white",
    ghost: "bg-transparent hover:bg-slate-100",
    link: "text-foreground underline-offset-4 hover:underline",
    input: [
      "[&>span]:flex h-10 [&>span]:w-full [&>span]:items-center [&>span]:justify-between rounded-md border",
      "border-slate-200 bg-white px-3 py-2 text-sm",
      "ring-offset-white placeholder:text-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-950",
      "focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span>span]:line-clamp-1 ",
    ].join(" "),
    postAction:
      "bg-card border border-mercury text-black hover:bg-primary/90 hover:text-white card-clickable flex items-center justify-center gap-1 text-2xs",
    minimal:
      "text-xs font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 py-0 p-0",
  },
  size: {
    none: "",
    default: "px-4 py-2",
    tight: "py-0 px-y",
    sm: "py-1 px-3",
    lg: "py-2 px-8",
    xl: "py-4 px-8",
    icon: "h-8 w-8",
    expandableIcon: "h-8 min-w-8 p-2",
    inline: "py-0 px-0 w-fit h-fit",
  },
  pseudo: {
    default: "",
    disabled: "opacity-50",
  },
  modifiers: {
    inputHover: "hover:border-slate-300 hover:bg-slate-100",
  },
}

const buttonVariants = cva("", {
  variants: { ...buttonVariantsConfigSchema },
  defaultVariants: {
    chrome: "default",
    theme: "primary",
    variant: "filled",
    size: "default",
    pseudo: "default",
  },
})

export type ButtonVariants = VariantProps<typeof buttonVariants>

type SharedProps = {
  isLoading?: boolean
}

export interface ButtonProps
  extends SharedProps,
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariants {
  asChild?: boolean
  pre?: React.ReactNode
  post?: React.ReactNode
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      chrome,
      variant,
      theme,
      size,
      pseudo,
      modifiers,
      isLoading,
      children,
      pre,
      post,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          buttonVariants({
            chrome,
            variant,
            theme,
            size,
            pseudo,
            modifiers,
            className,
          }),
          (pre || post) && "flex gap-2"
        )}
        ref={ref}
        type="button"
        {...props}
      >
        {pre}
        <span className={cn("flex", (pre || post) && "flex-grow")}>
          {isLoading ? "Saving..." : children}
        </span>
        {post}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export interface LinkButtonProps
  extends SharedProps,
    LinkProps,
    VariantProps<typeof buttonVariants> {
  pre?: React.ReactElement
}

const LinkButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
  pre,
  ...props
}: LinkButtonProps) => {
  return (
    <Link
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {pre}
      <span>{isLoading ? "Saving..." : children}</span>
    </Link>
  )
}
LinkButton.displayName = "LinkButton"

export interface FakeButtonProps
  extends SharedProps,
    React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof buttonVariants> {}

const FakeButton = ({
  className,
  variant,
  size,
  isLoading,
  children,
}: FakeButtonProps) => {
  return (
    <div className={cn(buttonVariants({ variant, size, className }))}>
      {isLoading ? "Saving..." : children}
    </div>
  )
}
FakeButton.displayName = "FakeButton"

export { Button, buttonVariants, LinkButton, FakeButton }
