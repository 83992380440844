import humps from "humps"
import { DirectUpload, DirectUploadDelegate } from "@rails/activestorage"
import { useCallback } from "react"
import { useConfig } from "./ConfigProvider"

export const useDirectImageUpload = () => {
  const { directUploadsUrl } = useConfig()

  const uploadFile = useCallback(
    (file: File, progressDelegate?: DirectUploadDelegate) => {
      return new Promise((resolve, reject) => {
        console.log({ directUploadsUrl })
        const upload = new DirectUpload(
          file,
          directUploadsUrl,
          progressDelegate
        )

        upload.create((error, blob) => {
          if (error) {
            reject(error)
          } else {
            resolve(blob)
          }
        })
      })
    },
    [directUploadsUrl]
  )

  const directImageUpload = useCallback(
    async (
      image: FileList[number],
      progressDelegate?: DirectUploadDelegate
    ) => {
      const data = await uploadFile(image, progressDelegate)
      return humps.camelizeKeys(data) as { signedId: string }
    },
    [uploadFile]
  )

  return { directImageUpload }
}
